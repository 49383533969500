(function ( $ ) {
	
	'use strict';
	
	const pluginName = 'liquidResponsiveBG';
	let defaults = {
	};
	
	class Plugin {

		constructor(element, options) {

			this.element = element;
			this.$element = $(element);

			this.options = $.extend({}, defaults, options);
			this._defaults = defaults;
			this._name = pluginName;

			this.targetImage = null;

			this.targetImage = this.element.querySelector('img');
			
			this.init();
			
		}

		init() {
			
			if ( typeof undefined === typeof this.targetImage || null === this.targetImage ) {
				console.error('There should be an image to get the source from it.');
				return false
			}
			
			this.setBgImage();

			imagesLoaded(this.targetImage)
			.on('done', this.onLoad.bind(this) );
			
		}
	
		getCurrentSrc() {

			let imageSrc = this.targetImage.currentSrc ? this.targetImage.currentSrc : this.targetImage.src;
			
			if ( /data:image\/svg\+xml/.test(imageSrc) ) {
				imageSrc = this.targetImage.dataset.src;
			}
			
			return imageSrc;
	
		}
	
		setBgImage() {
	
			this.$element.css({
				backgroundImage: `url( ${ this.getCurrentSrc() } )`
			});
	
		}

		reInitparallaxBG() {
	
			const parallaxFigure = this.$element.children('.lqd-parallax-container').find('.lqd-parallax-figure');
	
			if ( parallaxFigure.length ) {
	
				parallaxFigure.css({
					backgroundImage: `url( ${ this.getCurrentSrc() } )`
				});
				
			}
	
		}
	
		onLoad() {
	
			this.reInitparallaxBG();
	
			this.$element.addClass('loaded');
	
		}
	
	}
	
	$.fn[ pluginName ] = function( options ) {
		
		return this.each( function() {
			
			const pluginOptions = {...$(this).data('responsive-options'), ...options};
			
			if ( !$.data( this, "plugin_" + pluginName ) ) {
				$.data( this, "plugin_" + pluginName, new Plugin( this, pluginOptions ) );
			}
			
		} );
		
	};
	
}(jQuery));

jQuery(document).ready( function($) {

	$('[data-responsive-bg=true]')
	.filter((i, el) => {
		return ! el.querySelector('.ld-lazyload');
	})
	.liquidResponsiveBG();
	
});