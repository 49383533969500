( function ( $ ) {

	'use strict';

	const pluginName = 'liquidAnimatedFrames';
	let defaults = {
		current: 0,
		scrollable: false,
		forceDisablingWindowScroll: false,
		autoplay: false,
		autoplayTimeout: 4000
	};

	class Plugin {

		constructor( element, options ) {

			this.element = element;
			this.$element = $( element );

			this.options = $.extend( {}, defaults, options );

			this._defaults = defaults;
			this._name = pluginName;

			this.DOM = {};
			this.DOM.el = element;

			this.DOM.slides = Array.from( this.DOM.el.querySelectorAll( '.lqd-af-slides > div' ) );
			this.DOM.nav = this.DOM.el.querySelector( '.lqd-af-slidenav' );
			this.DOM.nums = this.DOM.el.querySelector( '.lqd-af-slidenum' );
			this.DOM.numsCurrent = this.DOM.el.querySelector( '.lqd-af-slidenum__current' );
			this.DOM.numsTotal = this.DOM.el.querySelector( '.lqd-af-slidenum__total' );
			this.DOM.nextCtrl = this.DOM.nav.querySelector( '.lqd-af-slidenav__item--next' );
			this.DOM.prevCtrl = this.DOM.nav.querySelector( '.lqd-af-slidenav__item--prev' );
			this.slidesTotal = this.DOM.slides.length;
			this.current = this.options.current;

			this.startY = 0;
			this.currentY = 0;
			this.dragY = 0;

			this.userInteracted = false;
			this.autoplayTimeout = null;

			this.initNumbers();
			this.init();
			this.animateNumbers();
			this.initEvents();

			this.autoplay();

		}

		init() {

			const currentSlide = this.DOM.slides[ this.current ];
			currentSlide.classList.add( 'lqd-af-slide--current' );
			this.DOM.el.classList.add( 'lqd-af--initial' );
			this.onSlideLoaded( currentSlide );

		}

		initNumbers() {

			if ( liquidIsElementor ) return;

			const $parantSpan = $( '<span class="pos-abs pos-tl absolute top-0 left-0" />' );

			this.DOM.numsTotal.innerText = this.slidesTotal;

			for ( let i = 1; i <= this.slidesTotal; i++ ) {
				const $span = $( `<span class="d-flex align-items-center justify-content-center flex items-center justify-center">${ i }</span>` );
				$span.appendTo( $parantSpan );
			}

			$parantSpan.appendTo( this.DOM.numsCurrent );
		}

		animateNumbers( i = 0 ) {

			const spanEl = this.DOM.numsCurrent.querySelector( 'span' );

			if ( !spanEl ) return;

			spanEl.style.transform = `translateY(${ ( i * 100 ) * -1 }%)`;

		}

		initEvents() {

			if ( this.slidesTotal <= 1 ) return;

			$( this.DOM.nextCtrl ).off( 'click.lqdAnimateFrames', this.navigate );
			$( this.DOM.prevCtrl ).off( 'click.lqdAnimateFrames', this.navigate );

			$( this.DOM.nextCtrl ).on( 'click.lqdAnimateFrames', this.navigate.bind( this, 'next', true ) );
			$( this.DOM.prevCtrl ).on( 'click.lqdAnimateFrames', this.navigate.bind( this, 'prev', true ) );

			$( document ).on( 'keydown.lqdAnimateFrames', ( ev ) => {
				const keyCode = ev.originalEvent.key;
				if ( keyCode === 'ArrowUp' ) {
					this.navigate( 'prev', true );
				}
				else if ( keyCode === 'ArrowDown' ) {
					this.navigate( 'next', true );
				}
			} );

			if ( !this.options.scrollable ) return false;

			this.initDrag();

			if (
				this.options.forceDisablingWindowScroll &&
				( 'elementorFrontend' in window && !elementorFrontend.isEditMode() )
			) {
				document.documentElement.classList.add( 'overflow-hidden' )
			}

			const onWheel = liquidThrottle( event => {

				const delta = Math.sign( event.originalEvent.deltaY );
				if ( delta < 0 ) {
					this.navigate( 'prev', true );
				} else if ( delta > 0 ) {
					this.navigate( 'next', true );
				}

			}, 800, true );

			this.$element.on( 'mouseenter.lqdAnimateFrames', () => {
				$liquidWindow.on( 'wheel.lqdAnimateFrames', onWheel );
			} );

			this.$element.on( 'mouseleave.lqdAnimateFrames', () => {
				$liquidWindow.on( 'wheel.lqdAnimateFrames', onWheel );
			} );

		}

		initDrag() {

			this.$element.on( 'mousedown touchstart', this.pointerStart.bind( this ) );
			this.$element.on( 'mousemove touchmove', this.pointerMove.bind( this ) );
			this.$element.on( 'mouseup touchend', this.pointerEnd.bind( this ) );

		}

		pointerStart( event ) {

			if (
				this.options.forceDisablingWindowScroll &&
				( 'elementorFrontend' in window && !elementorFrontend.isEditMode() )
			) {
				document.documentElement.classList.add( 'overflow-hidden' );
			}

			this.startY = event.pageY || event.originalEvent.changedTouches[ 0 ].pageY;
			this.currentY = this.startY;

			this.$element.addClass( 'pointer-down' );

		}

		pointerMove( event ) {

			if (
				this.options.forceDisablingWindowScroll &&
				( 'elementorFrontend' in window && !elementorFrontend.isEditMode() )
			) {
				document.documentElement.classList.add( 'overflow-hidden' );
			}

			this.currentY = event.pageY || event.originalEvent.changedTouches[ 0 ].pageY;

			this.dragY = parseInt( this.startY - this.currentY, 10 );

		}

		pointerEnd() {

			this.dragY = parseInt( this.startY - this.currentY, 10 );

			if ( this.dragY >= 20 ) {

				this.navigate( 'next' );

			} else if ( this.dragY <= -20 ) {

				this.navigate( 'prev' );

			}

			this.element.classList.remove( 'pointer-down' );

		}

		navigate( dir = 'next', navigatedByUser ) {

			if ( this.isAnimating ) return false;

			if ( navigatedByUser ) {
				this.userInteracted = true;
			}

			this.isAnimating = true;

			if ( this.autoplayTimeout ) {
				clearTimeout( this.autoplayTimeout );
			}

			if (
				this.options.forceDisablingWindowScroll &&
				( 'elementorFrontend' in window && !elementorFrontend.isEditMode() )
			) {
				document.documentElement.classList.add( 'overflow-hidden' );
			}

			this.element.classList.add( 'lqd-af--navigating', 'lqd-af--navigation-init' );

			dir === 'next' && this.element.classList.add( 'lqd-af--moving-up' );
			dir === 'prev' && this.element.classList.add( 'lqd-af--moving-down' );

			const currentSlide = this.DOM.slides[ this.current ];
			const currentSlideImg = currentSlide.querySelector( '.lqd-af-slide__img' );
			const currentSlideImgInner = currentSlideImg.querySelector( '.lqd-af-slide__img__inner' );
			const currentSlideFigureEl = currentSlideImg.querySelector( 'figure' );
			const currentSlideTitleSplitInner = currentSlide.querySelectorAll( '.lqd-af-slide__title .split-inner' );
			const currentSlideDescSplitInner = currentSlide.querySelectorAll( '.lqd-af-slide__desc .split-inner' );
			const currentSlideLink = currentSlide.querySelector( '.lqd-af-slide__link' );

			const figureAnimations = gsap.timeline( {
				duration: 1.2,
				onComplete: () => {
					currentSlide.classList.remove( 'lqd-af-slide--movin-out' );
				}
			} );

			figureAnimations.fromTo( currentSlideFigureEl,
				{ scale: 1, },
				{ scale: 1.25, ease: CustomEase.create( "custom", "M0,0,C0.4,0,0.2,1,1,1" ), },
				0 );

			figureAnimations.fromTo( currentSlideImgInner,
				{ scale: 1, },
				{ scale: 0.5, ease: CustomEase.create( "custom", "M0,0,C0.4,0,0.2,1,1,1" ), },
				0.015 );

			figureAnimations.to( currentSlideImg, {
				duration: 0.85,
				ease: CustomEase.create( "custom", "M0,0,C0.395,0,0.1,1,1,1" ),
				y: dir === 'next' ? '-100%' : '100%'
			}, 0.35 );

			currentSlide.classList.add( 'lqd-af-slide--movin-out' );

			const currentSlideContentTimeline = gsap.timeline( {
				duration: 1.2,
				delay: 0.5,
				ease: CustomEase.create( "custom", "M0,0,C0.4,0,0.1,1,1,1" ),
				onComplete: () => {
					currentSlide.classList.remove( 'lqd-af-slide--current' );
					this.DOM.el.classList.add( 'lqd-af--initial' );
				}
			} );

			currentSlideTitleSplitInner?.length && currentSlideContentTimeline.fromTo( currentSlideTitleSplitInner,
				{ y: '0%' },
				{ y: dir === 'next' ? '-105%' : '105%' },
				dir === 'next' ? 0 : 0.35 );
			currentSlideDescSplitInner?.length && currentSlideContentTimeline.fromTo( currentSlideDescSplitInner,
				{ opacity: 1, y: '0%' },
				{ opacity: 0, y: dir === 'next' ? '-100%' : '100%' },
				0.25 );
			currentSlideLink && currentSlideContentTimeline.fromTo( currentSlideLink,
				{ opacity: 1, y: '0%' },
				{ opacity: 0, y: dir === 'next' ? '-75%' : '75%' },
				dir === 'next' ? 0.35 : 0 );

			this.current = dir === 'next' ?
				this.current < this.slidesTotal - 1 ? this.current + 1 : 0 :
				this.current > 0 ? this.current - 1 : this.slidesTotal - 1;

			this.animateNumbers( this.current );

			// New slide
			const newSlide = this.DOM.slides[ this.current ];
			newSlide.classList.add( 'lqd-af-slide--current', 'lqd-af-slide--movin-in' );
			this.DOM.el.classList.add( 'lqd-af--initial' );
			this.onSlideLoaded( newSlide );

			const newSlideImg = newSlide.querySelector( '.lqd-af-slide__img' );
			const newSlideTitleSplitInner = newSlide.querySelectorAll( '.lqd-af-slide__title .split-inner' );
			const newSlideDescSplitInner = newSlide.querySelectorAll( '.lqd-af-slide__desc .split-inner' );
			const newSlideLink = newSlide.querySelector( '.lqd-af-slide__link' );

			const newSlideContent = gsap.timeline( {
				duration: 1.2,
				delay: 0.35,
				ease: CustomEase.create( "custom", "M0,0,C0.4,0,0.1,1,1,1" ),
				onComplete: () => {
					newSlide.classList.remove( 'lqd-af-slide--movin-in' );
				}
			} );

			newSlideContent.fromTo( newSlideImg,
				{ y: dir === 'next' ? '100%' : '-100%' },
				{
					y: 0,
					duration: 0.85,
					ease: CustomEase.create( "custom", "M0,0,C0.395,0,0.1,1,1,1" ),
				},
				0 );

			newSlideTitleSplitInner?.length && newSlideContent.fromTo( newSlideTitleSplitInner,
				{ y: dir === 'next' ? '105%' : '-105%' },
				{ y: '0%' },
				dir === 'next' ? 0.3 : 0.5 );
			newSlideDescSplitInner?.length && newSlideContent.fromTo( newSlideDescSplitInner,
				{ opacity: 0, y: dir === 'next' ? '100%' : '-100%' },
				{ opacity: 1, y: '0%' },
				0.4 );
			newSlideLink && newSlideContent.fromTo( newSlideLink,
				{ opacity: 0, y: dir === 'next' ? '75%' : '-75%', },
				{ opacity: 1, y: '0%' },
				dir === 'next' ? 0.5 : 0.3 );

			figureAnimations.then( this.animateShapeOut.bind( this, dir ) );

		}

		animateShapeOut() {

			const currentSlide = this.DOM.slides[ this.current ];
			const currentSlideImg = currentSlide.querySelector( '.lqd-af-slide__img' );
			const currentSlideImgInner = currentSlideImg.querySelector( '.lqd-af-slide__img__inner' );
			const currentSlideFigureEl = currentSlideImg.querySelector( 'figure' );

			gsap.to( [ currentSlideImgInner, currentSlideFigureEl ], {
				scale: 1,
				duration: 0.8,
				ease: CustomEase.create( "custom", "M0,0,C0.4,0,0.2,1,1,1" ),
				onComplete: () => {

					this.isAnimating = false;
					this.element.classList.remove( 'lqd-af--navigating', 'lqd-af--moving-up', 'lqd-af--moving-down' );
					!this.options.forceDisablingWindowScroll && document.documentElement.classList.remove( 'overflow-hidden' );

					this.autoplay();

				}
			} );

		}

		autoplay() {

			if ( !this.options.autoplay || this.userInteracted || this.slidesTotal <= 1 ) return;

			this.autoplayTimeout = setTimeout( () => {
				this.navigate();
			}, this.options.autoplayTimeout );

		}

		onSlideLoaded( slide ) {

			const $slide = $( slide );
			const $vids = $slide.find( 'video' );

			$vids.each( function () {
				const $vid = $( this );
				$vid.find( 'source' ).each( function () {
					const $source = $( this );
					$source.attr( 'src', $source.attr( 'data-src' ) );
				} );
				$vid[ 0 ].load();
				$vid[ 0 ].play();
			} );

		}

		destroy() {

			$( this.DOM.nextCtrl ).off( 'click.lqdAnimateFrames' );
			$( this.DOM.prevCtrl ).off( 'click.lqdAnimateFrames' );

			$( document ).off( 'keydown.lqdAnimateFrames' );
			this.$element.off( 'mouseenter.lqdAnimateFrames' );
			$liquidWindow.off( 'wheel.lqdAnimateFrames' );
			this.$element.off( 'mouseleave.lqdAnimateFrames' );

		}

	}

	$.fn[ pluginName ] = function ( options ) {

		return this.each( function () {

			const pluginOptions = { ...$( this ).data( 'af-options' ), ...options };

			if ( !$.data( this, "plugin_" + pluginName ) ) {
				$.data( this, "plugin_" + pluginName, new Plugin( this, pluginOptions ) );
			}

		} );

	};

}( jQuery ) );

jQuery( document ).ready( function ( $ ) {
	$( '[data-liquid-animatedframes=true]' ).liquidAnimatedFrames();
} );