(function ( $ ) {
	
	'use strict';
	
	const pluginName = 'liquidWebGLHover';
	let defaults = {
	};

	class EffectShell {

		constructor(container = document.body, itemsWrapper = null) {
			
			this.container = container;
			this.itemsWrapper = itemsWrapper;

			if (!this.container || !this.itemsWrapper) return

			this.build();
			
		}

		build() {

			new IntersectionObserver(([entry], observer) => {

				if (entry.isIntersecting) {

					this.setup();
	
					this.initEffectShell().then(() => {
						this.isLoaded = true;
						if (this.isMouseOver) this.onMouseOver(this.tempItemIndex)
						this.tempItemIndex = null
						this.createEventsListeners();
						this.init();
						this.container.classList.add('lqd-webglhover-ready');
					});

					observer.unobserve(entry.target);

				}

			}).observe(this.container)

		}
	
		setup() {
			
			window.addEventListener('resize', this.onWindowResize.bind(this), false)
	
			// renderer
			this.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true })
			
			this.renderer.setSize(this.viewport.width, this.viewport.height)
			this.renderer.setPixelRatio = window.devicePixelRatio
			this.container.appendChild(this.renderer.domElement)
	
			// scene
			this.scene = new THREE.Scene()
	
			// camera
			this.camera = new THREE.PerspectiveCamera( 52.75, this.viewport.aspectRatio, 1, 1000 );
			this.camera.position.set(0, 0, 1)
	
			//mouse
			this.mouse = new THREE.Vector2()
	
			// time
			this.timeSpeed = 2
			this.time = 0
			this.clock = new THREE.Clock()
	
			// animation loop
			this.renderer.setAnimationLoop(this.render.bind(this))
		}
	
		render() {
			// called every frame
			this.time += this.clock.getDelta() * this.timeSpeed
			this.renderer.render(this.scene, this.camera)
		}
	
		initEffectShell() {

			let promises = []
	
			this.items = this.itemsElements
	
			const THREEtextureLoader = new THREE.TextureLoader()
			this.items.forEach((item, index) => {
				// create textures
				promises.push(
					this.loadTexture(
						THREEtextureLoader,
						item.img ? item.img.src : null,
						index
					)
				)
			})
	
			return new Promise(resolve => {
				// resolve textures promises
				Promise.all(promises).then(promises => {
					// all textures are loaded
					promises.forEach((promise, index) => {
						// assign texture to item
						this.items[index].texture = promise.texture
					})
					resolve()
				})
			})

		}
	
		createEventsListeners() {

			this.items.forEach((item, index) => {
				item.element.addEventListener(
					'mouseover',
					this._onMouseOver.bind(this, index),
					false
				)
			})
	
			this.container.addEventListener(
				'mousemove',
				this._onMouseMove.bind(this),
				false
			)
			this.itemsWrapper.addEventListener(
				'mouseleave',
				this._onMouseLeave.bind(this),
				false
			)

		}
	
		_onMouseLeave(event) {
			this.isMouseOver = false
			this.onMouseLeave(event)
		}
	
		_onMouseMove(event) {
			// get normalized mouse position on viewport
			this.mouse.x = (event.clientX / this.viewport.width) * 2 - 1;
			this.mouse.y = -(event.clientY / this.viewport.height) * 2 + 1;
	
			this.onMouseMove(event)
		}
	
		_onMouseOver(index, event) {
			this.tempItemIndex = index
			this.onMouseOver(index, event)
		}
	
		onWindowResize() {
			this.camera.aspect = this.viewport.aspectRatio
			this.camera.updateProjectionMatrix()
			this.renderer.setSize(this.viewport.width, this.viewport.height)
		}
	
		onUpdate() {}
	
		onMouseEnter(event) {}
	
		onMouseLeave(event) {}
	
		onMouseMove(event) {}
	
		onMouseOver(index, event) {}
	
		get viewport() {
			let width = this.container.clientWidth
			let height = this.container.clientHeight
			let aspectRatio = width / height
			return {
				width,
				height,
				aspectRatio
			}
		}
	
		get viewSize() {
			// let distance = this.camera.position.z;
			// let vFov = (this.camera.fov * Math.PI) / 180;
			// let height = 2 * Math.tan(vFov / 2) * distance;
			// let width = height * this.viewport.aspectRatio;
			// let distance = 1;
			let vFov = ((this.camera.fov / 3) * Math.PI) / 180;
			let height = Math.tan(vFov / 3) * 0.25;
			let width = height * this.viewport.aspectRatio;
			return { width, height, vFov }
		}
	
		get itemsElements() {
			// convert NodeList to Array
			const items = [...this.itemsWrapper.querySelectorAll('figure')];
			//create Array of items including element, image and index
			return items.map((item, index) => ({
				element: item,
				img: item.querySelector('img') || null,
				index: index
			}))
		}
	
		loadTexture(loader, url, index) {
			// https://threejs.org/docs/#api/en/loaders/TextureLoader
			return new Promise((resolve, reject) => {
				if (!url) {
					resolve({ texture: null, index })
					return
				}
				// load a resource
				loader.load(
					// resource URL
					url,
	
					// onLoad callback
					texture => {
						resolve({ texture, index })
					},
	
					// onProgress callback currently not supported
					undefined,
	
					// onError callback
					error => {
						console.error('An error happened.', error)
						reject(error)
					}
				)
			})
		}
	}

	class RGBShiftEffect extends EffectShell {
		
		constructor(container = document.body, itemsWrapper = null, options = {}) {
			super(container, itemsWrapper)
			if (!this.container || !this.itemsWrapper) return
	
			options.strength = options.strength || 0.25
			this.options = options
	
			this.init()
		}
	
		init() {
			this.position = new THREE.Vector3(0, 0, 0)
			this.scale = new THREE.Vector3(1, 1, 1)
			this.geometry = new THREE.PlaneBufferGeometry(1, 1, 32, 32)
			this.uniforms = {
				uTime: {
					value: 0
				},
				uTexture: {
					value: null
				},
				uOffset: {
					value: new THREE.Vector2(0.0, 0.0)
				},
				uAlpha: {
					value: 1
				}
			}
			this.material = new THREE.ShaderMaterial({
				uniforms: this.uniforms,
				vertexShader: `
					uniform vec2 uOffset;
	
					varying vec2 vUv;
	
					vec3 deformationCurve(vec3 position, vec2 uv, vec2 offset) {
						float M_PI = 3.1415926535897932384626433832795;
						position.x = position.x + (sin(uv.y * M_PI) * offset.x);
						position.y = position.y + (sin(uv.x * M_PI) * offset.y);
						return position;
					}
	
					void main() {
						vUv = uv;
						vec3 newPosition = position;
						newPosition = deformationCurve(position,uv,uOffset);
						gl_Position = projectionMatrix * modelViewMatrix * vec4( newPosition, 1.0 );
					}
				`,
				fragmentShader: `
					uniform sampler2D uTexture;
					uniform float uAlpha;
					uniform vec2 uOffset;
	
					varying vec2 vUv;
	
					vec3 rgbShift(sampler2D texture, vec2 uv, vec2 offset) {
						float r = texture2D(uTexture,vUv + uOffset).r;
						vec2 gb = texture2D(uTexture,vUv).gb;
						return vec3(r,gb);
					}
	
					void main() {
						vec3 color = rgbShift(uTexture,vUv,uOffset);
						gl_FragColor = vec4(color,uAlpha);
					}
				`,
				transparent: true
			})
			this.plane = new THREE.Mesh(this.geometry, this.material)
			this.scene && this.scene.add(this.plane);
			this.onTargetChange(0);
			this.draw(0, 0);
		}
	
		onMouseEnter() {
			if (!this.currentItem || !this.isMouseOver) {
				this.isMouseOver = true
				// gsap.to(this.uniforms.uAlpha, {
				// 	value: 1,
				// 	duration: 0.5,
				// 	ease: 'power2.out'
				// })
			}
		}
	
		onMouseLeave(event) {
			// gsap.to(this.uniforms.uAlpha, {
			// 	value: 0,
			// 	duration: 0.5,
			// 	ease: 'power2.out'
			// })
		}
	
		onMouseMove(event) {
			// project mouse position to world coodinates
			let x = gsap.utils.mapRange(
				-1,
				1,
				-this.viewSize.width / 4,
				this.viewSize.width / 4,
				this.mouse.x
			);
			let y = gsap.utils.mapRange(
				-1,
				1,
				-this.viewSize.height / 4,
				this.viewSize.height / 4,
				this.mouse.y
			);

			this.draw(x, y);
	
		}

		draw(x, y) {

			this.position = new THREE.Vector3(x, y, 0)
			gsap.to(this.plane.position, {
				x: x,
				y: y,
				duration: 1,
				ease: 'power4.out',
				onUpdate: this.onPositionUpdate.bind(this)
			})

		}
	
		onPositionUpdate() {
			// compute offset
			let offset = this.plane.position
				.clone()
				.sub(this.position)
				.multiplyScalar(-this.options.strength)
			this.uniforms.uOffset.value = offset
		}
	
		onMouseOver(index) {
			if (!this.isLoaded) return
			this.onMouseEnter()
			if (this.currentItem && this.currentItem.index === index) return
			this.onTargetChange(index)
		}
	
		onTargetChange(index) {
			// item target changed
			if ( ! this.items ) return;
			this.currentItem = this.items[index]
			if (!this.currentItem.texture) return
	
			// compute image ratio
			let imageRatio =
				this.currentItem.img.naturalWidth / this.currentItem.img.naturalHeight
			this.scale = new THREE.Vector3(imageRatio, 1, 1);
			const texture = this.currentItem.texture;
			texture.generateMipmaps = false;
			texture.wrapS = texture.wrapT = THREE.ClampToEdgeWrapping;
			texture.minFilter = THREE.LinearFilter;
			this.uniforms.uTexture.value = texture;
			this.plane.scale.copy(this.scale)
		}
	}	
	
	class Plugin {

		constructor(element, options) {

			this.element = element;
			this.$element = $(element);

			this.options = {...defaults, ...options};
			
			this._defaults = defaults;
			this._name = pluginName;

			this.init();
			
		}
			
		init() {

			// Preload images
			const preloadImages = () => {
				return new Promise(resolve => {
					imagesLoaded(this.element.querySelector('img'), resolve);
				});
			};
			// And then..
			preloadImages().then(() => {
				// Remove the loader
				// new StretchEffect(document.body, this.element.querySelector('[data-hoverme]'))
				new RGBShiftEffect(this.element, this.element.querySelector('[data-hoverme]'), { strength: 3 })
			});
			
		}
		
	}
	
	$.fn[ pluginName ] = function( options ) {
		
		return this.each( function() {
			
			const pluginOptions = {...$(this).data('webglhover-options'), ...options};
			
			if ( !$.data( this, "plugin_" + pluginName ) ) {
				$.data( this, "plugin_" + pluginName, new Plugin( this, pluginOptions ) );
			}
			
		} );
		
	};
	
}(jQuery));

jQuery(document).ready( function($) {
	
	if ( liquidIsMobile() || $liquidBody.hasClass('lazyload-enabled') ) return;

	$('[data-webglhover]').liquidWebGLHover();
	
});