( function ( $ ) {

	'use strict';

	const pluginName = 'liquidParticles';
	let defaults = {
		asBG: false,
		"particles": {
			"number": {
				"value": 40,
				"density": {
					"enable": false,
					"value_area": 800
				}
			},
			"color": {
				"value": [ "#f7ccaf", "#f6cacd", "dbf5f8", "#c5d8f8", "#c5f8ce", "#f7afbd", "#b2d6ef", "#f1ecb7" ]
			},
			"shape": {
				"type": "triangle"
			},
			"size": {
				"value": 55,
				"random": true,
				"anim": {
					"enable": true,
					"speed": 1
				}
			},
			"move": {
				"direction": "right",
				"attract": {
					"enable": true
				}
			},
			"line_linked": {
				"enable": false
			},
		},
		"interactivity": {
			"events": {
				"onhover": {
					"enable": false
				},
				"onclick": {
					"enable": false
				},
			},
		}
	};

	class Plugin {

		constructor( element, options ) {

			this._defaults = defaults;
			this._name = pluginName;

			this.options = { ...defaults, ...options };
			this.options.particles = { ...defaults.particles, ...options.particles };
			this.options.interactivity = { ...defaults.interactivity, ...options.interactivity };

			this.element = element;
			this.$element = $( element );

			this.build();

		}

		build() {

			this.id = this.element.id;

			if ( this.options.interactivity.events.onhover || this.options.interactivity.events.onclick ) {
				this.$element.removeClass( 'pointer-events-none' );
				this.$element.addClass( 'pointer-events-auto' );
			}

			this.asSectionBg();
			this.init();

		}

		init() {

			particlesJS( this.id, this.options );

		}

		asSectionBg() {

			if ( this.options.asBG ) {

				const particlesBgWrap = $( '<div class="lqd-particles-bg-wrap lqd-overlay pointer-events-none"></div>' );
				const elementContainer = this.$element.parent( '.ld-particles-container' );
				let parentSection = this.$element.parents( '.vc_row' ).last();

				if ( liquidIsElementor ) {
					parentSection = this.$element.parents( '.elementor-section' ).last();
					if ( !parentSection.length ) {
						parentSection = this.$element.parent( '.e-container' );
					}
					if ( !parentSection.length ) {
						parentSection = this.$element.closest( '.e-con' )
					}
				}

				const sectionContainerElement = liquidIsElementor ? parentSection.children( '.elementor-container' ) : parentSection.children( '.ld-container' );
				const $stickyWrap = parentSection.children( '.lqd-sticky-bg-wrap' );

				particlesBgWrap.append( elementContainer );

				if ( $stickyWrap.length ) {

					particlesBgWrap.appendTo( $stickyWrap );

				} else if ( parentSection.hasClass( 'pp-section' ) ) {

					particlesBgWrap.prependTo( parentSection );

				} else {

					if ( sectionContainerElement.length ) {
						particlesBgWrap.insertBefore( sectionContainerElement );
					} else {
						particlesBgWrap.prependTo( parentSection );
					}

				}

			}

		}

	}

	$.fn[ pluginName ] = function ( options ) {

		return this.each( function () {

			const pluginOptions = { ...$( this ).data( 'particles-options' ), ...options };

			if ( !$.data( this, "plugin_" + pluginName ) ) {
				$.data( this, "plugin_" + pluginName, new Plugin( this, pluginOptions ) );
			}

		} );

	};

}( jQuery ) );

jQuery( document ).ready( function ( $ ) {

	$( '[data-particles=true]' ).filter( ( i, element ) => {

		const $element = $( element );
		const $fullpageSection = $element.closest( '.vc_row.pp-section' );

		return (
			!$fullpageSection.length
		);

	} ).liquidParticles();

} );