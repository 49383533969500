(function ( $ ) {
	
	'use strict';
	
	const pluginName = 'liquidAnimatedIcon';
	let defaults = {
		objContainer: '.iconbox-icon-container',
		color: '#f42958',
		hoverColor: null,
		type: 'delayed',
		delay: 0,
		duration: 100,
		resetOnHover: false,
		file: null
	};
	
	class Plugin {

		constructor(element, options) {

			this._defaults = defaults;
			this._name = pluginName;

			this.options = $.extend({}, defaults, options);

			this.element = element;
			this.$element = $(element);

			this.options.file && this.init();

		}

		init() {

			this.animateIcon();

			return this;

		}

		animateIcon() {

			const { type, duration, file, objContainer } = this.options;
			const gid = this.$element.attr('id') || Math.round(Math.random() * 1000000);
			const containerEl = this.$element.find(objContainer).attr('id', `${gid.replace(/ld_icon_box/, 'ld_icon_container')}`);

			new Vivus(containerEl.attr('id'), {
				file,
				type,
				duration,
				start: 'manual',
				onReady: vivus => {
					this.onReady.call(this, vivus);
				}
			}).setFrameProgress(1);

		}

		onReady(vivus) {

			const $parentCarousel = this.$element.closest('.carousel-items');
			const carouselData = $parentCarousel.data('plugin_liquidCarousel');

			this.addColors(vivus);
			this.animate(vivus);

			if ( $parentCarousel.length && carouselData != null && carouselData.flickityData != null ) {

				carouselData.flickityData.resize();

			}

		}

		addColors(svg) {

			const obj = $(svg.el);
			const { color, hoverColor } = this.options;
			const gid = Math.round(Math.random() * 1000000);
			let hoverGradientValues = hoverColor;
			let strokeHoverGradients = document.createElementNS('http://www.w3.org/2000/svg', 'style');
			let gradientValues = color != null ? color.split(':') : '#000';
			let strokegradients = null;

			if (undefined === gradientValues[1]) {
				gradientValues[1] = gradientValues[0];
			}

			strokegradients = '<defs xmlns="http://www.w3.org/2000/svg">' +
				'<linearGradient gradientUnits="userSpaceOnUse" id="grad' + gid + '" x1="0%" y1="0%" x2="0%" y2="100%">' +
				'<stop offset="0%" stop-color="' + gradientValues[0] + '" />' +
				'<stop offset="100%" stop-color="' + gradientValues[1] + '" />' +
				'</linearGradient>' +
				'</defs>';

			const xmlStrokegradients = new DOMParser().parseFromString(strokegradients, "text/xml");

			obj.prepend(xmlStrokegradients.documentElement);

			if (typeof undefined !== typeof hoverGradientValues && null !== hoverGradientValues) {

				hoverGradientValues = hoverGradientValues.split(':');

				if (undefined === hoverGradientValues[1]) {
					hoverGradientValues[1] = hoverGradientValues[0];
				}

				strokeHoverGradients.innerHTML = '#' + this.$element.attr('id') + ':hover .iconbox-icon-container defs stop:first-child{stop-color:' + hoverGradientValues[0] + ';}' +
					'#' + this.$element.attr('id') + ':hover .iconbox-icon-container defs stop:last-child{stop-color:' + hoverGradientValues[1] + ';}';
				obj.prepend(strokeHoverGradients);
			}

			obj.find('path, rect, ellipse, circle, polygon, polyline, line').attr({
				'stroke': 'url(#grad' + gid + ')',
				'fill': 'none'
			});

			this.$element.addClass('iconbox-icon-animating');

			return this;

		}
		animate(vivusObj) {

			const options = this.options;
			const delayTime = parseInt(options.delay, 10);
			const { duration } = options;

			vivusObj.reset().stop();

			new IntersectionObserver(([entry], observer) => {

				const vivusStatus = vivusObj.getStatus();

				if (entry.isIntersecting && vivusStatus === 'start' && vivusStatus !== 'progress') {

					observer.disconnect();

					this.resetAnimate(vivusObj, delayTime, duration);
					this.eventHandlers(vivusObj, delayTime, duration);


				}

			}).observe(this.element);

			return this;

		}
		eventHandlers(vivusObj, delayTime, duration) {

			const { options } = this;

			$(document).on('shown.bs.tab', 'a[data-toggle="tab"]', (event) => {

				const $target = $($(event.currentTarget).attr('href'));

				if ($target.find(this.element).length) {

					this.resetAnimate.call(this, vivusObj, delayTime, duration);

				}

			});

			if (options.resetOnHover) {

				this.$element.on('mouseenter.lqdIconbox', () => {

					if (vivusObj.getStatus() === 'end') {

						this.resetAnimate(vivusObj, 0, duration);

					}

				});

			}

		}
		resetAnimate(vivusObj, delay, duration) {

			vivusObj.stop().reset();

			const timeout = setTimeout(() => {
				vivusObj.play(duration / 100);
				clearTimeout(timeout);
			}, delay);

		}

		destroy() {
			this.$element.off('mouseenter.lqdIconbox');
		}

	}
	
	
	$.fn[ pluginName ] = function( options ) {
		
		return this.each( function() {
			
			const pluginOptions = {...$(this).data('plugin-options'), ...options};
			
			if ( !$.data( this, "plugin_" + pluginName ) ) {
				$.data( this, "plugin_" + pluginName, new Plugin( this, pluginOptions ) );
			}
			
		} );
		
	};
	
}(jQuery));

jQuery(document).ready( function($) {

	$('[data-animate-icon]').liquidAnimatedIcon();

});