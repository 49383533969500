( function ( $ ) {

	'use strict';

	const pluginName = 'liquidCarousel';

	let defaults = {
		bypassCheck: false,
		carouselEl: null,
		contain: false,
		imagesLoaded: true,
		percentPosition: true,
		prevNextButtons: false,
		pageDots: true,
		adaptiveHeight: false,
		cellAlign: "left",
		groupCells: true,
		dragThreshold: 0,
		wrapAround: false,
		autoplay: false,
		fullwidthSide: false,
		navArrow: 1,
		filters: false,
		filtersCounter: false,
		doSomethingCrazyWithFilters: false,
		equalHeightCells: false,
		middleAlignContent: false,
		randomVerOffset: false,
		parallax: false,
		parallaxEl: 'img',
		dotsIndicator: 'classic', // 'classic', 'numbers'
		numbersStyle: 'circle', // 'circle', 'line'
		addSlideNumbersToArrows: false,
		marquee: false,
		marqueeTickerSpeed: 1,
		fade: false,
		prevNextButtonsOnlyOnMobile: false,
		columnsAutoWidth: false,
		watchCSS: false,
		forceApply: false,
		skipWrapItems: false,
		forceEnableOnMobile: false,
		// buttonsAppendTo: self,
		// appendingBtnRel: null,
		// dotsAppendTo: self,
		// controllingCarousels: [],
		// navOffsets: { / we don't want to overwrite defaults
		// 	nav: {
		// 		top: 0
		//		bottom: 0,
		// 		left: 0,
		// 		right: 0
		// 	},
		// 	prev: 0,
		// 	next: 0,
		// }
	};

	function Plugin( element, options ) {

		this._defaults = defaults;
		this._name = pluginName;

		this.options = { ...defaults, ...options };

		this.flickityData = null;
		this.isRTL = $( 'html' ).attr( 'dir' ) === 'rtl';

		if ( liquidIsMobile() ) {
			this.options.dragThreshold = 5;
		}

		this.element = element;
		this.$element = $( element );

		this.$carouselContainer = this.$element.closest( '.carousel-container' ).length ? this.$element.closest( '.carousel-container' ) : this.$element.parent();
		this.carouselNavElement = null;
		this.carouselDotsElement = null;
		this.carouselMobileDotsElement = null;
		this.$carouselCurrentSlide = null;
		this.$carouselCurrentSlideInner = null;
		this.$carouselTotalSlides = null;
		this.$carouselSlidesShape = null;
		this.carouselSlidesPathLength = this.options.numbersStyle === 'circle' ? 471 : 200;
		this.windowWidth = liquidWindowWidth();

		this.$carouselEl = this.options.carouselEl ? $( this.options.carouselEl, this.element ) : this.$element;
		this.carouselEl = this.$carouselEl[ 0 ];

		this.carouselInitPromise = new Promise( resolve => {
			this.$element.on( 'lqd-carousel-initialized', resolve.bind( this, this ) )
		} );

		if ( this.options.marquee ) {
			this.options.wrapAround = true;
		}

		/**
		 * DO NOT ADD FONT FACE OBSERVER INSIDE INNER FUNCTIONS
		 */

		// const headings = this.element.querySelectorAll('h1, h2, h3, h4, h5, h6');
		// const fontInfo = {};
		// const styles = getComputedStyle( $cellChildren[ 0 ] );

		// fontInfo.elementFontFamily = styles.fontFamily.replace( /"/g, '' ).replace( /'/g, '' ).split( ',' )[ 0 ];
		// fontInfo.elementFontWeight = styles.fontWeight;
		// fontInfo.elementFontStyle = styles.fontStyle;
		// fontInfo.lowecaseFontFamily = fontInfo.elementFontFamily.toLowerCase();

		// const font = new FontFaceObserver( fontInfo.elementFontFamily, {
		// 	weight: fontInfo.elementFontWeight,
		// 	style: fontInfo.elementFontStyle
		// } );

		// font.load().finally( () => {

		// 	window.liquidCheckedFonts.push( fontInfo.lowecaseFontFamily );

		// } );

		this.init();

	}

	Plugin.prototype = {

		init() {

			if ( this.options.asNavFor ) {

				const $targetEl = $( this.options.asNavFor );
				if ( $targetEl.length ) {
					$targetEl.liquidCarousel( { forceApply: true } );
					$targetEl.data( 'plugin_liquidCarousel' ).carouselInitPromise.then( () => {
						this.initFlicky();
					} )
				}

			} else {

				if ( this.options.forceApply ) {
					this.initFlicky();
				} else {
					this.setIO();
				}

			}

		},

		setIO() {

			new IntersectionObserver( ( [ entry ], observer ) => {
				if ( entry.isIntersecting ) {
					this.initFlicky();
					observer.unobserve( entry.target );
				}
			}, { rootMargin: '35%' } ).observe( this.element );

		},

		initFlicky() {

			const options = { ...this.options, rightToLeft: this.isRTL || this.options.rightToLeft };
			const { equalHeightCells } = this.options;

			imagesLoaded( this.element, () => {

				this.columnsAutoWidth();
				this.wrapItems();
				this.setEqualHeightCells();
				this.$carouselEl.flickity( options );
				this.flickityData = this.$carouselEl.data( 'flickity' );

				options.adaptiveHeight && $( '.flickity-viewport', this.element ).css( 'transition', 'height 0.3s' );

				this.onImagesLoaded();

				this.$element.addClass( 'lqd-carousel-ready' );

				const resize = this.flickityData.resize;
				const self = this;
				const { carouselEl } = this;

				/**
				 * to prevent default flickity behavior
				 * that's causing issues on marquee movement on ios devices when user scrolling
				 */
				this.flickityData.resize = function () {
					if ( self.windowWidth === liquidWindowWidth() ) return;
					if ( equalHeightCells ) {
						carouselEl.classList.remove( 'flickity-equal-cells' );
					}
					resize.call( this );
					if ( equalHeightCells ) {
						carouselEl.classList.add( 'flickity-equal-cells' );
					}
					self.windowWidth = liquidWindowWidth();
				};

			} );

		},

		onImagesLoaded() {

			if ( !this.flickityData ) return;

			this.sliderElement = this.element.querySelector( '.flickity-slider' );

			this.initPlugins();
			this.setElementNavArrow();
			this.carouselNav();
			this.navOffsets();
			this.carouselDots();
			this.carouselMobileDots();
			this.carouselDotsNumbers();
			this.addSlideNumbersToArrows();
			this.addSlidesCurrentNumbers();
			this.randomVerOffset();
			this.fullwidthSide();
			this.controllingCarousels();
			this.marquee();
			this.filtersInit();
			this.windowResize();
			this.events();
			this.dispatchEvents();

			// to fix the contents overlapping or getting wrong positions. mostly on mobile
			if ( this.options.columnsAutoWidth ) {
				this.$element.find( '.carousel-item-content' ).css( 'width', '' );
				this.flickityData.reposition();
			}

		},

		initPlugins() {

			this.element.hasAttribute( 'data-custom-animations' ) && this.$element.liquidCustomAnimations();

		},

		dispatchEvents() {

			const e = new CustomEvent( 'lqd-carousel-initialized', { detail: { carouselData: this } } );
			document.dispatchEvent( e );
			this.$element.trigger( 'lqd-carousel-initialized', this.element );

		},

		windowResize() {

			const onResize = liquidDebounce( this.doOnWindowResize.bind( this ), 200 )

			$( window ).on( 'resize.lqdCarousel', onResize );

		},

		doOnWindowResize() {

			// to prevent triggering on ios safari when scrolling page
			if ( this.windowWidth === window.innerWidth ) return;

			this.windowWidth = window.innerWidth;

			this.fullwidthSide();
			this.columnsAutoWidth();

			// to fix the contents overlapping or getting wrong positions. mostly on mobile
			if ( this.options.columnsAutoWidth ) {
				this.$element.find( '.carousel-item-content' ).css( 'width', '' );
				this.flickityData.reposition();
			}

		},

		events() {

			this.flickityData.on( 'pointerDown', () => {
				$liquidHtml.addClass( 'lqd-carousel-pointer-down' );
			} );

			this.flickityData.on( 'pointerUp', () => {
				$liquidHtml.removeClass( 'lqd-carousel-pointer-down' );
			} );

			// added for clients
			this.flickityData.on( 'dragStart', () => {
				$( '[data-column-clickable]', this.element ).css( 'pointer-events', 'none' );
			} );
			this.flickityData.on( 'dragEnd', () => {
				$( '[data-column-clickable]', this.element ).css( 'pointer-events', '' );
			} );

			// skit the rest of listeners if marquee is enabled
			if ( this.options.marquee ) return;

			this.flickityData.on( 'settle', () => {
				this.sliderElement.style.willChange = 'auto';
			} );

			this.flickityData.on( 'scroll', () => {
				this.sliderElement.style.willChange = 'transform';
				this.doSomethingCrazyWithFilter();
				this.parallax();
				this.changeSlidesShape();
			} );

			this.flickityData.on( 'change', () => {
				this.changeSlidesNumbers();
			} );

			$( document ).on( 'added_to_cart', ( event, frags, id, $btn ) => {
				if ( this.$carouselEl.has( $btn ) ) {
					this.flickityData.resize();
				}
			} );

			$( '[data-toggle=tab]' ).on( 'hidden.bs.tab shown.bs.tab', ( event ) => {

				const $link = $( event.target );
				const $target = $( $( $link.attr( 'href' ) ), $link.parent().next( '.lqd-tabs-content' ) );
				const $carouselEl = $target.find( '[data-lqd-flickity]' );
				let carouselData = $carouselEl.data( 'plugin_liquidCarousel' );

				if ( event.type === 'shown' ) {
					$carouselEl.on( 'lqd-carousel-initialized', () => {
						carouselData = $carouselEl.data( 'plugin_liquidCarousel' );
						const $navEl = $( carouselData.carouselNavElement );
						$navEl.siblings( '.carousel-nav' ).addClass( 'screen-reader-text' ).stop().fadeOut( 300 )
						$navEl.removeClass( 'screen-reader-text' ).stop().fadeIn( 300 );
					} );
					if ( carouselData ) {
						const $navEl = $( carouselData.carouselNavElement );
						$navEl.siblings( '.carousel-nav' ).addClass( 'screen-reader-text' ).stop().fadeOut( 300 )
						$navEl.removeClass( 'screen-reader-text' ).stop().fadeIn( 300 );
					}
				}

			} );

		},

		wrapItems() {

			const { middleAlignContent, equalHeightCells, randomVerOffset, skipWrapItems } = this.options;

			if ( skipWrapItems ) return;

			// it's elementor. or has .flickity-viewport element
			const $firstChild = this.$carouselEl.children().first();
			if ( $firstChild.hasClass( 'flickity-viewport' ) || $firstChild.hasClass( 'flickity-viewport-wrap' ) ) {

				const $cells = $firstChild.find( '.flickity-slider' ).children();

				$cells.each( ( i, cell ) => {

					const $cell = $( cell );
					const $cellContent = $cell.find( '.carousel-item-content' ).first();
					const hasOneChild = $cellContent.children().not( 'style' ).length === 1;

					if ( hasOneChild ) {
						$cell.addClass( 'has-one-child' );
					}

				} );

				return;

			};

			this.$carouselEl.children( 'p, style' ).insertBefore( this.$carouselEl );

			const $cells = this.$carouselEl.children();

			$cells.each( ( i, cell ) => {

				const $cell = $( cell );

				if (
					$cell.hasClass( 'vc_ld_carousel_section' ) ||
					$cell.hasClass( 'vc_ld_carousel_marquee_section' ) ||
					$cell.hasClass( 'vc_container-anchor' ) ||
					$cell.hasClass( 'lqd-sticky-stack-nav' ) ||
					$cell.is( 'pre' )
				) return;

				const cellHasInner = $cell.children().hasClass( 'carousel-item-inner' );
				const $cellToSearch = cellHasInner ? $cell.find( '.carousel-item-content' ) : $cell;
				const hasOneChild = $cellToSearch.children().not( 'style, .vc_controls-container' ).length === 1;
				let classnames;

				if ( $cell.attr( 'class' ) ) {

					if ( $cell.hasClass( 'lqd-prod-item' ) ) {
						classnames = $cell.attr( 'class' ).split( ' ' ).filter( classname => (
							classname !== 'lqd-prod-item' &&
							classname !== 'product'
						) );
					} else {
						classnames = $cell.attr( 'class' ).split( ' ' ).filter( classname => (
							classname.includes( 'vc_hidden-' ) ||
							classname.includes( 'hidden-' ) ||
							classname.includes( 'col-' ) ||
							classname.includes( 'vc_col-' )
						) );
					}

				}

				if ( $cell.hasClass( 'carousel-item' ) ) {
					middleAlignContent && equalHeightCells && !randomVerOffset && $cell.addClass( 'align-items-center' );
					hasOneChild && $cell.addClass( 'has-one-child' );
					if ( !$cell.children( '.carousel-item-inner' ).length ) {
						$cell
							.wrapInner( '<div class="carousel-item-inner" />' );
					}
					if ( !$cell.children( '.carousel-item-inner' ).children( '.carousel-item-content' ).length ) {
						$cell.children().wrapInner( '<div class="carousel-item-content" />' )
					}
				} else {
					$cell
						.wrap( `<div class="carousel-item ${ hasOneChild ? 'has-one-child' : '' } ${ classnames && classnames.join( ' ' ) } ${ middleAlignContent && equalHeightCells && !randomVerOffset ? 'align-items-center' : '' }" />` )
						.wrap( `<div class="carousel-item-inner" />` )
						.wrap( `<div class="carousel-item-content" />` );
				}

			} );

		},

		columnsAutoWidth() {

			if ( !this.options.columnsAutoWidth ) return;

			let $cells = this.$carouselEl.children();
			const $firstChild = $cells.first();
			if ( $firstChild.hasClass( 'flickity-viewport' ) || $firstChild.hasClass( 'flickity-viewport-wrap' ) ) {
				$cells = $firstChild.find( '.flickity-slider' ).children();
			}

			$cells.each( ( i, cell ) => {

				const $cell = $( cell );

				if ( $cell.hasClass( 'width-is-set' ) ) return;

				const $cellContentWrapper = $cell.find( '.carousel-item-content' );
				let $cellChildren = $cellContentWrapper.children().not( 'style' ).first();

				if ( $cellChildren.hasClass( 'ld-fancy-heading' ) ) {
					$cellChildren = $cellChildren.children();
				}

				this.setColumnWidth( $cell, $cellChildren, $cellContentWrapper );

			} )

		},

		setColumnWidth( $cell, $cellChildren, $cellContentWrapper ) {

			const width = $cellChildren.length ? $cellChildren.outerWidth() : $cellContentWrapper.outerWidth();

			$cellContentWrapper.css( 'width', width );
			$cell.css( 'width', 'auto' );

		},

		carouselNav() {

			if (
				!this.options.prevNextButtons ||
				!this.flickityData.prevButton ||
				!this.flickityData.nextButton
			) return;

			let appendingSelector = this.options.buttonsAppendTo;

			if ( appendingSelector === 'parent_row' ) {
				appendingSelector = liquidIsElementor ? this.$element.closest( '.elementor-section' ) : this.$element.closest( '.vc_row' );
			}
			if ( appendingSelector === 'parent_el' ) {
				appendingSelector = this.$element.parent();
			}
			if ( appendingSelector === 'self' ) {
				appendingSelector = this.$carouselContainer;
			}

			const $prevBtn = $( this.flickityData.prevButton.element );
			const $nextBtn = $( this.flickityData.nextButton.element );
			const $appendingSelector = $( appendingSelector );
			const $carouselNav = $( `<div class="carousel-nav"></div>` );
			const carouselNavClassnames = [];
			let carouselId = this.options.carouselEl ? this.$element.attr( 'id' ) : this.$carouselContainer.attr( 'id' );

			if ( liquidIsElementor ) {
				carouselId = `elementor-element elementor-element-${ this.$element.closest( '.elementor-element' ).attr( 'data-id' ) }`;
			}

			$.each( $( this.$carouselContainer[ 0 ].classList ), ( i, className ) => {
				if ( className.indexOf( 'carousel-nav-' ) >= 0 ) carouselNavClassnames.push( className );
			} );

			$carouselNav.addClass( [ ...carouselNavClassnames, carouselId ].join( ' ' ) );
			this.$carouselContainer.removeClass( carouselNavClassnames.join( ' ' ) );

			$carouselNav.append( [ $prevBtn, $nextBtn ] );

			if ( appendingSelector != null ) {

				if ( $appendingSelector.hasClass( 'vc_column_container' ) ) {

					const $wpbWrapperElement = $appendingSelector.children( '.vc_column-inner' ).children( '.wpb_wrapper' );
					$carouselNav.appendTo( $wpbWrapperElement );

				} else if ( $appendingSelector.hasClass( 'elementor-column' ) ) {

					const $wrappingEl = $appendingSelector.children( '.elementor-widget-wrap' );
					$wrappingEl.children( '.elementor-empty-view' ).remove();
					$carouselNav.appendTo( $wrappingEl );

				} else {

					if ( this.options.appendingBtnRel ) {
						$carouselNav.appendTo( this.$carouselEl[ this.options.appendingBtnRel ]( appendingSelector ) );
					} else {
						$carouselNav.appendTo( appendingSelector );
					}

				}

				$appendingSelector.addClass( 'carousel-nav-appended' );

			} else {

				$carouselNav.appendTo( this.$carouselContainer );

			}

			this.carouselNavElement = $carouselNav[ 0 ];

			this.options.prevNextButtonsOnlyOnMobile && this.carouselNavElement.classList.add( 'visible-xs', 'visible-sm' );

		},

		carouselDots() {

			if ( !this.options.pageDots ) return;

			const { dotsAppendTo, hasPageDotsFromOptions, carouselEl } = this.options;
			const dotsHolder = this.flickityData.pageDots.holder;
			const carouselDots = $( `<div class="carousel-dots ${ hasPageDotsFromOptions ? 'carousel-dots-from-options' : 'carousel-dots-to-hide' }"></div>` );
			let carouselId = carouselEl ? this.$carouselEl.attr( 'id' ) : this.$carouselContainer.attr( 'id' );
			let appendingSelector = dotsAppendTo;

			if ( liquidIsElementor ) {
				carouselId = `elementor-element elementor-element-${ this.$element.closest( '.elementor-element' ).attr( 'data-id' ) }`;
			}
			if ( appendingSelector === 'parent_row' ) {
				appendingSelector = liquidIsElementor ? this.$element.closest( '.elementor-section' ) : this.$element.closest( '.vc_row' );
			}
			if ( appendingSelector === 'self' ) {
				appendingSelector = this.$carouselContainer;
			}

			carouselDots.append( dotsHolder );

			const carouselDotsClassnames = [ carouselId ];

			$.each( $( this.$carouselContainer[ 0 ].classList ), ( i, className ) => {
				if ( className.indexOf( 'carousel-dots-' ) >= 0 ) carouselDotsClassnames.push( className );
			} );

			carouselDots.addClass( carouselDotsClassnames.join( ' ' ) );

			if ( appendingSelector != null ) {

				if ( $( appendingSelector ).hasClass( 'vc_column_container' ) ) {

					const $wpbWrapperElement = $( appendingSelector ).children( '.vc_column-inner ' ).children( '.wpb_wrapper' );
					carouselDots.appendTo( $wpbWrapperElement );

				} else if ( $( appendingSelector ).hasClass( 'elementor-column' ) ) {

					const $wrappingEl = $( appendingSelector ).children( '.elementor-widget-wrap' );
					$wrappingEl.children( '.elementor-empty-view' ).remove();
					carouselDots.appendTo( $wrappingEl );

				} else {

					carouselDots.appendTo( appendingSelector );

				}

				$( appendingSelector ).addClass( 'carousel-dots-appended' );

			} else {

				carouselDots.appendTo( this.$carouselContainer );

			}

			this.carouselDotsElement = carouselDots[ 0 ];

		},

		carouselMobileDots( force = false ) {

			if ( ( !this.options.pageDots || this.options.marquee ) && !force ) return;

			const { carouselEl } = this.options;
			const carouselId = carouselEl ? this.$carouselEl.attr( 'id' ) : this.$carouselContainer.attr( 'id' );
			const mobileDotsClassnames = [ carouselId ];

			$.each( $( this.$carouselContainer[ 0 ].classList ), ( i, className ) => {
				if ( className.indexOf( 'carousel-dots-mobile-' ) >= 0 ) mobileDotsClassnames.push( className );
			} );

			const $dotsHolder = $( this.flickityData.pageDots.holder ).clone( true );
			const $carouselMobileDots = $( `<div class="carousel-dots-mobile carousel-dots-style4 ${ mobileDotsClassnames.join( ' ' ) }"></div>` );

			$carouselMobileDots.append( $dotsHolder );

			if ( this.carouselDotsElement && this.$carouselEl.has( this.carouselDotsElement ).length ) {
				$carouselMobileDots.insertBefore( this.carouselDotsElement );
			} else {
				$carouselMobileDots.appendTo( this.$carouselContainer );
				$( this.carouselDotsElement ).addClass( 'hidden-xs hidden-sm md:hidden' );
			}

			this.carouselMobileDotsElement = $carouselMobileDots[ 0 ];

			const dots = this.carouselMobileDotsElement.querySelectorAll( '.dot' );
			dots.forEach( ( dot, i ) => {
				dot.addEventListener( 'click', () => {
					this.flickityData.select( i );
					this.carouselMobileDotsClasslist( dot, i );
				} )
			} );

			this.flickityData.on( 'select', i => this.carouselMobileDotsClasslist.call( this, dots[ i ], i ) );

		},

		carouselMobileDotsClasslist( activeItem, activeItemIndex ) {

			if ( !activeItem ) return;

			activeItem.classList.add( 'is-selected' );
			const inActives = [ ...this.carouselMobileDotsElement.querySelectorAll( '.dot' ) ].filter( ( inactiveItem, inactiveIndex ) => activeItemIndex !== inactiveIndex );
			inActives.forEach( inactiveItem => { inactiveItem.classList.remove( 'is-selected' ) } );

		},

		carouselDotsNumbers() {

			if ( !this.options.pageDots || this.options.dotsIndicator !== 'numbers' ) return;

			const { flickityData } = this;
			const { numbersStyle } = this.options;
			const $dotsHolder = $( flickityData.pageDots.holder );

			let $svgMarkup;

			if ( numbersStyle === 'circle' ) {
				const $numbers = this.createSlideNumbers( false );
				$svgMarkup = $( '<div class="lqd-carousel-slides-numbers d-inline-flex pos-rel inline-flex relative">' +
					'<svg xmlns="http://www.w3.org/2000/svg" width="150" height="152" viewBox="-2 0 154 150" class="w-100 h-100 w-full h-full">' +
					'<circle fill="none" cx="75" cy="75" r="74.5"/>' +
					'<path fill="none" stroke-dashoffset="' + this.carouselSlidesPathLength + '" stroke-dasharray="' + this.carouselSlidesPathLength + '" stroke-width="3" x="2" d="M75,150 C116.421356,150 150,116.421356 150,75 C150,33.5786438 116.421356,0 75,0 C33.5786438,0 0,33.5786438 0,75 C0,116.421356 33.5786438,150 75,150 Z"/>' +
					'</svg>' +
					'</div>' );
				$numbers.prependTo( $svgMarkup );
			} else if ( numbersStyle === 'line' ) {
				const $numbers = this.createSlideNumbers( true );
				$svgMarkup = $( '<div class="lqd-carousel-slides-numbers d-inline-flex pos-rel inline-flex relative lqd-carousel-numbers-line">' +
					'<svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke-width="2" width="200" height="1" viewBox="0 0 200 1" class="w-100 h-100 w-full h-full">' +
					'<path opacity="0.15" d="M1 1 201 1 201 2 1 2z"></path>' +
					'<path stroke-dashoffset="' + this.carouselSlidesPathLength + '" stroke-dasharray="' + this.carouselSlidesPathLength + '" d="M1 1 201 1 201 2 1 2z"></path>' +
					'</svg>' +
					'</div>' );
				$numbers.prependTo( $svgMarkup );
			}

			$dotsHolder.appendTo( $svgMarkup );
			$svgMarkup.appendTo( this.carouselDotsElement );

			this.$carouselTotalSlides = $( '.lqd-carousel-slides-total', $svgMarkup );
			this.$carouselCurrentSlide = $( '.lqd-carousel-slides-current', $svgMarkup );
			this.$carouselSlidesShape = $( 'svg', $svgMarkup );

		},

		addSlideNumbersToArrows() {

			if ( !this.options.prevNextButtons || !this.options.addSlideNumbersToArrows ) return;

			const { prevButton } = this.flickityData;
			const prevButtonEl = prevButton.element;
			const $numbers = this.createSlideNumbers();

			$numbers.insertAfter( prevButtonEl );

			this.$carouselTotalSlides = $( '.lqd-carousel-slides-total', $( prevButtonEl ).next( '.lqd-carousel-slides' ) );
			this.$carouselCurrentSlide = $( '.lqd-carousel-slides-current', $( prevButtonEl ).next( '.lqd-carousel-slides' ) );

		},

		createSlideNumbers( isZeroBased ) {

			const totalSlides = ( this.flickityData.slides.length < 10 && isZeroBased ? '0' : '' ) + ( this.flickityData.slides.length );

			const $markup = $( `<div class="lqd-carousel-slides d-flex align-items-center justify-content-center flex items-center justify-center lqd-overlay">
				<div class="lqd-carousel-slides-current d-inline-block overflow-hidden ws-nowrap text-center inline-block whitespace-nowrap"></div>
				<svg width="32" height="32" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" style="width: 1em; height: 1em;"><path fill="currentColor" d="M6 15.77a1 1 0 0 1 1-1h18.05a1 1 0 1 1 0 2h-18.04a1 1 0 0 1-1.01-1z"></path></svg>
				<div class="lqd-carousel-slides-total">${ totalSlides }</div>
			</div>`);

			return $markup;

		},

		addSlidesCurrentNumbers() {

			if (
				this.options.dotsIndicator !== 'numbers' &&
				!this.options.addSlideNumbersToArrows
			) {
				return false
			};

			const { flickityData } = this;
			const { numbersStyle } = this.options;
			const isZeroBased = numbersStyle === 'line';
			const $currentInner = $( '<div class="lqd-carousel-slides-current-inner d-inline-block pos-rel inline-block relative" />' );

			for ( let i = 1; i <= flickityData.slides.length; i++ ) {
				$currentInner.append( `<span class="d-inline-block inline-block" style="text-indent: 0;">${ i < 10 && isZeroBased ? '0' : '' }${ i }</span>` );
			}

			setTimeout( () => {
				const $spanEls = $currentInner.children( 'span' );
				const widths = $spanEls.map( ( i, el ) => $( el ).outerWidth( true ) );
				const maxWidth = Math.ceil( Math.max( ...widths ) );
				this.$carouselCurrentSlide.add( $spanEls ).css( 'width', maxWidth );
			}, 0 );

			$currentInner.appendTo( this.$carouselCurrentSlide );
			this.$carouselCurrentSlideInner = $currentInner;

		},

		changeSlidesNumbers() {

			if (
				this.options.dotsIndicator !== 'numbers' &&
				!this.options.addSlideNumbersToArrows
			) {
				return false
			};

			const { flickityData } = this;
			const { selectedIndex } = flickityData;
			const selectedNum = this.$carouselCurrentSlideInner.children( 'span' ).eq( selectedIndex )[ 0 ];

			this.$carouselCurrentSlideInner.css( {
				transition: 'transform 0.5s',
				transform: `translateX(${ ( selectedNum.offsetLeft ) * -1 }px)`
			} );

		},

		changeSlidesShape() {

			if ( this.options.pageDots && this.options.dotsIndicator !== 'numbers' ) {
				return false;
			}

			const { flickityData } = this;
			const $pathElement = this.$carouselSlidesShape.find( 'path' ).last();
			const pathLength = this.carouselSlidesPathLength;
			const slidesPercentage = Math.floor( ( Math.abs( Math.floor( flickityData.x + flickityData.cursorPosition ) ) / Math.abs( Math.floor( flickityData.slidesWidth ) ) ) * 100 );
			const dashOffset = pathLength - ( ( slidesPercentage / 100 ) * pathLength );

			$pathElement.css( 'stroke-dashoffset', Math.abs( dashOffset ) );

		},

		fullwidthSide() {

			if ( !this.options.fullwidthSide ) return;

			const viewportEl = $( this.flickityData.viewport );
			const elementWidth = this.flickityData.size.width - parseInt( this.$element.css( 'padding-left' ), 10 );
			const viewportElOffset = viewportEl.offset();
			const viewportElOffsetRight = this.windowWidth - ( elementWidth + viewportElOffset.left );
			const margin = !this.isRTL ? 'marginRight' : 'marginLeft';
			const padding = !this.isRTL ? 'paddingRight' : 'paddingLeft';
			let existingViewportWrap = viewportEl.parent( '.flickity-viewport-wrap' );
			let viewportElWrap =
				existingViewportWrap.length ?
					existingViewportWrap :
					$( '<div class="flickity-viewport-wrap overflow-hidden" />' );

			if ( !existingViewportWrap.length ) {
				viewportEl.wrap( viewportElWrap );
				viewportEl.removeClass( 'overflow-hidden' )
				viewportElWrap = viewportEl.parent();
				viewportEl.css( 'overflow', 'visible' );
			}

			viewportElWrap.css( {
				[ margin ]: '',
				[ padding ]: ''
			} );

			viewportElWrap.css( {
				[ margin ]: viewportElOffsetRight >= 0 ? ( viewportElOffsetRight - 1 ) * -1 : ( viewportElOffsetRight - 1 ),
				[ padding ]: Math.abs( viewportElOffsetRight - 1 )
			} );

			this.flickityData.resize();

		},

		randomVerOffset() {

			if ( this.options.randomVerOffset ) {

				const cellsArray = this.flickityData.cells;
				let maxHeight = 0;

				for ( let i = 0; i < cellsArray.length; i++ ) {

					const $cell = $( cellsArray[ i ].element );
					const itemHeight = cellsArray[ i ].size.height;

					if ( itemHeight > maxHeight ) {
						maxHeight = itemHeight;
					}

					const maxOffset = maxHeight - itemHeight;
					const offset = ( Math.random() * maxOffset ).toFixed();

					$cell.children( '.carousel-item-inner' ).css( "top", offset + "px" );

				}

			}

		},

		navOffsets() {

			const { options } = this;
			const { navOffsets } = options;
			const $carouselNavElement = $( this.carouselNavElement );

			if (
				navOffsets &&
				$carouselNavElement &&
				this.flickityData.options.prevNextButtons
			) {

				const prevButton = $( this.flickityData.prevButton.element );
				const nextButton = $( this.flickityData.nextButton.element );

				if ( navOffsets.nav ) {

					for ( const offset in navOffsets.nav ) {

						let val = navOffsets.nav[ offset ].trim();

						val.match( /^-?\d*(\.\d+){0,1}(%|in|cm|mm|em|rem|ex|pt|pc|px|vw|vh|vmin|vmax)$/ ) || ( val = isNaN( parseFloat( val ) ) ?
							"" :
							parseFloat( val ) + "px" );

						$carouselNavElement.css( offset.trim(), val );

					}

				}

				prevButton.css( {
					left: navOffsets.prev
				} );

				nextButton.css( {
					right: navOffsets.next
				} );

			}

		},

		setElementNavArrow() {

			if ( !this.options.prevNextButtons ) {
				return false;
			}

			const navArrowsArray = this.navArrows;
			const prevButton = this.flickityData.prevButton ? this.flickityData.prevButton.element : null;
			const nextButton = this.flickityData.nextButton ? this.flickityData.nextButton.element : null;
			let elementNavArrow = this.options.navArrow;
			let prevIcon;
			let nextIcon;

			if ( typeof elementNavArrow !== 'object' ) {

				elementNavArrow = elementNavArrow - 1;

				// if it's RTL, just reverse prev/next icons
				if ( !this.isRTL ) {
					prevIcon = $( navArrowsArray[ elementNavArrow ].prev );
					nextIcon = $( navArrowsArray[ elementNavArrow ].next );
				} else {
					prevIcon = $( navArrowsArray[ elementNavArrow ].next );
					nextIcon = $( navArrowsArray[ elementNavArrow ].prev );
				}

			} else {

				prevIcon = $( this.options.navArrow.prev );
				nextIcon = $( this.options.navArrow.next );

			}

			if ( prevButton || nextButton ) {

				$( prevButton ).find( 'svg' ).remove().end().append( prevIcon );
				$( nextButton ).find( 'svg' ).remove().end().append( nextIcon );

			}

		},

		navArrows: [

			{
				prev: '<svg width="27" height="16" viewBox="0 0 27 16" xmlns="http://www.w3.org/2000/svg"> <path d="M2.5 7.75H27V9H2.5L9 15L8 16L0 8.5L8 0L9 1L2.5 7.75Z" /> </svg>',
				next: '<svg width="27" height="16" viewBox="0 0 27 16" xmlns="http://www.w3.org/2000/svg"> <path d="M24.5 7.75H0V9H24.5L18 15L19 16L27 8.5L19 0L18 1L24.5 7.75Z"/> </svg>'
			},
			{
				prev: '<svg width="32" height="18" viewBox="0 0 32 18" xmlns="http://www.w3.org/2000/svg"> <path d="M8.77638 0.223663L10.2018 1.64911L3.85885 7.99209H32V10.008H3.85885L10.2018 16.3509L8.77638 17.7763L1.71102e-06 8.99997L8.77638 0.223663Z"/> </svg> ',
				next: '<svg width="32" height="18" viewBox="0 0 32 18" xmlns="http://www.w3.org/2000/svg"> <path d="M23.2236 0.223663L21.7982 1.64911L28.1412 7.99209H0V10.008H28.1412L21.7982 16.3509L23.2236 17.7763L32 8.99997L23.2236 0.223663Z"/> </svg>'
			},
			{
				prev: '<svg width="20" height="18" viewBox="0 0 32 28" xmlns="http://www.w3.org/2000/svg"> <path fill-rule="evenodd" clip-rule="evenodd" d="M12.9881 0.478424L0.377096 13.0899C-0.12566 13.5922 -0.12566 14.4072 0.377096 14.91L12.9881 27.5214C13.2395 27.7728 13.5685 27.8985 13.8979 27.8985C14.2274 27.8985 14.5564 27.7728 14.8077 27.5214C15.3105 27.0191 15.3105 26.2041 14.8077 25.7018L4.39347 15.2871H30.7132C31.424 15.2871 32.0001 14.7105 32.0001 14.0002C32.0001 13.2898 31.4239 12.7133 30.7132 12.7133H4.39338L14.8077 2.29851C15.3105 1.79619 15.3105 0.981181 14.8077 0.478424C14.305 -0.0238953 13.4909 -0.0238953 12.9881 0.478424Z"/> </svg>',
				next: '<svg width="20" height="18" viewBox="0 0 32 28" xmlns="http://www.w3.org/2000/svg"> <path fill-rule="evenodd" clip-rule="evenodd" d="M19.012 0.478424L31.623 13.0899C32.1257 13.5921 32.1257 14.4072 31.623 14.9099L19.012 27.5214C18.7606 27.7728 18.4316 27.8985 18.1021 27.8985C17.7727 27.8985 17.4437 27.7728 17.1923 27.5214C16.6896 27.0191 16.6896 26.2041 17.1923 25.7018L27.6066 15.287H1.28687C0.57605 15.287 0 14.7105 0 14.0002C0 13.2898 0.576111 12.7132 1.28687 12.7132H27.6067L17.1923 2.29849C16.6896 1.79617 16.6896 0.981171 17.1923 0.478424C17.6951 -0.0238953 18.5092 -0.0238953 19.012 0.478424Z"/> </svg>'
			},
			{
				prev: '<svg width="10" height="19" viewBox="0 0 33 60" xmlns="http://www.w3.org/2000/svg"> <path d="M1.41739 28L28.823 0.670159C29.7209 -0.224745 31.1747 -0.22324 32.0711 0.674788C32.9668 1.5727 32.9645 3.02725 32.0664 3.92285L6.29209 29.626L32.0674 55.3291C32.9653 56.2248 32.9676 57.6784 32.072 58.5765C31.6226 59.0266 31.0339 59.2517 30.4452 59.2517C29.8581 59.2517 29.2717 59.0281 28.8231 58.5811L1.41739 31.252C0.984926 30.8217 0.742248 30.2361 0.742248 29.626C0.742248 29.0159 0.98562 28.4311 1.41739 28Z"/> </svg>',
				next: '<svg width="10" height="19" viewBox="0 0 33 60" xmlns="http://www.w3.org/2000/svg"> <path d="M32.0671 28L4.66149 0.670159C3.76358 -0.224745 2.30984 -0.22324 1.41343 0.674788C0.517715 1.5727 0.52003 3.02725 1.41806 3.92285L27.1924 29.626L1.41713 55.3291C0.519219 56.2248 0.516905 57.6784 1.4125 58.5765C1.86186 59.0266 2.45056 59.2517 3.03926 59.2517C3.62645 59.2517 4.21283 59.0281 4.66138 58.5811L32.0671 31.252C32.4996 30.8217 32.7422 30.2361 32.7422 29.626C32.7422 29.0159 32.4989 28.4311 32.0671 28Z"/> </svg>'
			},
			{
				prev: '<svg width="16" height="17" viewBox="0 0 16 17" xmlns="http://www.w3.org/2000/svg"> <path fill-rule="evenodd" clip-rule="evenodd" d="M15.612 16.0721C15.6116 16.2693 15.4515 16.4289 15.2542 16.4286C15.1593 16.4286 15.0684 16.3908 15.0014 16.3236L7.14431 8.46655C7.00489 8.32706 7.00489 8.101 7.14431 7.96154L15.0014 0.104495C15.141 -0.0351572 15.3674 -0.0351572 15.5071 0.104495C15.6467 0.244147 15.6467 0.47055 15.5071 0.610202L7.90217 8.21436L15.5071 15.8186C15.5744 15.8857 15.6122 15.977 15.612 16.0721ZM9.18351 16.0721C9.18314 16.2693 9.02297 16.4289 8.82573 16.4286C8.73118 16.4286 8.64051 16.3911 8.57358 16.3243L0.716562 8.46727C0.577143 8.32778 0.577143 8.10171 0.716562 7.96226L8.57361 0.105214C8.71199 -0.0284448 8.9314 -0.0284448 9.06981 0.105214C9.21167 0.242255 9.21562 0.468357 9.07858 0.610219L1.47368 8.21438L9.07858 15.8186C9.14591 15.8857 9.18368 15.977 9.18351 16.0721Z"/> </svg>',
				next: '<svg width="16" height="17" viewBox="0 0 16 17" xmlns="http://www.w3.org/2000/svg"> <path d="M0.612 16.0721C0.61237 16.2693 0.772547 16.4289 0.969787 16.4286C1.06467 16.4286 1.15564 16.3908 1.22264 16.3236L9.07969 8.46655C9.21911 8.32706 9.21911 8.101 9.07969 7.96154L1.22264 0.104495C1.08299 -0.0351572 0.856586 -0.0351572 0.716933 0.104495C0.577281 0.244147 0.577281 0.47055 0.716933 0.610202L8.32183 8.21436L0.716933 15.8186C0.649602 15.8857 0.611834 15.977 0.612 16.0721Z"/> <path d="M7.04049 16.0721C7.04085 16.2693 7.20103 16.4289 7.39827 16.4286C7.49282 16.4286 7.58349 16.3911 7.65042 16.3243L15.5074 8.46727C15.6469 8.32778 15.6469 8.10171 15.5074 7.96226L7.65039 0.105214C7.51201 -0.0284448 7.2926 -0.0284448 7.15419 0.105214C7.01233 0.242255 7.00838 0.468357 7.14542 0.610219L14.7503 8.21438L7.14542 15.8186C7.07809 15.8857 7.04032 15.977 7.04049 16.0721Z"/> </svg>'
			},
			{
				prev: '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="13.4" viewBox="0 0 16 13.4"><path d="M9.3,1.3,7.9,2.7,12.2,7H0V9H12.2L7.9,13.3l1.4,1.4L16,8Z" transform="translate(16 14.7) rotate(180)"/></svg>',
				next: '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="13.4" viewBox="0 0 16 13.4"><path d="M9.3,13.4,7.9,12l4.3-4.3H0v-2H12.2L7.9,1.4,9.3,0,16,6.7Z"/></svg>'
			},
		],

		setEqualHeightCells() {

			if (
				!this.options.equalHeightCells ||
				this.element.classList.contains( 'flickity-equal-cells' )
			) return;

			const { carouselEl } = this;
			Flickity.prototype._createResizeClass = function () {
				carouselEl.classList.add( 'flickity-equal-cells' );
			};

			Flickity.createMethods.push( '_createResizeClass' );

		},

		parallax() {

			if ( !this.options.parallax || liquidIsMobile() ) {
				return false;
			}

			this.flickityData.cells.forEach( ( cell, i ) => {

				const multiply = !this.isRTL ? -1 : 1;
				const x = ( cell.target + this.flickityData.x ) * multiply / 3;
				const $cellElement = $( cell.element );
				const $cellImage = $cellElement.find( this.options.parallaxEl );

				if ( !$cellImage.parent( '.ld-carousel-parallax-wrap' ).length ) {
					$cellImage.wrap( '<div class="ld-carousel-parallax-wrap overflow-hidden"></div>' );
				}

				if ( $cellImage.is( ':only-child' ) ) {

					$cellImage.css( {
						willChange: 'transform',
						'-webkit-transform': `translateX(${ x }px)`,
						'transform': `translateX(${ x }px)`
					} );

				}

			} );

		},

		controllingCarousels() {

			const { options } = this;
			const { controllingCarousels } = options;

			if (
				typeof controllingCarousels !== typeof undefined &&
				controllingCarousels !== null &&
				controllingCarousels.length
			) {

				const $controlledCarousels = $( controllingCarousels.map( carousel => $( carousel ).children( '[data-lqd-flickity]' ) ) );

				$.each( $controlledCarousels, ( i, controlledCarousel ) => {

					const $controlledCarousel = $( controlledCarousel );

					$controlledCarousel.imagesLoaded( () => {

						const controlledCarouselData = $controlledCarousel.data( 'plugin_liquidCarousel' );

						if ( controlledCarouselData ) {
							controlledCarouselData.carouselInitPromise.then( () => {
								$controlledCarousel.parent().addClass( 'is-controlled-carousel' );
								controlledCarouselData.carouselMobileDotsElement && controlledCarouselData.carouselMobileDotsElement.classList.add( 'hidden' );
								this.flickityData.on( 'change', i => {
									controlledCarouselData.flickityData.select( i );
								} );
								controlledCarouselData.flickityData.on( 'change', i => {
									this.flickityData.select( i );
								} );
							} )
						}

					} );

				} );

			}

		},

		getCellsArray() {

			return this.flickityData.cells.map( cell => cell.element );

		},

		doSomethingCrazyWithFilter() {

			if (
				!this.options.doSomethingCrazyWithFilters ||
				liquidIsMobile() ||
				this.windowWidth <= 992
			) return false;

			const header = $( '.lqd-pf-carousel-header', this.$carouselContainer )[ 0 ];

			if ( !header ) return false;

			const { x, size } = this.flickityData;
			const firstVisibleCell = this.flickityData.cells.filter( cell => $( cell.element ).is( ':visible' ) )[ 0 ];
			const firstCellWidth = firstVisibleCell.size.width;

			const opacityVal = gsap.utils.normalize( -firstCellWidth, 0, this.isRTL ? x + size.width : x );
			const rotationVal = gsap.utils.mapRange( 0, -firstCellWidth, 0, -100, this.isRTL ? x + size.width : x );
			const zVal = gsap.utils.mapRange( 0, -firstCellWidth, 0, -300, this.isRTL ? x + size.width : x );

			$( header ).parent().addClass( 'perspective' );

			gsap.to( header, {
				opacity: opacityVal,
				z: zVal,
				rotationY: rotationVal,
				duration: 0.6,
				ease: 'expo.out'
			} );

		},

		filtersInit() {

			if ( !this.options.filters ) return;

			const { filtersCounter, filters } = this.options;
			const $filters = $( filters );
			const $filterItems = $( '[data-filter]', $filters );
			const $filterDropdown = $filters.siblings( '.lqd-filter-dropdown' );

			$filterItems.each( ( i, filterItem ) => {

				const $filterItem = $( filterItem );
				const filterValue = $filterItem.attr( 'data-filter' );

				filtersCounter && this.addFilterCounts( $filterItem, filterValue );

				$filterItem.off( 'click' );
				$filterItem.on( 'click.lqdCarouselFilter', () => {
					if ( !$filterItem.hasClass( 'active' ) ) {
						$filterItem.addClass( 'active' ).siblings().removeClass( 'active' );
						this.filterAnimateStart( filterValue );
					}
				} );

			} );

			if ( $filterDropdown.length ) {
				$( 'select', $filterDropdown ).on( 'selectmenuchange', ( event, ui ) => {
					const filterVal = ui.item.value;
					this.filterAnimateStart( filterVal );
				} )
			}

		},

		addFilterCounts( $filterItem, filterValue ) {

			const count = filterValue === '*' ? this.flickityData.cells.length : $( filterValue, this.element ).length;
			const $counter = $( `
				<sup class="lqd-filter-counter">
					<span>${ count }</span>
				</sup>`
			);
			$counter.appendTo( $filterItem );

		},

		filterAnimateStart( filterValue ) {

			const visibleCells = this.getCellsArray().filter( element => !element.classList.contains( 'hidden' ) );

			gsap.to( visibleCells, {
				x: '-=10%',
				opacity: 0,
				ease: 'power4.inOut',
				duration: 0.6,
				stagger: 0.1,
				clearProps: 'x',
				onStart: () => {

					if ( this.options.equalHeightCells ) {

						const $cells = $( this.flickityData.cells );
						const currentHeight = this.flickityData.size.height;

						$cells.css( 'minHeight', currentHeight );

					}

					$( visibleCells ).css( {
						transition: 'none'
					} );

				},
				onComplete: this.filterItems.bind( this, filterValue )
			} );

		},

		filterItems( filterValue ) {

			const $cells = $( this.getCellsArray() );

			this.$element.find( '.hidden' ).removeClass( 'hidden' );

			if ( filterValue !== '*' ) {
				$cells.not( filterValue ).addClass( 'hidden' );
			}

			if ( this.options.equalHeightCells ) {
				$cells.css( 'minHeight', '' );
			}

			this.flickityData.resize();
			this.flickityData.reposition();

			if ( this.flickityData.slides.length <= 1 ) {
				this.flickityData.options.draggable = false;
			} else {
				this.flickityData.options.draggable = true;
			}
			this.flickityData.updateDraggable();

			this.filterAnimateComplete();

		},

		filterAnimateComplete() {

			const visibleCells = this.getCellsArray().filter( element => !element.classList.contains( 'hidden' ) );
			const timeline = gsap.timeline( {
				defaults: {
					duration: 0.6,
					ease: 'power4.out',
				},
				onComplete: () => {
					$( visibleCells ).css( {
						transition: '',
						opacity: ''
					} );
				}
			} );

			visibleCells.forEach( cell => {
				const currentX = gsap.getProperty( cell, 'x', '%' );
				timeline.fromTo( cell,
					{
						x: '+=10%'
					},
					{
						x: currentX,
						opacity: 1
					}, '<+=0.1' );
			} )

			if ( this.carouselMobileDotsElement ) {
				this.carouselMobileDotsElement.remove();
				this.carouselMobileDots( true );
			}

		},

		// https://github.com/metafizzy/flickity/issues/77
		marquee() {

			if ( !this.options.marquee ) return;

			this.marqueeIsPaused = true;
			this.flickityData.x = 0;

			const IO = () => {
				new IntersectionObserver( ( [ entry ] ) => {
					if ( entry.isIntersecting ) {
						this.sliderElement.style.willChange = 'transform';
						this.marqueePlay();
					} else {
						this.sliderElement.style.willChange = '';
						this.marqueePause();
					}
				}, { rootMargin: '50%' } ).observe( this.element );
			};

			if ( this.options.pauseAutoPlayOnHover ) {
				this.element.addEventListener( 'mouseenter', this.marqueePause.bind( this ), false );
				this.element.addEventListener( 'focusin', this.marqueePause.bind( this ), false );
				this.element.addEventListener( 'mouseleave', this.marqueePlay.bind( this ), false );
				this.element.addEventListener( 'focusout', this.marqueePlay.bind( this ), false );
			}

			this.flickityData.on( 'dragStart', this.marqueePause.bind( this ) );
			this.flickityData.on( 'dragEnd', !this.options.pauseAutoPlayOnHover && this.marqueePlay.bind( this ) );

			IO();

		},

		marqueePlay() {
			if ( !this.marqueeIsPaused ) return;
			this.marqueeIsPaused = false;
			this.marqueeUpdate();
		},

		marqueePause() {
			this.marqueeIsPaused = true;
			this.marqueeRAF && cancelAnimationFrame( this.marqueeRAF );
		},

		marqueeUpdate() {

			if ( this.marqueeIsPaused || !this.flickityData.slides ) return;

			this.flickityData.x = ( this.flickityData.x - this.options.marqueeTickerSpeed ) % this.flickityData.slideableWidth;
			this.flickityData.settle( this.flickityData.x );

			this.marqueeRAF = window.requestAnimationFrame( this.marqueeUpdate.bind( this ) );

		},

		destroy() {

			$( window ).off( 'resize.lqdCarousel' );

		}

	};

	$.fn[ pluginName ] = function ( options ) {

		return this.each( function () {

			const $carouselElement = $( this )
			const pluginOptions = { ...$carouselElement.data( 'lqd-flickity' ), ...options };

			const globalDisabledOnMobile = document.body.hasAttribute( 'data-disable-carousel-onmobile' );

			if ( ( liquidIsMobile() && globalDisabledOnMobile ) && !pluginOptions.forceEnableOnMobile ) {
				$carouselElement.find( '.flickity-viewport' ).css( 'overflow-x', 'auto' );
				return;
			};

			if ( pluginOptions.pageDots ) {
				pluginOptions.hasPageDotsFromOptions = true;
			} else {
				pluginOptions.hasPageDotsFromOptions = false;
			}

			if ( !pluginOptions.forceDisablePageDots ) {
				pluginOptions.pageDots = true;
			}

			if ( !$.data( this, "plugin_" + pluginName ) ) {
				$.data( this, "plugin_" + pluginName, new Plugin( this, pluginOptions ) );
			}

		} );

	};

}( jQuery ) );

jQuery( document ).ready( function ( $ ) {

	$( '[data-lqd-flickity]' ).liquidCarousel();

} );