( function ( $ ) {

	'use strict';

	const pluginName = 'liquidWoo';
	let defaults = {
	};

	class Plugin {

		constructor( element, options ) {

			this._defaults = defaults;
			this._name = pluginName;

			this.options = { ...defaults, ...options };

			this.DOM = {};
			this.DOM.$element = $( element );
			this.DOM.$headerCart = $( '.ld-module-cart' );
			this.DOM.$snickersBarTemp = $( '#lqd-temp-snickersbar' );
			this.DOM.$snickersBar = null;
			this.snickersBarsHeight = 20;
			this.cartItems = [];

			this.init();

		}

		init() {

			this.events();

		}

		events() {

			$( document ).on( 'adding_to_cart', ( event, $btn, data ) => { this.onAddingToCart.call( this, $btn, data ) } );
			$( document ).on( 'added_to_cart', ( event, frags, cartHash, $btn ) => { this.onAddedToCart.call( this, frags, $btn ) } );
			$( document ).on( 'removed_from_cart', ( event, frags ) => { this.onRemovedFromCart.call( this, frags ) } );
			$( document ).on( 'updated_wc_div', this.onUpdatedWcDiv.bind( this ) );
			$( '.widget_price_filter form' ).on( 'submit', this.onWcanAjaxLoading.bind( this ) );
			$( document ).on( 'yith-wcan-ajax-loading', this.onWcanAjaxLoading.bind( this ) );
			$( document ).on( 'yith-wcan-ajax-filtered ajaxComplete', this.onWCanAjaxFiltered.bind( this ) );
			$( document ).on( 'qv_loader_stop', () => { this.formIntputsInit.call( this ) } );

		}

		onAddingToCart( $btn, data ) {

			this.initSnickersBar( $btn, data );

		}

		initSnickersBar( $btn, data ) {

			const cartItem = this.cartItems.find( item => item.id === data.product_id || item.id === $btn.attr( 'data-product_id' ) || item.id === $btn.attr( 'value' ) );

			if ( cartItem ) {

				const { $snickersBarEl, snickersBarHeight, isVisible } = cartItem;

				$snickersBarEl.removeClass( 'lqd-snickersbar-action-done' );

				if ( !isVisible ) {
					$snickersBarEl.addClass( 'lqd-snickersbar-in' ).removeClass( 'lqd-snickersbar-out' );
					cartItem.isVisible = true;
					this.snickersBarsHeight += snickersBarHeight;
				}

				this.hideSnickersBar( cartItem );

			} else {

				this.createSnickersBar( $btn, data );

			}

			this.upadteSnickersBarsPos();

		}

		createSnickersBar( $btn, data ) {

			let { product_id, product_name } = data;
			const snickersClone = this.DOM.$snickersBarTemp[ 0 ].content.cloneNode( true );
			const $snickersBar = $( '.lqd-snickersbar', snickersClone );
			const $addningMsgTemp = $( '.lqd-snickersbar-addding-temp', $snickersBar );
			const $addedMsgTemp = $( '.lqd-snickersbar-added-temp', $snickersBar );

			const $msg = $( '.lqd-snickersbar-msg', $snickersBar );
			const $msgDone = $( '.lqd-snickersbar-msg-done', $snickersBar );
			const $checkIcon = $( '<svg width="32" height="29" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 29" style="height: 1.25em; margin-inline-start: .25rem;"><path fill="currentColor" d="M25.74 6.23c0.38 0.34 0.42 0.9 0.09 1.28l-12.77 14.58a0.91 0.91 0 0 1-1.33 0.04l-5.46-5.46a0.91 0.91 0 1 1 1.29-1.29l4.77 4.78 12.12-13.85a0.91 0.91 0 0 1 1.29-0.08z"></path></svg>' );
			const $spinIcon = $( '<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" style="height: 1em; margin-inline-start: .25rem;"><path fill="currentColor" d="M4.005 16.03c0-5.945 4.344-10.842 10.027-11.802v1.784l4.004-3.006L14.032 0v2.162C7.244 3.142 2.007 8.98 2.007 16.03c0 5.072 2.715 9.503 6.75 11.976l1.745-1.31c-3.85-2.007-6.487-6.03-6.497-10.666zm26.056 0c0-5.072-2.716-9.504-6.75-11.967l-1.745 1.31c3.85 2.006 6.487 6.03 6.487 10.656 0 5.944-4.344 10.86-10.017 11.82v-1.793l-4.004 3.006 4.004 3.006v-2.172c6.788-.98 12.025-6.817 12.025-13.867z"></path></svg>' )

			if ( $liquidBody.hasClass( 'single' ) ) {
				if ( !product_name ) {
					product_name = $( 'h1[itemprop=name].entry-title' ).text()
				}
				if ( !product_id ) {
					product_id = $btn.attr( 'data-product_id' ) || $btn.attr( 'value' );
				}
			}

			$msg.text( $addningMsgTemp.text().replace( /\{\{itemName\}\}/, product_name ) );
			$msgDone.text( $addedMsgTemp.text().replace( /\{\{itemName\}\}/, product_name ) );

			$snickersBar.attr( 'data-item-id', product_id );

			$msg.append( $spinIcon );
			$msgDone.append( $checkIcon );
			$liquidBody.append( $snickersBar );

			const newCartItem = {
				id: product_id,
				name: product_name,
				$snickersBarEl: $snickersBar,
				$msgEl: $msg,
				$msgDoneEl: $msgDone,
				snickersBarHeight: $snickersBar.outerHeight( true ),
				isVisible: true,
				timeout: null
			}

			this.snickersBarsHeight += newCartItem.snickersBarHeight;

			this.cartItems.push( newCartItem );

		}

		onAddedToCart( frags, $btn ) {

			this.updateCartAmount( frags );

			const cartItem = this.cartItems.find( item => item.id === $btn.attr( 'data-product_id' ) || item.id === $btn.attr( 'value' ) );

			if ( cartItem ) {

				const { $snickersBarEl } = cartItem;
				const $snickersBarExtEl = $( '.lqd-snickersbar-ext', $snickersBarEl );

				!$snickersBarExtEl.children( '.added_to_cart' ).length && $snickersBarExtEl.append( $btn.siblings( '.added_to_cart' ).clone( true ) );

				$snickersBarEl.addClass( 'lqd-snickersbar-action-done' );

				this.hideSnickersBar( cartItem );

			}

		}

		hideSnickersBar( cartItem ) {

			const { $snickersBarEl, snickersBarHeight, isVisible } = cartItem;
			const timeOutVal =
				liquidIsElementor ?
					getComputedStyle( document.body ).getPropertyValue( '--lqd-snickersbar-stay-time' ) :
					getComputedStyle( document.documentElement ).getPropertyValue( '--lqd-snickersbar-stay-time' );

			if ( cartItem.timeout ) {

				clearTimeout( cartItem.timeout );
				cartItem.timeout = null;
				$snickersBarEl.addClass( 'lqd-snickersbar-in' ).removeClass( 'lqd-snickersbar-out' );
				cartItem.isVisible = true;

			} else {

				cartItem.timeout = setTimeout( () => {

					if ( isVisible ) {
						$snickersBarEl.addClass( 'lqd-snickersbar-out' ).removeClass( 'lqd-snickersbar-in' );
						cartItem.isVisible = false;
						this.snickersBarsHeight -= snickersBarHeight;
					}
					this.upadteSnickersBarsPos();
					clearTimeout( cartItem.timeout );

				}, parseFloat( timeOutVal ) * 1000 );

			}

		}

		upadteSnickersBarsPos() {

			let heights = 0;

			this.cartItems.forEach( item => {
				if ( item.isVisible ) heights += item.snickersBarHeight;
				item.$snickersBarEl.css( 'transform', `translateY(${ ( this.snickersBarsHeight - heights ) * -1 }px)` )
			} );

		}

		onRemovedFromCart( frags ) {

			this.updateCartAmount( frags );
			this.lazyLoadUpdate();

		}

		onUpdatedWcDiv() {

			this.formIntputsInit();

		}

		updateCartAmount( frags ) {

			if ( !frags ) return;

			const $headerCartAmount = this.DOM.$headerCart.find( '.ld-module-trigger-txt .woocommerce-Price-amount' );

			const amount = $( frags[ 'span.header-cart-amount' ] );

			if ( $headerCartAmount.length && amount ) {
				$headerCartAmount.text( amount.text() )
			}
		}

		lazyLoadUpdate() {

			if ( window.liquidLazyload ) {
				window.liquidLazyload.update();
			}

		}

		formIntputsInit() {

			$( 'form' ).liquidFormInputs();

		}

		onWCanAjaxFiltered() {

			this.lazyLoadUpdate();
			$( '.widget' ).removeClass( 'wcan-ajax-loading' );

		}

		onWcanAjaxLoading() {

			$( '.widget' ).addClass( 'wcan-ajax-loading' );

		}

	}

	$.fn[ pluginName ] = function ( options ) {

		return this.each( function () {

			const pluginOptions = { ...$( this ).data( 'woo-options' ), ...options };

			if ( !$.data( this, "plugin_" + pluginName ) ) {
				$.data( this, "plugin_" + pluginName, new Plugin( this, pluginOptions ) );
			}

		} );

	};

}( jQuery ) );

jQuery( document ).ready( function ( $ ) {
	if ( $( '.woocommerce' ).length ) {
		$liquidBody.liquidWoo();
	}
} );