( function ( $ ) {

	'use strict';

	const pluginName = 'liquidCarouselStack';
	let defaults = {
		autoplay: false,
		// distDragBack: if the user stops dragging the image in a area that does not exceed [distDragBack]px for either x or y then the image goes back to the stack
		distDragBack: 150,
		// distDragMax: if the user drags the image in a area that exceeds [distDragMax]px for either x or y then the image moves away from the stack
		distDragMax: 450,
		// callback
		isRandom: false,
		onUpdateStack: function ( current ) { return false; }
	};

	class Plugin {

		constructor( element, options ) {

			this.element = element;
			this.$element = $( element );
			this.$container = $( '.carousel-items', this.element );
			this.$prevBtn = $( '.lqd-carousel-stack-prev', this.element );
			this.$nextBtn = $( '.lqd-carousel-stack-next', this.element );
			this.items = this.$container.children( '.carousel-item' ).get();

			this.options = $.extend( {}, defaults, options );
			this._defaults = defaults;
			this._name = pluginName;

			this.isInit = false;
			this.moveVector = {};
			this.draggie = null;

			this._init();

			if ( this.options.autoplay ) {
				this.autoplay();
			}

		}

		autoplay() {

			if ( isNaN( this.options.autoplay ) || this.options.autoplay <= 0 ) return;

			this.autoplayTimeout = setTimeout( () => {
				this._moveAway( 'next' );
			}, this.options.autoplay );

		}

		shuffle( array ) {

			let m = array.length;
			let t;
			let i;

			while ( m ) {
				i = Math.floor( Math.random() * m-- );
				t = array[ m ];
				array[ m ] = array[ i ];
				array[ i ] = t;
			}

			return array;

		}

		setTransformStyle( el, tval ) {
			el.style.WebkitTransform = tval;
			el.style.msTransform = tval;
			el.style.transform = tval;
		}

		initSetting() {
			this.itemsCount = this.items.length;
			this._setContainerHeight();
			this._setStackStyle();
			if ( this.itemsCount <= 1 ) return;
			if ( !this.isInit ) {
				this._initDragg();
				this._initEvents();
			}
			this.isInit = true;
		}

		_init() {
			if ( this.options.isRandom ) {
				this.shuffle( this.items );
			}
			// current item's index (the one on the top of the stack)
			this.current = 0;
			// set initial styles
			this.initSetting();
		}

		_initEvents() {

			const onResize = liquidDebounce( this.onResize.bind( this ), 750 );

			this.draggie.on( 'dragMove', ( event, pointer, moveVector ) => { this._onDragMove.call( this, event, moveVector ) } );
			this.draggie.on( 'dragEnd', ( event ) => { this._onDragEnd.call( this, event ) } );
			this.$prevBtn.on( 'click', this.goToPrev.bind( this ) );
			this.$nextBtn.on( 'click', this.goToNext.bind( this ) );

			$liquidWindow.on( 'resize.lqdCarouselStack', onResize );

		}

		_setContainerHeight() {

			this.element.style.transition = `height 0.3s`;
			this.element.style.height = `${ $( this._firstItem() ).outerHeight() }px`;

		}

		_setStackStyle( direction ) {

			var item1 = this._firstItem(), item2 = this._secondItem(), item3 = this._thirdItem();

			this.items.forEach( item => item.classList.remove( 'is-first', 'is-second', 'is-third' ) );

			if ( item1 ) {

				item1.style.zIndex = 4;
				item1.classList.add( 'is-first' );

				gsap.to( item1, {
					ease: 'power4.out',
					duration: 0.6,
					x: 0,
					y: 0,
					z: 0
				} )

			}

			if ( item2 ) {

				item2.style.zIndex = 3;
				item2.classList.add( 'is-second' );

				gsap.to( item2, {
					startAt: {
						x: 0,
						y: 0,
						z: () => {
							if ( !direction || direction === 'next' ) { return -180 }
							else { return 0 }
						}
					},
					x: 0,
					y: 0,
					z: () => {
						if ( !direction || direction === 'next' ) { return -80 }
						else { return -80 }
					},
					ease: 'power4.out',
					duration: 0.6,
				} );

			}

			if ( item3 ) {

				item3.style.zIndex = 2;
				item3.classList.add( 'is-third' );

				gsap.to( item3, {
					startAt: {
						x: 0,
						y: 0,
						z: () => {
							if ( !direction || direction === 'next' ) { return -280 }
							else { return 0 }
						}
					},
					x: 0,
					y: 0,
					z: () => {
						if ( !direction || direction === 'next' ) { return -180 }
						else { return -180 }
					},
					duration: 0.6,
					ease: 'power4.out'
				}
				);

			}
		}

		_moveAway( direction ) {

			if ( this.animating ) return;

			// calculate how much to translate in the x and y axis
			const tVal = this._getTranslateVal( direction );
			let item1;
			let initiated = false;

			this.animating = true;

			// disable drag
			this._disableDragg();

			if ( !direction || direction === 'next' ) {
				item1 = this.draggie.element;
			} else {
				item1 = this.draggie.element.previousElementSibling || this.items[ this.itemsCount - 1 ];
				this.draggie.element.style.zIndex = 3;
				item1.style.zIndex = 4;
			}
			gsap.killTweensOf( item1 )
			// apply it
			const item1Tween = gsap.to( item1, {
				startAt: {
					z: tVal.z[ 0 ],
					opacity: () => {
						if ( direction !== 'prev' ) { return 1 }
						else { return 0 }
					},
				},
				duration: 0.6,
				ease: 'power4.out',
				x: tVal.x,
				y: tVal.y || 0,
				z: tVal.z[ 1 ],
				opacity: () => {
					if ( direction !== 'prev' ) { return 0 }
					else { return 1 }
				},
				onUpdate: () => {
					if ( item1Tween.progress() >= 0.5 && !initiated ) {
						initiated = true;
						this.onEndTransFn( direction );
					}
				},
				onComplete: () => {
					this.onCompleteTransFn( item1 )
				}
			} );

			// other items move back to stack
			const item2 = this._secondItem();
			const item3 = this._thirdItem();

			if ( item2 ) {
				gsap.to( item2, {
					ease: 'power4.out',
					duration: 0.6,
					x: 0,
					y: 0,
					z: -80
				} );
			}
			if ( item3 ) {
				gsap.to( item3, {
					ease: 'power4.out',
					duration: 0.6,
					x: 0,
					y: 0,
					z: -180
				} );
			}

		}

		onEndTransFn( direction ) {

			// reset first item
			gsap.to( this.draggie.element, {
				x: 0,
				y: 0,
				z: -180,
				ease: 'power4.out',
				duration: 0.6,
				onComplete: () => {
					this.draggie.element.style.transform = '';
				}
			} );

			// reorder stack
			if ( !direction || direction === 'next' ) {
				this.draggie.element.style.left = this.draggie.element.style.top = '0px';
				this.draggie.element.style.zIndex = -1;
				this.current = this.current < this.itemsCount - 1 ? this.current + 1 : 0;
			} else {
				this.draggie.element.style.zIndex = 4;
				this.current = this.current > 0 ? this.current - 1 : this.itemsCount - 1;
			}

			// reset style
			this._setStackStyle( direction )

			// add dragging capability
			this._initDragg();

			// init drag events on new current item
			this._initEvents();

			// callback
			this.options.onUpdateStack( this.current );

			this._setContainerHeight();

		}

		onCompleteTransFn( animatedTarget ) {

			this.animating = false;

			if ( this.autoplayTimeout ) {
				clearTimeout( this.autoplayTimeout );
			}

			if ( this.options.autoplay ) {
				this.autoplay();
			}

			animatedTarget.style.opacity = '';

		}

		_moveBack() {

			const item2 = this._secondItem();
			const item3 = this._thirdItem();

			gsap.to( this.draggie.element, {
				startAt: {
					x: this.moveVector.x,
					y: this.moveVector.y
				},
				ease: 'power4.out',
				duration: 0.6,
				x: 0,
				y: 0,
				z: 0
			} );
			this.draggie.element.style.left = '0px';
			this.draggie.element.style.top = '0px';

			if ( item2 ) {
				gsap.to( item2, {
					ease: 'power4.out',
					duration: 0.6,
					x: 0,
					y: 0,
					z: -80
				} );
			}
			if ( item3 ) {
				gsap.to( item3, {
					ease: 'power4.out',
					duration: 0.6,
					x: 0,
					y: 0,
					z: -180
				} );
			}

		}

		_onDragMove( event, moveVector ) {

			this.moveVector = moveVector;

			if ( this._outOfBounds() ) {
				this._moveAway();
			} else {
				// the second and third items also move
				const item2 = this._secondItem();
				const item3 = this._thirdItem();
				if ( item2 ) {
					gsap.to( item2, {
						ease: 'power4.out',
						duration: 0.3,
						x: this.moveVector.x * 0.6,
						y: this.moveVector.y * 0.6
					} );
				}
				if ( item3 ) {
					gsap.to( item3, {
						ease: 'power4.out',
						duration: 0.3,
						x: this.moveVector.x * 0.3,
						y: this.moveVector.y * 0.3
					} );
				}
			}
		}

		_onDragEnd() {
			if ( this._outOfBounds() ) return;
			if ( this._outOfSight() ) {
				this._setContainerHeight();
				this._moveAway();
			}
			else {
				this._moveBack();
			}
		}

		_initDragg() {
			this.draggie = new Draggabilly( this.items[ this.current ], { handle: '.lqd-carousel-handle' } );
		}

		_disableDragg() {
			this.draggie.disable();
		}

		// returns true if x or y is bigger than distDragMax
		_outOfBounds() {
			return Math.abs( this.moveVector.x ) > this.options.distDragMax || Math.abs( this.moveVector.y ) > this.options.distDragMax;
		}

		// returns true if x or y is bigger than distDragBack
		_outOfSight() {
			return Math.abs( this.moveVector.x ) > this.options.distDragBack || Math.abs( this.moveVector.y ) > this.options.distDragBack;
		}

		_getTranslateVal( direction ) {

			var h = Math.sqrt( Math.pow( this.moveVector.x, 2 ) + Math.pow( this.moveVector.y, 2 ) ),
				a = Math.asin( Math.abs( this.moveVector.y ) / h ) / ( Math.PI / 180 ),
				hL = h + this.options.distDragBack,
				dx = Math.cos( a * ( Math.PI / 180 ) ) * hL,
				dy = Math.sin( a * ( Math.PI / 180 ) ) * hL,
				tx = dx - Math.abs( this.moveVector.x ),
				ty = dy - Math.abs( this.moveVector.y );

			if ( !direction ) {
				return {
					x: this.moveVector.x > 0 ? tx : tx * -1,
					y: this.moveVector.y > 0 ? ty : ty * -1,
					z: [ 0, 0 ]
				}
			} else if ( direction === 'prev' ) {
				return {
					x: 0,
					y: 0,
					z: [ 80, 0 ]
				}
			} else if ( direction === 'next' ) {
				return {
					x: 0,
					y: 0,
					z: [ 0, 80 ]
				}
			}
		}

		// returns the first item in the stack
		_firstItem() {
			return this.items[ this.current ];
		}

		// returns the second item in the stack
		_secondItem() {
			if ( this.itemsCount >= 2 ) {
				return this.current + 1 < this.itemsCount ? this.items[ this.current + 1 ] : this.items[ Math.abs( this.itemsCount - ( this.current + 1 ) ) ];
			}
		}

		// returns the third item in the stack
		_thirdItem() {
			if ( this.itemsCount >= 3 ) {
				return this.current + 2 < this.itemsCount ? this.items[ this.current + 2 ] : this.items[ Math.abs( this.itemsCount - ( this.current + 2 ) ) ];
			}
		}

		// returns the last item (of the first three) in the stack
		_lastItem() {
			if ( this.itemsCount >= 3 ) {
				return this._thirdItem();
			}
			else {
				return this._secondItem();
			}
		}

		goToPrev() {

			this._moveAway( 'prev' );

		}

		goToNext() {

			this._moveAway( 'next' );

		}

		add( el ) {
			this.$container.appendChild( el );
			this.items.push( el );
			this.initSetting();
		}

		getSize() {
			return this.itemsCount;
		}

		getCurrent() {
			return this.current;
		}

		getCurrentItem() {
			return this.items[ this.current ];
		}

		insert( el, index ) {
			this.$container.insertBefore( el, this.$container.childNodes[ index ] );
			this.items.splice( index, 0, el );
			this.initSetting();
		}

		remove( index ) {
			if ( this.items.length === 0 ) {
				return;
			}
			if ( this.current >= index ) {
				this.current--;
			}
			this.$container.removeChild( this.$container.childNodes[ index ] );
			this.items.splice( index, 1 );
			if ( this.current >= this.items.length ) {
				this.current = 0;
			}
			this.initSetting();

		}

		onResize() {

			this._setContainerHeight();

		}

		destroy() {

			$( window ).off( 'resize.lqdCarouselStack' );

			this.$prevBtn.off( 'click' );
			this.$nextBtn.off( 'click' );

		}

	}

	$.fn[ pluginName ] = function ( options ) {

		return this.each( function () {

			const pluginOptions = { ...$( this ).data( 'carousel-options' ), ...options };

			if ( !$.data( this, "plugin_" + pluginName ) ) {
				$.data( this, "plugin_" + pluginName, new Plugin( this, pluginOptions ) );
			}

		} );

	};

}( jQuery ) );

jQuery( document ).ready( function ( $ ) {

	if ( liquidWindowWidth() <= 768 ) return;

	$( '.lqd-carousel-stack' ).filter( ( i, el ) => !el.closest( '.lqd-tabs-pane:not(.active)' ) ).liquidCarouselStack();

} );