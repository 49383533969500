(function ( $ ) {
	
	'use strict';
	
	const pluginName = 'liquidPreloader';
	let defaults = {
		animationType: 'fade', // fade, slide, scale
		animationTargets: 'self',
		dir: 'x', // x, y
		stagger: 0,
		duration: 1400
	};
	
	class Plugin {

		constructor(element, options) {
			
			this._defaults = defaults;
			this._name = pluginName;

			this.options = $.extend({}, defaults, options);

			this.element = element;
			this.$element = $(element);
			this.animationTargets = this.getAnimationTargets();
			this.onPreloaderHiddenEvent = new CustomEvent('lqd-preloader-anim-done');

			this.onPageLoad();

		}

		getAnimationTargets() {

			const {animationTargets} = this.options;

			if ( animationTargets === 'self' ) {

				return this.element;

			} else {

				return document.querySelectorAll(animationTargets);

			}

		}
		
		getAnimationProperties() {
			
			const {animationType} = this.options;

			return this[`${animationType}Properties`]();

		}

		fadeProperties() {

			const animateIn = {
				opacity: [0, 1]
			};
			const animateOut = {
				opacity: [1, 0]
			}

			return {
				animateIn,
				animateOut
			}

		}

		slideProperties() {

			const { dir } = this.options;

			const animateIn = {
				[dir]: ['100%', '0%']
			};
			const animateOut = {
				[dir]: ['0%', '-100%']
			}

			return {
				animateIn,
				animateOut
			}

		}

		scaleProperties() {

			const animateIn = {
				[`scale${this.options.dir.toUpperCase()}`]: [0, 1]
			};
			const animateOut = {
				[`scale${this.options.dir.toUpperCase()}`]: [1, 0]
			}

			return {
				animateIn,
				animateOut
			}

		}

		onPageLoad() {

			$liquidBody.addClass('lqd-page-loaded lqd-preloader-animations-started');
			$liquidBody.removeClass('lqd-page-leaving lqd-page-not-loaded');
			this.hidePreloader();

		}
	
		hidePreloader() {

			const stagger = this.options.stagger / 1000;
			const duration = this.options.duration / 1000;

			const timeline = gsap.timeline({
				duration,
				ease: 'expo.out',
				stagger: stagger,
				onComplete: () => {

					this.$element.hide();
					$liquidBody.removeClass('lqd-preloader-animations-started');
					$liquidBody.addClass('lqd-preloader-animations-done');

					$(this.animationTargets).css('transform', '');

					document.dispatchEvent(this.onPreloaderHiddenEvent);

				}
			});

			$(this.animationTargets).each( (i, targetElement) => {

				const $targetElement = $(targetElement);

				if ( targetElement.hasAttribute('data-animations') ) {

					const animations = $targetElement.data('animations');
					
					timeline.to(targetElement, {
						...animations
					}, stagger * i)
					
				} else {

					const animationProperties = this.getAnimationProperties().animateOut;

					timeline.fromTo(targetElement, {
						[Object.keys(animationProperties)[0]]: Object.values(animationProperties)[0][0]
					},
					{
						[Object.keys(animationProperties)[0]]: Object.values(animationProperties)[0][1]
					}, stagger * i)

				}
				
			} );
	
		}
		
	}
	
	$.fn[ pluginName ] = function( options ) {
		
		return this.each( function() {
			
			const pluginOptions = {...$(this).data('preloader-options'), ...options};
			
			if ( !$.data( this, "plugin_" + pluginName ) ) {
				$.data( this, "plugin_" + pluginName, new Plugin( this, pluginOptions ) );
			}
			
		} );
		
	};
	
}(jQuery));

$liquidWindow.on('pageshow load', (ev) => {
	if ( 'elementorFrontend' in window && elementorFrontend.isEditMode()) return;
	liquidPageLoaded || jQuery('.lqd-preloader-wrap').liquidPreloader();
	liquidPageLoaded = true;
});