( function ( $ ) {

	'use strict';

	const pluginName = 'liquidTextRotator';
	let defaults = {
		delay: 2,
		duration: 0.8,
		easing: 'power4.inOut',
		/** @type {'slide' | 'basic' | 'list'} */
		animationType: 'slide',
		// only for 'list' animationType
		marquee: false
	};

	class Plugin {

		constructor( element, options ) {

			/** @type {HTMLElement} */
			this.element = element;
			this.$element = $( element );

			this.options = $.extend( {}, defaults, options );
			this._defaults = defaults;
			this._name = pluginName;

			this.activeKeywordIndex = 0;
			this.nextKeywordIndex = 1;
			this.isFirstItterate = true;

			this.basicAnimationTimeline = null;
			this.basicAnimationsResetTimeout = null;

			this.$keywordsContainer = null;
			this.keywordsContainer = null;
			this.$keywords = null;
			this.keywordsLength = 0;
			this.keywordsDimensions = [];
			this.slideInTimeout = null;
			this.slideOutTimeout = null;

			this.prevWindowWidth = window.innerWidth;

			this.build();

		}

		async init() {

			await this._measure();
			await this._onFontsLoad();

		}

		_measure() {

			return fastdomPromised.measure( () => {

				const styles = getComputedStyle( this.element );

				this.fontInfo.elementFontFamily = styles.fontFamily.replace( /"/g, '' ).replace( /'/g, '' ).split( ',' )[ 0 ];
				this.fontInfo.elementFontWeight = styles.fontWeight;
				this.fontInfo.elementFontStyle = styles.fontStyle;
				this.fontInfo.fontFamilySlug = window.liquidSlugify( this.fontInfo.elementFontFamily );

			} )

		}

		_onFontsLoad() {

			return fastdomPromised.measure( () => {

				if ( window.liquidCheckedFonts.find( ff => ff === this.fontInfo.fontFamilySlug ) ) {
					return this.build();
				}

				const font = new FontFaceObserver( this.fontInfo.elementFontFamily, {
					weight: this.fontInfo.elementFontWeight,
					style: this.fontInfo.elementFontStyle
				} );

				font.load().finally( () => {
					window.liquidCheckedFonts.push( this.fontInfo.fontFamilySlug );
					this.build();
				} );

			} );

		}

		build() {

			const promises = [];

			const $customAnimationParent = this.$element.closest( '[data-custom-animations]' )
			const $customAnimationChild = this.$element.children( '[data-custom-animations]' );
			const $splitTextChild = this.$element.children( '[data-split-text]' )

			if ( this.element.hasAttribute( 'data-split-text' ) ) {
				const data = this.$element.data( 'plugin_liquidSplitText' );
				data && promises.push( data.splitDonePormise );
			}
			if ( $splitTextChild.length ) {
				const data = $splitTextChild.data( 'plugin_liquidSplitText' );
				data && promises.push( data.splitDonePormise );
			}
			if ( $customAnimationParent.length ) {
				const data = $customAnimationParent.data( 'plugin_liquidCustomAnimations' );
				data && promises.push( data.animationsDonePromise );
			}
			if ( $customAnimationChild.length ) {
				const data = $customAnimationChild.data( 'plugin_liquidCustomAnimations' );
				data && promises.push( data.animationsDonePromise );
			}
			if ( this.element.hasAttribute( 'data-custom-animations' ) ) {
				const data = this.$element.data( 'plugin_liquidCustomAnimations' );
				data && promises.push( data.animationsDonePromise );
			}

			if ( promises.length ) {
				Promise.all( promises ).finally( () => {
					this.init();
				} )
			} else {
				this.init();
			}

		}

		async init() {

			this._handleWindowResize = liquidDebounce( this._handleWindowResize.bind( this ), 350 )

			this.$keywordsContainer = $( '.txt-rotate-keywords', this.element );

			if ( !this.$keywordsContainer.length ) {
				return console.warn( 'Could not find keywords container' );
			};

			/** @type {HTMLElement} */
			this.keywordsContainer = this.$keywordsContainer[ 0 ];
			this.keywordsInner = this.keywordsContainer.querySelector( '.txt-rotate-keywords-inner' );

			this.$keywords = $( '.txt-rotate-keyword', this.$keywordsContainer );

			this.$keywords.attr( 'class', 'txt-rotate-keyword' ).eq( 0 ).addClass( 'active' );

			this.keywordsLength = this.$keywords.length - 1;

			this.keywordsDimensions = await this.getKeywordsDimensions();

			this.setContainerWidth( 0 );
			this.initAnimations();
			this._windowResize();

			this.$element.addClass( 'text-rotator-activated' );

		}

		async getKeywordsDimensions() {

			const promises = [];

			this.$keywords.each( ( i, keyword ) => {

				const promise = new Promise( resolve => {

					new IntersectionObserver( ( [ entry ], observer ) => {
						observer.disconnect();
						const { boundingClientRect: { width, height } } = entry;
						resolve( {
							width,
							height
						} );
					} ).observe( keyword )

				} );

				promises.push( promise );

			} );

			const widths = await Promise.all( promises );

			return widths;

		}

		updateActiveIndex() {

			this.activeKeywordIndex = this.activeKeywordIndex + 1 > this.keywordsLength ? 0 : this.activeKeywordIndex + 1;

		}

		updateNextIndex() {

			this.nextKeywordIndex = this.nextKeywordIndex + 1 > this.keywordsLength ? 0 : this.nextKeywordIndex + 1;

		}

		setActiveClass() {

			this.$keywords.removeClass( 'active' );
			this.$keywords.eq( this.activeKeywordIndex ).addClass( 'active' );

		}

		setNextClass() {

			this.$keywords.removeClass( 'is-next' );
			this.$keywords.eq( this.nextKeywordIndex ).addClass( 'is-next' );

		}

		setContainerWidth( index ) {

			const keywordContainer = this.$keywordsContainer[ 0 ];

			if ( this.options.animationType === 'list' ) {
				return keywordContainer.style.width = `${ Math.max( ...this.keywordsDimensions.map( dim => parseInt( dim.width, 10 ) ) ) }px`;
			}

			keywordContainer.style.width = `${ this.keywordsDimensions[ index ].width }px`;

		}

		slideInNextKeyword() {

			const $nextKeyword = this.$keywords.eq( this.nextKeywordIndex );
			const delay = this.isFirstItterate ? this.options.delay / 2 : this.options.delay;

			this.slideInTimeout = setTimeout( () => {

				this.setContainerWidth( this.nextKeywordIndex );

				$nextKeyword.removeClass( 'lqd-keyword-slide-out' ).addClass( 'lqd-keyword-slide-in' );

				this.isFirstItterate = false;

				this.updateNextIndex();
				this.setNextClass();
				this.slideOutAciveKeyword();

				clearTimeout( this.slideInTimeout );

			}, delay * 1000 );

		}

		slideOutAciveKeyword() {

			const $activeKeyword = this.$keywords.eq( this.activeKeywordIndex );
			const delay = this.isFirstItterate ? this.options.delay / 2 : this.options.delay;

			$activeKeyword.removeClass( 'lqd-keyword-slide-in' ).addClass( 'lqd-keyword-slide-out' );

			this.updateActiveIndex();
			this.setActiveClass();

			this.slideOutTimeout = setTimeout( () => {

				this.slideInNextKeyword();

				clearTimeout( this.slideOutTimeout );

			}, delay * 1000 );

		}

		buildBaiscAnimation() {

			this.$element.addClass( 'txt-rotator-basic' );

			this.basicAnimationTimeline = gsap.timeline( {
				easing: 'power2.inOut',
				onStart: () => {
					this.isFirstItterate = false;
					if ( this.basicAnimationsResetTimeout ) {
						clearTimeout( this.basicAnimationsResetTimeout );
					}
					this.setContainerWidth( this.nextKeywordIndex );
				},
				onComplete: () => {

					this.updateActiveIndex();
					this.updateNextIndex();

					this.setActiveClass();
					this.setNextClass();

					this.basicAnimationsResetTimeout = setTimeout( () => (
						this.basicAnimationTimeline && this.basicAnimationTimeline.restart()
					), this.options.delay * 1000 );

				}
			} );

			this.$keywords.each( ( i, keyword ) => {

				this.basicAnimationTimeline.to( keyword, {
					duration: 0.125,
					opacity: 1,
					onStart: () => {
						const $keyword = $( keyword );
						this.$keywords.not( $keyword ).removeClass( 'active' );
						$keyword.addClass( 'active' );
					}
				} );

			} );

		}

		buildListAnimation() {

			const duration = 2;
			const visibleWords = parseInt( getComputedStyle( this.keywordsContainer ).getPropertyValue( '--visible-words' ), 10 );
			const totalHeight = this.keywordsDimensions.map( dim => dim.height ).reduce( ( prevVal, newVal ) => prevVal + newVal, 0 )
			const listHeight = this.keywordsDimensions.slice( 0, visibleWords ).map( dim => dim.height ).reduce( ( prevVal, newVal ) => prevVal + newVal, 0 );
			const totalKeywords = this.$keywords.length;
			const timer = gsap.delayedCall( this.options.delay, animateTo.bind( this ) );
			let currentKeyword = 1;
			let nextKeyword = currentKeyword + 1;
			let offset = 0;
			let wrapping = false;
			const mainTimeline = gsap.timeline( {
				defaults: {
					repeat: -1,
					duration,
					ease: 'none'
				},
				paused: true
			} );

			this.keywordsInnerClone = this.keywordsInner.cloneNode( true );
			this.keywordsInnerClone.classList.add( 'txt-rotate-keywords-inner-clone', 'lqd-overlay', 'flex-column' );
			this.keywordsContainer.append( this.keywordsInnerClone );

			this.keywordsContainer.style.height = `${ listHeight }px`;
			this.keywordsContainer.style.overflow = `hidden`;

			this.$keywords.add( $( this.keywordsInnerClone ).children() ).each( ( i, keyword ) => {

				i = i % totalKeywords;
				const keywordHeight = this.keywordsDimensions[ i ].height;
				const wrap = gsap.utils.wrap( keywordHeight * -1, totalHeight - keywordHeight );

				gsap.set( keyword, {
					position: 'absolute',
					y: offset,
				} );

				mainTimeline
					.to( keyword, {
						y: `-=${ totalHeight }`,
						modifiers: {
							y: gsap.utils.unitize( wrap )
						}
					}, 0 )
					.add( `keyword-${ i + 1 }`, gsap.utils.mapRange( 0, totalKeywords, 0, duration )( i ) );

				offset += keywordHeight;

			} );

			const slideKeywordsInner = () => {
				gsap.set( [ this.keywordsInner, this.keywordsInnerClone ], {
					'--current-keyword-height': `${ this.keywordsDimensions[ currentKeyword - 1 ].height / 2 * -1 }px`
				} )
			};

			slideKeywordsInner();

			const scrubTimeline = ( from, to ) => {
				if ( wrapping ) {
					return new gsap.timeline()
						.add( mainTimeline.tweenFromTo( from, duration, { duration: this.options.duration, ease: this.options.easing } ) )
						.add( mainTimeline.tweenFromTo( 0, to, { duration: this.options.duration, ease: this.options.easing, immediateRender: false } ) );
				}
				return mainTimeline.tweenFromTo( from, to, { duration: this.options.duration, ease: this.options.easing } );
			}

			function animateTo() {
				timer && timer.restart( true );
				currentKeyword === totalKeywords ? wrapping = true : wrapping = false;
				if ( !wrapping ) {
					scrubTimeline( `keyword-${ currentKeyword }`, `keyword-${ nextKeyword }` );
				} else {
					scrubTimeline( `keyword-${ totalKeywords }`, `keyword-${ 1 }` );
				}
				slideKeywordsInner();
				currentKeyword = currentKeyword >= totalKeywords ? 1 : currentKeyword + 1;
				nextKeyword = currentKeyword === totalKeywords ? 1 : currentKeyword + 1;
			};

			animateTo();

		}

		initAnimations() {

			const { animationType } = this.options;

			switch ( animationType ) {
				case 'basic':
					this.buildBaiscAnimation();
					break;
				case 'list':
					this.buildListAnimation();
					break;
				default:
					this.slideInNextKeyword();
			}

		}

		_windowResize() {

			$( window ).on( 'resize.lqdTextRotator', this._handleWindowResize.bind( this ) );

		}

		_handleWindowResize() {

			if ( this.prevWindowWidth === window.innerWidth ) return;

			gsap.killTweensOf( this.$keywordsContainer[ 0 ] );
			this.keywordsInner && gsap.killTweensOf( this.keywordsInner );
			this.$keywords.each( ( i, keyword ) => {
				gsap.killTweensOf( keyword );
			} );

			if ( this.keywordsInnerClone ) {
				gsap.killTweensOf( this.keywordsInnerClone );
				$( this.keywordsInnerClone ).children().each( ( i, keyword ) => {
					gsap.killTweensOf( keyword );
				} );
			}

			this.destroy();

			this._onWindowResize();

			this.prevWindowWidth = window.innerWidth;

		}

		_onWindowResize() {

			this.activeKeywordIndex = 0;
			this.nextKeywordIndex = 1;
			this.isFirstItterate = true;

			this.basicAnimationTimeline = null;
			this.basicAnimationsResetTimeout = null;

			this.slideInTimeout && clearTimeout( this.slideInTimeout );
			this.slideOutTimeout && clearTimeout( this.slideOutTimeout );

			this.build();

		}

		destroy() {

			$( window ).off( 'resize.lqdTextRotator' );

			this.keywordsInnerClone && this.keywordsInnerClone.remove();

		}

	}

	$.fn[ pluginName ] = function ( options ) {

		return this.each( function () {

			const pluginOptions = { ...$( this ).data( 'text-rotator-options' ), ...options };

			if ( !$.data( this, "plugin_" + pluginName ) ) {
				$.data( this, "plugin_" + pluginName, new Plugin( this, pluginOptions ) );
			}

		} );

	};

}( jQuery ) );

jQuery( document ).ready( function ( $ ) {

	$( '[data-text-rotator]' ).liquidTextRotator();

} );