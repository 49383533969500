( function ( $ ) {

	'use strict';

	const pluginName = 'liquidSlideshow';
	let defaults = {
		/**
		 * @type {'click' | 'mouseenter'}
		 */
		handler: 'click',
		menuItems: '.lqd-slsh-alt-menu a, .lqd-vslider-menu a',
		images: '.lqd-slsh-alt-images figure, .lqd-vslider-images figure',
		extras: '.lqd-slsh-alt-ext > ul > li, .lqd-vslider-ext > ul > li'
	};

	class Plugin {

		constructor( element, options ) {

			this._defaults = defaults;
			this._name = pluginName;

			this.options = { ...defaults, ...options };

			this.DOM = {};
			this.DOM.element = element;
			this.DOM.$element = $( element );
			this.DOM.$menuItems = $( this.options.menuItems, this.DOM.$element );
			this.DOM.$images = $( this.options.images, this.DOM.$element );
			this.DOM.$extras = $( this.options.extras, this.DOM.$element );
			this.DOM.$loader = $( '.lqd-vslider-loader', this.DOM.$element );

			this.isLoading = false;
			this.prevItem = 0;
			this.currentItem = 0;

			this.init();

		}

		init() {

			this.loadImage();
			this.events();

		}

		events() {

			this.DOM.$menuItems.each( ( i, item ) => {
				$( item ).on( this.options.handler, ev => {
					ev.preventDefault();
					ev.stopPropagation();

					if ( this.isLoading ) return;

					this.prevItem = this.currentItem;
					this.currentItem = i;

					if ( this.prevItem === this.currentItem ) return;
					this.loadImage();

				} );
			} );

		}

		loadImage() {

			const $currentFigure = this.DOM.$images.eq( this.currentItem );
			const $img = $( 'img', $currentFigure );

			if ( !$img.hasClass( 'loaded' ) ) {
				$img.attr( 'src', $img.attr( 'data-src' ) );
			}

			this.isLoading = true;

			this.DOM.$element.addClass( 'is-loading' );
			imagesLoaded( $img[ 0 ], this.onImageLoaded.bind( this ) );

		}

		onImageLoaded() {

			const $currentFigure = this.DOM.$images.eq( this.currentItem );
			const $currentImg = $( 'img', $currentFigure );
			const $currentExtras = this.DOM.$extras.eq( this.currentItem );
			const $menuLi = this.DOM.$menuItems.eq( this.currentItem ).parent();

			this.isLoading = false;

			this.DOM.$element.removeClass( 'is-loading' );
			$currentImg.addClass( 'loaded' );

			this.DOM.$images.removeClass( 'is-active' );
			this.DOM.$menuItems.parent().removeClass( 'is-active' );
			this.DOM.$extras.removeClass( 'is-active' );
			$currentFigure.addClass( 'is-active' );
			$currentExtras.addClass( 'is-active' );
			$menuLi.addClass( 'is-active' );

			this.animateElements();

		}

		animateElements() {

			const $prevExtras = this.DOM.$extras.eq( this.prevItem );
			const $currentExtras = this.DOM.$extras.eq( this.currentItem );
			const $prevExtrasChildElements = $prevExtras.children().not( 'style' );
			const $currentExtrasChildElements = $currentExtras.children().not( 'style' );

			gsap.fromTo( $prevExtras[ 0 ], {
				opacity: 1
			}, {
				opacity: 0,
				delay: 0.2
			} )
			gsap.fromTo( $currentExtras[ 0 ], {
				opacity: 0
			}, {
				opacity: 1,
				delay: 0.2
			} )

			gsap.fromTo( $prevExtrasChildElements.get(), {
				xPercent: 0,
				opacity: 1
			}, {
				xPercent: 3,
				opacity: 0,
				stagger: 0.1,
				delay: 0.3
			} )
			gsap.fromTo( $currentExtrasChildElements.get(), {
				xPercent: -3,
				opacity: 0
			}, {
				xPercent: 0,
				opacity: 1,
				stagger: 0.1,
				delay: 0.3
			} )

		}

		destroy() {

			this.DOM.$menuItems.each( ( i, item ) => { $( item ).off() } );

		}

	}

	$.fn[ pluginName ] = function ( options ) {

		return this.each( function () {

			const pluginOptions = { ...$( this ).data( 'slideshow-options' ), ...options };

			if ( !$.data( this, "plugin_" + pluginName ) ) {
				$.data( this, "plugin_" + pluginName, new Plugin( this, pluginOptions ) );
			}

		} );

	};

}( jQuery ) );

jQuery( document ).ready( function ( $ ) {
	$( '[data-lqd-slideshow]' ).liquidSlideshow();
} );