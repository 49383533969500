(function ( $ ) {
	
	'use strict';
	
	const pluginName = 'liquidTransitionDelay';
	let defaults = {
		elements: null,
		startDelay: 0,
		delayBetween: 250,
		random: false,
		reverse: false,
		delayType: 'transition', // ['transition', 'animation']
	};
	
	class Plugin {

		constructor(element, options) {
			this.element = element;
			this.$element = $(element);

			this.options = $.extend({}, defaults, options);

			this._defaults = defaults;
			this._name = pluginName;

			const splitTextEls = this.$element.find('[data-split-text]').get();
			const promises = [];

			if ( this.element.hasAttribute('data-split-text') ) {
				splitTextEls.push(this.element);
			}

			splitTextEls.forEach(el => {
				const elData = $(el).data('plugin_liquidSplitText');
				if ( elData ) {
					promises.push(elData.splitDonePormise);
				}
			});

			if ( promises.length ) {
				Promise.all(promises).then(this.init.bind(this));
			} else {
				this.init();
			}

		}
			
		init() {

			this.addDelays();
			
		}

		addDelays() {

			const { elements, delayBetween, startDelay, delayType, reverse } = this.options;

			if ( elements ) {

				const $elements = ! reverse ? $(elements, this.element) : $(elements, this.element).get().reverse();
				
				$.each($elements, ( i, element ) => {

					const delay = i * delayBetween + startDelay;

					$(element).css({
						[`-webkit-${delayType}-delay`]: `${(delay)}ms`,
						[`${delayType}-delay`]: `${(delay)}ms`
					});

				});

			}

		}
		
	}
	
	$.fn[ pluginName ] = function( options ) {
		
		return this.each( function() {
			
			const pluginOptions = $(this).data('delay-options') || options;
			
			if ( !$.data( this, "plugin_" + pluginName ) ) {
				$.data( this, "plugin_" + pluginName, new Plugin( this, pluginOptions ) );
			}
			
		} );
		
	};
	
}(jQuery));

jQuery(document).ready( function($) {
  $('[data-transition-delay=true]').liquidTransitionDelay();
  $('.lqd-submenu-cover .main-nav > .menu-item-has-children:not(.megamenu) > .nav-item-children, .navbar-visible-ontoggle > ul').liquidTransitionDelay({
		elements: '> li',
		delayBetween: 60
	});
});