( function ( $ ) {

	'use strict';

	const pluginName = 'liquidPin';
	let defaults = {
		trigger: 'self',
		start: 'top top',
		end: null,
		endTrigger: null,
		// [ 'contentsHeight', 'last-link', 'parent', number ] // 'last-link' used in custom css for sticky menu
		duration: 'contentsHeight',
		// it can be a number, or a css selector
		offset: 0,
		pinSpacing: false,
		pinReparent: false
	};

	class Plugin {

		constructor( element, options ) {

			this._defaults = defaults;
			this._name = pluginName;

			this.options = { ...defaults, ...options };

			this.element = element;
			this.$element = $( element );

			this.ST = null;

			this.spacerElement = null;
			this.offset = 0;
			this.end = 0;
			this.rect = {};

			this.pinPromise = new Promise( resolve => {
				this.element.addEventListener( 'element-was-pinned', resolve( this, this ) );
			} )

			if ( $liquidMainHeader.length && $liquidMainHeader[ 0 ].hasAttribute( 'data-sticky-header' ) ) {
				$( document ).on( 'lqd-header-sticky-change', () => {
					!this.ST && setTimeout( this.init.bind( this ), 150 );
				} );
			} else {
				this.init();
			}

		}

		async init() {

			this.rect = await this.measure();
			this.offset = await this.getOffset();
			this.end = await this.getEnd();

			this.pin();
			this.events();
			this.handleResize();

			this.element.dispatchEvent( new CustomEvent( 'element-was-pinned', { bubbles: false } ) );

		}

		measure() {

			return fastdomPromised.measure( () => {

				return this.element.getBoundingClientRect();

			} )

		}

		pin() {

			fastdom.mutate( () => {

				const { start, pinSpacing, pinReparent, trigger } = this.options;
				let breakpoint = '(min-width: 992px)';

				if ( this.element.classList.contains( 'lqd-custom-menu' ) ) {
					breakpoint = 'all';
				} else if ( this.element.classList.contains( 'lqd-add-to-cart-row' ) ) {
					breakpoint = '(max-width: 767px)'
				}

				ScrollTrigger.matchMedia( {
					[ `${ breakpoint }` ]: () => {
						this.ST = ScrollTrigger.create( {
							trigger: trigger === 'self' ? this.element : $( trigger )[ 0 ],
							pin: true,
							start: `${ start }+=${ this.offset }`,
							endTrigger: this.getEndTrigger(),
							end: this.end,
							pinSpacing,
							pinReparent
						} );

						this.spacerElement = this.ST.spacer;
					}
				} );

			} )

		}

		async getOffset() {

			const { offset } = this.options;

			if ( isNaN( parseInt( offset ), 10 ) ) {
				return await this.getOffsetElementsHeight();
			}

			return offset;

		}

		async getOffsetElementsHeight() {

			const { options } = this;
			const promises = [];
			let offset = 0;

			options.offset.split( ',' ).forEach( el => {

				const element = document.querySelector( el );

				if ( element ) {

					const promise = new Promise( resolve => {

						new IntersectionObserver( ( [ entry ], observer ) => {
							observer.disconnect();
							resolve( entry.boundingClientRect.height );
						} ).observe( element );

					} )

					promises.push( promise )

				}

			} );

			const heights = await Promise.all( promises );
			heights.forEach( height => offset += height );

			return offset;

		}

		getEnd() {

			return fastdomPromised.measure( () => {

				let { duration, end } = this.options;

				if ( end ) {
					return end;
				}

				if ( duration === 'contentsHeight' ) {

					if ( this.element.classList.contains( 'lqd-sticky-bg-wrap' ) || this.element.classList.contains( 'lqd-section-borders-wrap' ) ) {
						const $contentsContainer = this.spacerElement ? $( this.spacerElement ).siblings( '.ld-container' ) : this.$element.siblings( '.ld-container' );
						const contentsContainerHeight = $contentsContainer[ 0 ].offsetHeight;
						duration = `+=${ contentsContainerHeight }`;
					} else {
						duration = `+=${ this.rect.height }`;
					}

				}

				if ( duration === 'parent' ) {

					if ( this.element.classList.contains( 'vc_column-inner' ) ) {
						duration = `+=${ this.element.closest( '.ld-row' ).offsetHeight - this.rect.height }`
					} else {
						let contentsHeight = 0;
						this.$element.children().each( ( i, children ) => contentsHeight += $( children ).outerHeight( true ) );
						duration = `+=${ this.rect.height - contentsHeight }`;
					}

				}

				if ( duration === 'last-link' ) {
					duration = `bottom top+=${ this.offset + this.rect.height }`;
				}

				return duration;

			} )

		}

		getEndTrigger() {

			const { duration } = this.options;
			let { endTrigger } = this.options;

			if ( duration === 'parent' ) {
				endTrigger = this.spacerElement ? this.spacerElement.parentElement : this.element.parentElement;
			}

			if ( duration === 'last-link' ) {
				const $lastLink = $( 'a', this.element ).last();
				const lastLinkHref = $lastLink.attr( 'href' );
				if ( lastLinkHref !== '' && lastLinkHref.startsWith( '#' ) && $( lastLinkHref ).length ) {
					endTrigger = $( lastLinkHref )[ 0 ];
				} else {
					endTrigger = $liquidContents[ 0 ];
				}
			}

			return endTrigger;

		}

		events() {

			$( document ).on( 'lqd-header-sticky-change lqd-masonry-layout-complete lqd-carousel-initialized', () => {
				this.ST && this.ST.refresh();
			} );

		}

		handleResize() {

			const onResize = liquidDebounce( this.onWindowResize, 250 );

			$( window ).on( 'resize', onResize.bind( this ) );

		}

		async onWindowResize() {

			this.rect = await this.measure();

			// this.ST && this.ST.refresh();

		}

	}


	$.fn[ pluginName ] = function ( options ) {

		return this.each( function () {

			const pluginOptions = { ...$( this ).data( 'pin-options' ), ...options };

			if ( !$.data( this, "plugin_" + pluginName ) ) {
				$.data( this, "plugin_" + pluginName, new Plugin( this, pluginOptions ) );
			}

		} );

	};

}( jQuery ) );

jQuery( document ).ready( function ( $ ) {

	const pinElements = $( $( '[data-pin=true]' ).get().reverse() );

	pinElements.liquidPin();

} );