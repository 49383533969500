(function ( $ ) {
	
	'use strict';
	
	const pluginName = 'liquidFullscreenNav';
	let defaults = {
	};
	
	class Plugin {

		constructor(element, options) {
			
			this._defaults = defaults;
			this._name = pluginName;

			this.options = {...defaults, ...options};

			this.DOM = {};
			this.DOM.element = element;
			this.DOM.$element = $(element);

			this.init();
			
		}
			
		init() {
			
			this.DOM.$element.children('.header-modules-container').find('.lqd-head-col').removeClass('lqd-head-col');
			
		}
		
	}
	
	$.fn[ pluginName ] = function( options ) {
		
		return this.each( function() {
			
			const pluginOptions = {...$(this).data('fullscreen-nav-options'), ...options};
			
			if ( !$.data( this, "plugin_" + pluginName ) ) {
				$.data( this, "plugin_" + pluginName, new Plugin( this, pluginOptions ) );
			}
			
		} );
		
	};
	
}(jQuery));

jQuery(document).ready( function($) {
  $('.navbar-fullscreen').liquidFullscreenNav();
});