(function ( $ ) {
	
	'use strict';
	
	const pluginName = 'liquidShrinkBorders';
	let defaults = {
		start: 'top',
		end: 'bottom-=30%',
		scrub: 0.2
	};
	
	class Plugin {

		constructor(element, options) {
			
			this._defaults = defaults;
			this._name = pluginName;

			this.options = {...defaults, ...options};

			this.element = element;
			this.$element = $(element);

			this.$parentRow = this.$element.closest('.vc_row');
			this.$contents = this.$parentRow.children('.container').length ? this.$parentRow.children('.container') : this.$parentRow.children('.ld-container');
			this.contentsHeight = this.$contents.height();
			this.$animatables = this.$element.children();

			if ( this.$parentRow[0].hasAttribute('data-row-bg') ) {
				this.$parentRow.on('lqdrowbginit', this.init.bind(this));
			} else {
				this.init();
			}

		}
			
		init() {

			this._initScrollTrigger();

			this.$element.addClass('sticky-applied');
			
		}

		_initScrollTrigger() {

			const $stickyBg = this.$element.siblings('.row-bg-wrap');
			const {start, end, scrub} = this.options;
			const timeline = gsap.timeline();

			if ( $stickyBg.length ) {
				this.$animatables = this.$animatables.add($stickyBg);
			}

			$.each( this.$animatables, (i, border) => {

				const $border = $(border);
				const scaleAxis = $border.attr('data-axis');

				const animations = {
					startAt: {}
				};

				if ( scaleAxis === 'x' ) {
					animations.startAt.scaleX = 1;
					animations.scaleX = 0;
				} else if ( scaleAxis === 'y' ) {
					animations.startAt.scaleY = 1;
					animations.scaleY = 0;
				} else {
					animations.startAt.scale = 1.05;
					animations.scale = 1;
				}

				timeline.to(border, {
					...animations
				}, 0);

			} );

			ScrollTrigger.create({
				animation: timeline,
				trigger: this.element,
				start,
				end,
				scrub,
				toggleClass: {targets: this.$animatables.get(),	className: 'will-change'}
			});

		}
		
	}
	
	$.fn[ pluginName ] = function( options ) {
		
		return this.each( function() {
			
			const pluginOptions = {...$(this).data('plugin-options'), ...options};
			
			if ( !$.data( this, "plugin_" + pluginName ) ) {
				$.data( this, "plugin_" + pluginName, new Plugin( this, pluginOptions ) );
			}
			
		} );
		
	};
	
}(jQuery));

jQuery(document).ready( function($) {

	if ( liquidWindowWidth() <= liquidMobileNavBreakpoint() || liquidIsMobile() ) return false;

	$('[data-shrink-borders]').liquidShrinkBorders();
	
});