(function ( $ ) {
	
	'use strict';
	
	const pluginName = 'liquidResponsiveAccordion';
	let defaults = {
		triggers: ".lqd-tabs-nav a",
		contents: '.lqd-tabs-pane',
		parent: '.lqd-tabs'
	};
	
	class Plugin {

		constructor(element, options) {
			
			this._defaults = defaults;
			this._name = pluginName;

			this.options = {...defaults, ...options};

			this.DOM = {};
			this.DOM.$element = $(element);
			this.DOM.$triggers = this.DOM.$element.find(this.options.triggers);
			this.DOM.$contents = this.DOM.$element.find(this.options.contents);
			this.DOM.responsiveTriggers = [];

			this.init();
			
		}
			
		init() {
			
			this.createTriggers();
			this.appendTriggers();
			this.initCollapse();
			
		}

		createTriggers() {

			this.DOM.$triggers.each((i, trigger) => {
				const $trigger = $(trigger).clone();
				const $h2 = $('<h2 role="tab" class="lqd-res-acc-trigger" />');
				$h2.append($trigger);
				this.DOM.responsiveTriggers.push($h2);
			});

		}

		appendTriggers() {

			this.DOM.$contents.each((i, content) => {
				$(this.DOM.responsiveTriggers[i]).insertBefore(content);
			})

		}

		initCollapse() {

			$.each(this.DOM.responsiveTriggers, (i, trigger) => {
				const $trigger = $(trigger).children('a');
				const $parent = $trigger.closest(this.options.parent);
				const $contents = $parent.find(this.options.contents);
				$trigger.off('click');
				$trigger.on('click', event => {
					event.preventDefault();
					const $target = $($trigger.attr('href'));
					$trigger.parent().siblings('.lqd-res-acc-trigger').removeClass('is-active');
					$contents.not($target).removeClass('is-active').stop().slideUp(300);
					
					$target.toggleClass('is-active').stop().slideToggle(300);
					$trigger.parent().toggleClass('is-active');
				});
			});

		}
		
	}
	
	$.fn[ pluginName ] = function( options ) {
		
		return this.each( function() {
			
			const pluginOptions = {...$(this).data('res-acc-options'), ...options};
			
			if ( !$.data( this, "plugin_" + pluginName ) ) {
				$.data( this, "plugin_" + pluginName, new Plugin( this, pluginOptions ) );
			}
			
		} );
		
	};
	
}(jQuery));

jQuery(document).ready( function($) {
  $('.woocommerce-tabs').liquidResponsiveAccordion({
		triggers: '.wc-tabs > li > a',
		contents: '.woocommerce-Tabs-panel',
		parent: '.woocommerce-tabs'
	});
});