( function ( $ ) {

	'use strict';

	const contentsIsLiquidBg = $liquidContents.length && $liquidContents[ 0 ].getAttribute( 'data-liquid-bg-options' );
	const contentsInteractWithHeader = contentsIsLiquidBg && ( JSON.parse( contentsIsLiquidBg ).interactWithHeader === true || JSON.parse( contentsIsLiquidBg ).interactWithHeader === 'true' );

	const pluginName = 'liquidStickyHeader';
	let defaults = {
		stickyTrigger: 'this', // 'this', 'first-section'
		dynamicColors: false,
		disableOnMobile: false,
		smartSticky: false
	};

	class Plugin {

		constructor( element, options ) {

			this._defaults = defaults;
			this._name = pluginName;

			this.options = { ...defaults, ...options };

			this.DOM = {
				element: element,
				$element: $( element ),
				$stickySections: null,
				sentinel: null,
				placeholder: null,
				$stickyElements: null,
			};

			this.DOM.$stickySections = liquidIsElementor ? $( '> .elementor-section-wrap > .elementor-section, > .elementor-section, > .e-container, > .e-con', this.DOM.$element.children( '.elementor:not(.lqd-mobile-sec)' ) ).not( '.lqd-hide-onstuck, .lqd-stickybar-wrap' ) : $( '.lqd-head-sec-wrap', element ).not( '.lqd-hide-onstuck' );
			this.isInTitlebar = this.DOM.element.parentElement.classList.contains( 'titlebar' );
			this.isOverlay = this.DOM.element.classList.contains( 'main-header-overlay' );
			this.DOM.sentinel = null;
			this.DOM.placeholder = this.DOM.$element.prev( '.lqd-sticky-placeholder' )[ 0 ];
			this.DOM.$stickyElements = this.getStickyElements();
			this.firstRow = document.body.classList.contains( 'single-post' ) ? document.querySelector( '.lqd-post-cover' ) : $liquidSections.filter( ':visible' ).first()[ 0 ];
			this.firstRowIsSticky = this.firstRow ? getComputedStyle( this.firstRow ).position === 'sticky' : false;

			this.stickyElsDetails = null;

			this.stickySectionsHeight = 0;

			this.smartStickyStuff = {
				state: null,
				prevScrollY: 0,
				scrolledDistance: 0,
				tolerance: {
					up: 25,
					down: 3
				},
				toleranceExceeded: false
			}

			this.wasStuck = false;
			this.isStuck = false;

			this.init();

		}

		async init() {

			await this.addSentinel();
			!this.DOM.placeholder && await this.addPlaceholder();

			await this.getStickySectionsHeight();
			await this.getStickyElsDetails();

			this.DOM.element.setAttribute( 'data-sticky-values-measured', 'true' );

			this.sentinelIO();
			this.headerIO();
			this.addStickySectionsHeight();
			this.initDynamicColors();
			this.handleResizeEvents();
			this.eventListeners();

		}

		eventListeners() {

			document.addEventListener( 'lqd-header-sticky-change', e => {

				const isStuck = e.detail.stuck;

				this.updateStickyStates( isStuck );

				this.isStuck && this.addStickySectionsHeight();

			} );

			if ( this.options.smartSticky ) {

				$liquidWindow.on( 'scroll.lqdSmartStickyHeader', this.handleSmartSticky.bind( this ) );

			}

		}

		updateStickyStates( isStuck ) {

			fastdom.mutate( () => {

				this.wasStuck = this.isStuck;
				this.isStuck = isStuck;

				this.DOM.element.classList.toggle( 'is-stuck', this.isStuck );
				this.DOM.element.classList.toggle( 'is-not-stuck', !this.isStuck );

				if ( !this.isOverlay || this.isInTitlebar ) {
					this.DOM.placeholder.classList.toggle( 'd-none', !this.isStuck );
					this.DOM.placeholder.classList.toggle( 'hidden', !this.isStuck );
				};

				if ( this.options.smartSticky ) {

					if ( !this.isStuck ) {
						this.smartStickyStuff.state = null;
						this.DOM.element.classList.remove( 'lqd-smart-sticky-show', 'lqd-smart-sticky-hide', 'lqd-just-stuck' );
					} else {
						this.DOM.element.classList.add( 'lqd-smart-sticky-hide' );
						if ( !this.wasStuck ) {
							this.DOM.element.classList.add( 'lqd-just-stuck' );
						}
					}

				}

			} )

		}

		async getStickySectionsHeight() {

			const promises = [];

			this.DOM.$stickySections.each( ( i, el ) => {

				const promise = new Promise( resolve => {

					fastdom.measure( () => {

						new IntersectionObserver( ( [ entry ], observer ) => {
							observer.disconnect();
							resolve( entry.boundingClientRect );
						} ).observe( el );

					} )

				} );
				promises.push( promise );

			} )

			const rects = await Promise.all( promises );
			rects.forEach( rect => this.stickySectionsHeight += rect.height );

		}

		addStickySectionsHeight() {

			const applyCssTo = liquidIsElementor ? document.body : document.documentElement;

			fastdomPromised.mutate( () => {

				applyCssTo.style.setProperty( '--lqd-sticky-header-height', `${ this.stickySectionsHeight }px` );

			} )

		}

		addPlaceholder() {

			return fastdomPromised.mutate( () => {

				const placeholder = document.createElement( 'div' );
				placeholder.setAttribute( 'class', 'lqd-sticky-placeholder d-none' );

				this.DOM.placeholder = placeholder;

				this.DOM.element.before( placeholder );

			} )

		}

		addSentinel() {

			return fastdomPromised.mutate( () => {

				const sentinelTemplate = document.querySelector( '#lqd-temp-sticky-header-sentinel' );
				const sentinel = sentinelTemplate.content.firstElementChild.cloneNode( true );
				const { stickyTrigger } = this.options;
				let trigger = document.body;

				if ( stickyTrigger === 'first-section' ) {

					const titlebar = document.querySelector( '.titlebar' );

					if ( titlebar ) {
						trigger = titlebar;
					} else if ( this.firstRow && !this.firstRow.closest( '.main-footer' ) ) {
						if ( !this.firstRowIsSticky ) {
							trigger = this.firstRow;
						}
					} else {
						this.options.stickyTrigger = 'this';
					}

				}

				trigger.appendChild( sentinel );

				this.DOM.sentinel = sentinel;

			} )

		}

		sentinelIO() {

			new IntersectionObserver( ( [ entry ] ) => {

				fastdom.measure( () => {

					let targetInfo = entry.boundingClientRect;
					let rootBoundsInfo = entry.rootBounds;

					if ( !rootBoundsInfo ) {
						rootBoundsInfo = {
							top: 0,
							bottom: window.innerHeight
						};
					}

					if (
						!this.isStuck &&
						rootBoundsInfo &&
						targetInfo.bottom < rootBoundsInfo.top
					) {
						this.fireEvent( 'stickyChange', true );
					} else if (
						this.isStuck &&
						rootBoundsInfo &&
						targetInfo.bottom >= rootBoundsInfo.top &&
						targetInfo.bottom < rootBoundsInfo.bottom
					) {
						this.fireEvent( 'stickyChange', false );
					}

				} )

			} ).observe( this.DOM.sentinel );

		}

		headerIO() {

			const { stickyTrigger } = this.options;
			const applyCssTo = liquidIsElementor ? document.body : document.documentElement;

			fastdomPromised.measure( () => {

				const targetInfo = {
					height: this.DOM.element.offsetHeight
				};

				return targetInfo;

			} ).then( ( { height } ) => {

				fastdom.mutate( () => {

					applyCssTo.style.setProperty( '--lqd-sticky-header-placeholder-height', `${ height }px` );

					if ( stickyTrigger === 'this' ) {
						applyCssTo.style.setProperty( '--lqd-sticky-header-sentinel-top', `var(--lqd-sticky-header-placeholder-height)` );
					} else {
						if ( this.firstRowIsSticky ) {
							applyCssTo.style.setProperty( '--lqd-sticky-header-sentinel-top', `${ $( this.firstRow ).outerHeight() }px` );
						}
					}

				} )

			} )

		}

		stickyVisibilityChange( state ) {

			let classnamesToRemove = [ 'lqd-smart-sticky-hide', 'lqd-just-stuck' ];
			let classnamesToAdd = [ 'lqd-smart-sticky-show' ];

			if ( state === 'hide' ) {
				classnamesToRemove = [ 'lqd-smart-sticky-show' ];
				classnamesToAdd = [ 'lqd-smart-sticky-hide' ];
			}

			this.smartStickyStuff.state = state;
			this.DOM.element.classList.remove( ...classnamesToRemove );
			this.DOM.element.classList.add( ...classnamesToAdd );

			this.fireEvent( 'stickyVisibility', state )

		}

		handleSmartSticky() {

			fastdomPromised.measure( () => {

				const { scrollY } = window;
				const scrollDirection = scrollY > this.smartStickyStuff.prevScrollY ? 'down' : 'up';

				return {
					scrollY,
					scrollDirection
				}

			} ).then( ( { scrollY, scrollDirection } ) => {

				this.smartStickyStuff.scrolledDistance = Math.abs( scrollY - this.smartStickyStuff.prevScrollY );

				fastdom.mutate( () => {

					if ( this.isStuck && this.smartStickyStuff.toleranceExceeded ) {

						if ( scrollDirection === 'up' && this.smartStickyStuff.state !== 'show' ) {
							this.stickyVisibilityChange( 'show' );
						} else if ( scrollDirection === 'down' && this.smartStickyStuff.state !== 'hide' ) {
							this.stickyVisibilityChange( 'hide' );
						}

					}

					this.smartStickyStuff.prevScrollY = scrollY;
					this.smartStickyStuff.toleranceExceeded = this.smartStickyStuff.scrolledDistance > this.smartStickyStuff.tolerance[ scrollDirection ];

				} )

			} )

		}

		fireEvent( type = 'stickyChange', state ) {

			fastdom.mutate( () => {

				if ( type === 'stickyChange' ) {
					document.dispatchEvent(
						new CustomEvent( 'lqd-header-sticky-change', { bubbles: false, detail: { stuck: state, target: this.DOM.element } } )
					);
				}

				if ( type === 'stickyVisibility' ) {
					document.dispatchEvent(
						new CustomEvent( 'lqd-header-sticky-visibility-change', { bubbles: false, detail: { state, target: this.DOM.element } } )
					);
				}

			} )

		}

		getStickyElements() {

			const $stickyModules = liquidIsElementor ?
				this.DOM.$element
					.find( '> .elementor:not(.lqd-mobile-sec)' )
					.find( '[data-element_type="widget"]' )
					.filter( ( i, el ) => {
						return (
							!el.classList.contains( 'elementor-widget-ld_modal_window' ) &&
							!el.closest( '.ld-module-sd' ) &&
							!el.closest( '.navbar-fullscreen' ) &&
							!el.closest( '.lqd-modal' )
						)
					} ) :
				this.DOM.$element.find( '.lqd-head-sec-wrap, .lqd-stickybar-wrap' ).find( '.lqd-head-col > .header-module, [data-lqd-interactive-color=true]' );

			const $elements =
				this.DOM.element.hasAttribute( 'data-liquid-bg' ) ?
					$stickyModules.add( this.DOM.$element ).not( '.navbar-brand-solid' ) :
					$stickyModules.add( this.DOM.$element );

			return $elements;

		}

		async getStickyElsDetails() {

			const promises = [];
			const windowWidth = liquidWindowWidth();

			this.DOM.$stickyElements.each( ( i, stickyEl ) => {

				const promise = new Promise( resolve => {

					new IntersectionObserver( ( [ entry ], observer ) => {

						fastdom.measure( () => {

							observer.disconnect();

							let { boundingClientRect } = entry;
							let obj = {};

							obj.el = entry.target;
							obj.moduleEl = stickyEl;

							let { x, y, width, height } = boundingClientRect;

							if ( x < 0 ) {
								x = 0;
							} else if ( x >= windowWidth ) {
								x = windowWidth - width - entry.target.parentElement.offsetWidth;
							}

							if ( y < 0 ) {
								y = y + window.scrollY;
							}

							obj.rect = {
								width,
								height,
								x,
								y
							};

							obj.currentColor = 'default';

							resolve( obj );

						} )

					} ).observe( stickyEl === this.DOM.element ? this.DOM.element : stickyEl );

				} );

				promises.push( promise );

			} );

			const stickyElDetailsArray = await Promise.all( promises );

			this.stickyElsDetails = stickyElDetailsArray;

		}

		getSections( visibleSections ) {

			let sections = [];

			visibleSections.forEach( sec => {

				let section = sec;

				if ( sec.isInnerSection ) {

					section = sec.parentSection;

					if ( !section ) return;

					const sectionBgcolor = sec.backgroundColor.replace( /, /g, ',' ).split( ' ' )[ 0 ];
					const parentBgColor = section.backgroundColor.replace( /, /g, ',' ).split( ' ' )[ 0 ];
					const sectionBgAlpha = tinycolor( sectionBgcolor ).getAlpha();
					const parentBgAlpha = tinycolor( parentBgColor ).getAlpha();

					if ( ( sectionBgAlpha !== 0 || sec.predefinedLuminosity ) && parentBgAlpha === 0 ) {

						section = sec;

						sections = sections.filter( sect => sect.el !== section.parentSection.el );

					}

				}

				const sectionExists = sections.some( sect => sect.el === section.el );

				!sectionExists && sections.push( section );

			} )

			return sections;

		}

		initDynamicColors() {

			if ( !this.options.dynamicColors || contentsInteractWithHeader ) return;

			LiquidSectionsDetails.getDetails().then( lqdSections => {

				const visibleSections = lqdSections.filter( sec => !sec.isHidden );
				const sections = this.getSections( visibleSections );
				const onscroll = liquidThrottle( this.onScroll.bind( this, sections ), 150, { leading: true } );

				this.onScroll( sections );

				$liquidWindow.off( 'scroll.lqdStickyHeader' );
				$liquidWindow.on( 'scroll.lqdStickyHeader', onscroll );

			} );

		}

		onScroll( sections ) {

			for ( let sectionsItterator = 0; sectionsItterator < sections.length; sectionsItterator++ ) {

				fastdomPromised.measure( () => {

					const sec = sections[ sectionsItterator ];
					const rect = { ...sec.rect };

					rect.y = rect.initialOffset.y - window.scrollY;
					rect.x = rect.initialOffset.x - window.scrollX;

					for ( let stickyItterator = 0; stickyItterator < this.stickyElsDetails.length; stickyItterator++ ) {

						if ( this.isCollide( this.stickyElsDetails[ stickyItterator ].rect, rect ) ) {
							this.changeAttrs( sec, this.stickyElsDetails[ stickyItterator ] )
						}

					}

				} )

			}

		}

		changeAttrs( rowObj, moduleObj ) {

			const { luminosity } = rowObj;
			const { moduleEl } = moduleObj;

			fastdom.mutate( () => {

				if ( luminosity === 'light' && moduleObj.currentColor !== 'dark' ) {
					moduleObj.currentColor = 'dark';
					moduleEl.classList.add( 'lqd-active-row-light' );
					moduleEl.classList.remove( 'lqd-active-row-dark' );
				} else if ( luminosity === 'dark' && moduleObj.currentColor !== 'light' ) {
					moduleObj.currentColor = 'light';
					moduleEl.classList.add( 'lqd-active-row-dark' );
					moduleEl.classList.remove( 'lqd-active-row-light' );
				}

			} )

		}

		// https://stackoverflow.com/questions/2440377/javascript-collision-detection
		isCollide( a, b ) {
			return !(
				( ( a.y + a.height ) < ( b.y ) ) ||
				( a.y > ( b.y + b.height ) ) ||
				( ( a.x + a.width ) < b.x ) ||
				( ( a.x + ( a.width / 2 ) ) > ( b.x + b.width ) )
			);
		}

		handleResizeEvents() {

			$liquidWindow.on( 'resize', this.onResize.bind( this ) );
			$( document ).on( 'lqd-masonry-layout-init', this.onResize.bind( this ) );
			// $(document).on('lqd-carousel-initialized', this.onResize.bind(this) );

		}

		onResize() {

			this.headerIO();
			this.initDynamicColors();

		}

		drawIndicators( sec ) {

			const $indicator = $( `<div class="lqd-section-ind pos-abs pointer-events-none absolute" style="width: ${ sec.rect.width }px; height: ${ sec.rect.height }px; border: 3px solid red; top: ${ sec.rect.y }px; left: ${ sec.rect.x }px; z-index: 10;"><span style="display: inline-block; background: var(--color-primary); color: #fff; padding: 0.35em 1em;">${ sec.luminosity }</span></div>` );

			$indicator.appendTo( $liquidBody );

		}

	}

	$.fn[ pluginName ] = function ( options ) {

		return this.each( function () {

			const pluginOptions = { ...$( this ).data( 'sticky-options' ), ...options };

			if ( pluginOptions.disableOnMobile && liquidIsMobile() ) return;

			if ( !$.data( this, "plugin_" + pluginName ) ) {
				$.data( this, "plugin_" + pluginName, new Plugin( this, pluginOptions ) );
			}

		} );

	};

}( jQuery ) );

jQuery( document ).ready( function ( $ ) {

	// controlling from stacks js
	const $stickyHeader = $( '[data-sticky-header]' );

	if ( $liquidContents.length ) {

		const pageStackEnabled = $liquidContents[ 0 ].hasAttribute( 'data-liquid-stack' );
		const pageStackDisabledOnMobile =
			pageStackEnabled &&
			$liquidContents[ 0 ].hasAttribute( 'data-stack-options' ) &&
			JSON.parse( $liquidContents[ 0 ].getAttribute( 'data-stack-options' ) ).disableOnMobile === true;

		if (
			!$liquidBody.hasClass( 'header-style-side' ) &&
			( !pageStackEnabled || ( pageStackEnabled && liquidIsMobile() && pageStackDisabledOnMobile ) )
		) {
			$stickyHeader.liquidStickyHeader();
		} else if ( pageStackEnabled ) {
			$stickyHeader.attr( 'data-sticky-values-measured', 'true' );
		}

	}

} );