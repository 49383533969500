( function ( $ ) {

	'use strict';

	const pluginName = 'liquidSplitText';
	let defaults = {
		type: "words", // "words", "chars", "lines". or mixed e.g. "words, chars"
		forceApply: false
	};

	class Plugin {

		constructor( element, options ) {

			this._defaults = defaults;
			this._name = pluginName;

			this.options = { ...defaults, ...options };

			this.splittedTextList = {
				lines: [],
				words: [],
				chars: []
			};
			this.splitTextInstance = null;
			this.isRTL = $( 'html' ).attr( 'dir' ) === 'rtl';

			this.element = element;
			this.$element = $( element );

			this.prevWindowWidth = window.innerWidth;

			this.fontInfo = {};

			this.splitDonePormise = new Promise( resolve => {
				this.$element.on( 'lqdsplittext', resolve.bind( this, this ) );
			} );

			if ( !this.options.forceApply ) {
				new IntersectionObserver( ( [ entry ], observer ) => {
					if ( entry.isIntersecting ) {
						observer.disconnect();
						this.init();
					}
				}, { rootMargin: '20%' } ).observe( this.element );
			} else {
				this.init();
			}

		}

		async init() {

			await this._measure();
			await this._onFontsLoad();
			this._windowResize();

		}

		_measure() {

			return fastdomPromised.measure( () => {

				const styles = getComputedStyle( this.element );

				this.fontInfo.elementFontFamily = styles.fontFamily.replace( /"/g, '' ).replace( /'/g, '' ).split( ',' )[ 0 ];
				this.fontInfo.elementFontWeight = styles.fontWeight;
				this.fontInfo.elementFontStyle = styles.fontStyle;
				this.fontInfo.fontFamilySlug = window.liquidSlugify( this.fontInfo.elementFontFamily );

			} )

		}

		_onFontsLoad() {

			return fastdomPromised.measure( () => {

				if ( window.liquidCheckedFonts.find( ff => ff === this.fontInfo.fontFamilySlug ) ) {
					return this._doSplit();
				}

				const font = new FontFaceObserver( this.fontInfo.elementFontFamily, {
					weight: this.fontInfo.elementFontWeight,
					style: this.fontInfo.elementFontStyle
				} );

				return font.load().finally( () => {
					window.liquidCheckedFonts.push( this.fontInfo.fontFamilySlug );
					this._doSplit();
				} );

			} );

		}

		getSplitTypeArray() {

			const { type } = this.options;
			const splitTypeArray = type.split( ',' ).map( item => item.replace( ' ', '' ) );

			if ( !this.isRTL ) {
				return splitTypeArray;
			} else {
				return splitTypeArray.filter( type => type !== 'chars' );
			}

		}

		async _doSplit() {

			await this._split();
			await this._unitsOp();
			await this._onSplittingDone();

		}

		_split() {

			const splitType = this.getSplitTypeArray();
			const fancyHeadingInner = this.element.classList.contains( 'ld-fh-txt' ) && this.element.querySelector( '.ld-fh-txt-inner' ) != null;
			const el = fancyHeadingInner ? this.element.querySelector( '.ld-fh-txt-inner' ) : this.element;
			let splittedText;

			return fastdomPromised.mutate( () => {

				splittedText = new SplitText( el, {
					type: splitType,
					charsClass: 'split-unit lqd-chars',
					linesClass: 'split-unit lqd-lines',
					wordsClass: 'split-unit lqd-words'
				} );

				splitType.forEach( type => {

					splittedText[ type ].forEach( element => {
						this.splittedTextList[ type ].push( element );
					} )

				} )

				this.element.classList.add( 'split-text-applied' );

				this.splitTextInstance = splittedText;

			} )

		}

		_unitsOp() {

			return fastdomPromised.mutate( () => {

				for ( const [ splitType, splittedTextArray ] of Object.entries( this.splittedTextList ) ) {

					if ( splittedTextArray && splittedTextArray.length > 0 ) {

						splittedTextArray.forEach( ( splitElement, i ) => {
							splitElement.style.setProperty( `--${ splitType }-index`, i );
							splitElement.style.setProperty( `--${ splitType }-last-index`, splittedTextArray.length - 1 - i );
							$( splitElement ).wrapInner( `<span class="split-inner" />` );
						} )

					};

				};

			} )

		}

		_onSplittingDone() {

			return fastdomPromised.mutate( () => {
				this.element.dispatchEvent( new CustomEvent( 'lqdsplittext' ) );
			} )

		}

		_windowResize() {

			$( window ).on( 'resize.lqdSplitText', this._onWindowResize.bind( this ) );

		}

		_onWindowResize() {

			if ( this.prevWindowWidth === window.innerWidth ) return;

			if ( this.splitTextInstance ) {

				// fastdom.mutate(() => {
				this.splitTextInstance.revert();
				this.element.classList.remove( 'split-text-applied' );
				// })

			}

			this._onAfterWindowResize();

			this.prevWindowWidth = window.innerWidth;

		}

		_onAfterWindowResize() {

			this._doSplit();
			this._onSplittingDone();
			this.$element.find( '.split-unit' ).addClass( 'lqd-unit-animation-done' );

		}

		destroy() {

			$( window ).off( 'resize.lqdSplitText' );

		}

	}

	$.fn[ pluginName ] = function ( options ) {

		return this.each( function () {

			const pluginOptions = { ...$( this ).data( 'split-options' ), ...options };

			if ( !$.data( this, "plugin_" + pluginName ) ) {
				$.data( this, "plugin_" + pluginName, new Plugin( this, pluginOptions ) );
			}

		} );

	};

}( jQuery ) );

jQuery( document ).ready( function ( $ ) {

	const $elements = $( '[data-split-text]' ).filter( ( i, el ) => {
		const $el = $( el );
		const isCustomAnimation = el.hasAttribute( 'data-custom-animations' );
		const hasCustomAnimationParent = $el.closest( '[data-custom-animations]' ).length;
		const hasAccordionParent = $el.closest( '.accordion-content' ).length;
		const hasTabParent = $el.closest( '.lqd-tabs-pane' ).length;
		const webglSlideshowParent = $el.closest( '[data-lqd-webgl-slideshow]' ).length;

		return (
			!isCustomAnimation &&
			!hasCustomAnimationParent &&
			!hasAccordionParent &&
			!hasTabParent &&
			!webglSlideshowParent
		)
	} );

	$elements.liquidSplitText();

} );