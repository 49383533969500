( function ( $ ) {

	'use strict';

	const pluginName = 'liquidSubmenu';

	let defaults = {
		toggleType: "fade", // fade, slide
		handler: "mouse-in-out", // click, mouse-in-out
		animationSpeed: 200
	};

	class Plugin {

		constructor( element, options ) {
			this.element = element;
			this.$element = $( element );

			this.options = { ...defaults, ...options };

			this._defaults = defaults;
			this._name = pluginName;

			this.rects = [];
			this.windowWidth = fastdom.measure( liquidWindowWidth )();
			this.itemsAreFullwidth = this.element.classList.contains( 'lqd-menu-items-block' );
			this.$submenuParents = $( this.$element.find( '.menu-item-has-children, .page_item_has_children' ).get().reverse() );
			this.$elementorHeaderWidgets = liquidIsElementor && $liquidMainHeader.find( '> .elementor:not(.lqd-mobile-sec) > .elementor-section-wrap > .elementor-section, > .elementor:not(.lqd-mobile-sec) > .elementor-section, > .elementor:not(.lqd-mobile-sec) > .e-con' ).not( '.lqd-stickybar-wrap' ).find( '> .elementor-container > .elementor-column > .elementor-widget-wrap > [data-element_type="widget"]' );

			this.init();
		}

		init() {
			const { handler } = this.options;

			this.$submenuParents.each( async ( i, subParent ) => {
				const isMegamenu = subParent.classList.contains( 'megamenu' );
				const submenu = subParent.querySelector( '.nav-item-children, .children' );

				if ( !submenu ) return;

				if ( handler === 'click' ) {
					this.element.classList.add( 'lqd-submenu-toggle-click' );
				} else if ( handler === 'mouse-in-out' ) {
					this.element.classList.add( 'lqd-submenu-toggle-hover' );
				}

				if ( ( !liquidIsMobile() || !$( subParent ).is( ':hidden' ) ) && !this.itemsAreFullwidth ) {
					if ( !isMegamenu ) {
						await this.measure( i, submenu, subParent );
					} else {
						this.getMegamenuBackgroundLuminance( subParent );
					}
				} else {
					subParent.classList.add( 'position-applied' )
				}

				this.eventHandlers( i, submenu, subParent );

			} );

			$( document ).on( 'click', this.closeActiveSubmenu.bind( this ) );

			$( document ).keyup( event => {
				if ( event.keyCode == 27 ) {
					this.closeActiveSubmenu( event );
				}
			} );
		}

		async measure( i, submenu, subParent ) {
			await this.getRects( i, submenu, subParent );
			await this.positioning( i, submenu, subParent );
		}

		eventHandlers( i, submenu, submenuParent ) {
			const { handler } = this.options;
			const $toggleLink = $( submenuParent ).children( 'a' );

			if ( handler === 'click' ) {
				$toggleLink.off();
				$toggleLink.on( 'click', this.handleToggle.bind( this, 'toggle' ) );
			} else {
				$( submenuParent ).off();

				$( submenuParent ).on( 'mouseenter', this.handleToggle.bind( this, 'show' ) );
				$( submenuParent ).on( 'mouseleave', this.handleToggle.bind( this, 'hide' ) );
			}

			document.addEventListener( 'lqd-header-sticky-change', async () => {
				await this.measure( i, submenu, submenuParent );
			} );

			return this;
		}

		handleToggle( state, event ) {
			const { toggleType, handler } = this.options;
			const $link = $( event.currentTarget );
			const $submenuParent = $link.closest( 'li' );
			const $submenu = $submenuParent.children( '.nav-item-children, .children' );
			const isMegamenu = $submenuParent.hasClass( 'megamenu' );
			const megamenuBg = isMegamenu && $submenuParent.attr( 'data-bg-color' );
			const megamenuScheme = isMegamenu && $submenuParent.attr( 'data-megamenu-bg-scheme' );

			if ( !$submenu.length ) return;

			event.preventDefault();

			const stateIsShow = state === 'show' || !$submenuParent.hasClass( 'is-active' );

			if ( toggleType === 'fade' ) {
				if ( state === 'show' ) {
					this.fadeIn( $submenu, isMegamenu, megamenuScheme, megamenuBg );
				} else if ( state === 'hide' ) {
					this.fadeOut( $submenu, isMegamenu );
				} else if ( state === 'toggle' ) {
					this.fadeToggle( $submenu, isMegamenu, megamenuScheme, megamenuBg );
				}
			}

			if ( toggleType === 'slide' ) {
				if ( state === 'show' ) {
					this.slideDown( $submenu, isMegamenu, megamenuScheme, megamenuBg );
				} else if ( state === 'hide' ) {
					this.slideUp( $submenu, isMegamenu );
				} else if ( state === 'toggle' ) {
					this.slideToggle( $submenu, isMegamenu, megamenuScheme, megamenuBg );
				}
			}

			$submenuParent
				.toggleClass( `is-active ${ handler === 'mouse-in-out' ? 'is-hovered' : '' }`, stateIsShow )
				.siblings()
				.removeClass( `is-active ${ handler === 'mouse-in-out' ? 'is-hovered' : '' }` );

			document.dispatchEvent(
				new CustomEvent( 'lqd-submenu-change', { bubbles: false, detail: { state: stateIsShow ? 'show' : 'hide', $submenu } } )
			);
		}

		fadeToggle( submenu, isMegamenu, megamenuScheme, megamenuBg ) {
			const { handler } = this.options;
			const submenuParent = submenu.closest( 'li' );

			if ( handler === 'click' ) {
				submenu.css( {
					'visibility': 'visible',
					'opacity': submenuParent.hasClass( 'is-active' ) ? 0 : 1,
				} );
				submenu[ submenuParent.hasClass( 'is-active' ) ? 'show' : 'hide' ]();
				submenuParent.siblings().find( '.nav-item-children, .children' ).stop().fadeOut( this.options.animationSpeed );
				submenu.stop().fadeToggle( this.options.animationSpeed );
			}

			$liquidMainHeader.removeClass( 'megamenu-scheme-dark megamenu-scheme-light megamenu-scheme-transparent' );
			$liquidMainHeader.toggleClass( `megamenu-item-active megamenu-scheme-${ megamenuScheme }`, isMegamenu );

			if ( this.$elementorHeaderWidgets && megamenuScheme !== 'transparent' && !$liquidMainHeader.hasClass( 'is-stuck' ) ) {
				this.$elementorHeaderWidgets.removeClass( `lqd-active-row-dark lqd-active-row-light` );
				this.$elementorHeaderWidgets.addClass( `lqd-active-row-${ megamenuScheme }` );
			}

			if ( isMegamenu ) {
				$liquidMainHeader[ 0 ].style.setProperty( '--lqd-megamenu-background-color', megamenuBg );
			}
		}

		fadeIn( submenu, isMegamenu, megamenuScheme, megamenuBg ) {
			const { handler } = this.options;
			const submenuParent = submenu.closest( 'li' );

			if ( handler === 'click' ) {
				submenu.css( {
					'visibility': 'visible',
					'display': 'none',
					'opacity': 0,
				} );
				submenuParent.siblings().find( '.nav-item-children, .children' ).stop().fadeOut( this.options.animationSpeed );
				submenu.stop().fadeIn( this.options.animationSpeed );
			}

			if ( isMegamenu ) {
				if ( this.$elementorHeaderWidgets && megamenuScheme !== 'transparent' && !$liquidMainHeader.hasClass( 'is-stuck' ) ) {
					this.$elementorHeaderWidgets.removeClass( `lqd-active-row-dark lqd-active-row-light` );
					this.$elementorHeaderWidgets.addClass( `lqd-active-row-${ megamenuScheme }` );
				}
				$liquidMainHeader[ 0 ].style.setProperty( '--lqd-megamenu-background-color', megamenuBg );
				$liquidMainHeader.removeClass( 'megamenu-scheme-dark megamenu-scheme-light megamenu-scheme-transparent' );
				$liquidMainHeader.addClass( `megamenu-item-active megamenu-scheme-${ megamenuScheme }` );
			}

			if ( submenu.find( '[data-lqd-flickity]' ).length ) {
				submenu.find( '[data-lqd-flickity]' ).flickity( 'resize' );
			}
		}

		fadeOut( submenu, isMegamenu ) {
			const { handler } = this.options;
			const submenuParent = submenu.closest( 'li' );

			if ( handler === 'click' ) {
				submenu.css( {
					'visibility': 'visible',
					'display': 'block',
					'opacity': submenuParent.hasClass( 'is-active' ) ? 1 : 0,
				} );
				submenuParent.siblings().find( '.nav-item-children, .children' ).stop().fadeOut( this.options.animationSpeed );
				submenu.stop().fadeOut( this.options.animationSpeed );
			}

			if ( isMegamenu ) {
				if ( this.$elementorHeaderWidgets ) {
					this.$elementorHeaderWidgets.removeClass( `lqd-active-row-dark lqd-active-row-light` );
				}
				$liquidMainHeader.removeClass( 'megamenu-scheme-dark megamenu-scheme-light megamenu-scheme-transparent' );
				$liquidMainHeader.removeClass( 'megamenu-item-active' );
			}
		}

		slideToggle( submenu, isMegamenu, megamenuScheme, megamenuBg ) {
			submenu.closest( 'li' ).siblings().find( '.nav-item-children, .children' ).stop().slideUp( this.options.animationSpeed );
			submenu.stop().slideToggle( this.options.animationSpeed );

			if ( isMegamenu ) {
				if ( this.$elementorHeaderWidgets && megamenuScheme !== 'transparent' && !$liquidMainHeader.hasClass( 'is-stuck' ) ) {
					this.$elementorHeaderWidgets.removeClass( `lqd-active-row-dark lqd-active-row-light` );
					this.$elementorHeaderWidgets.addClass( `lqd-active-row-${ megamenuScheme }` );
				}
				$liquidMainHeader[ 0 ].style.setProperty( '--lqd-megamenu-background-color', megamenuBg );
				$liquidMainHeader.removeClass( 'megamenu-scheme-dark megamenu-scheme-light megamenu-scheme-transparent' );
				$liquidMainHeader.toggleClass( `megamenu-item-active megamenu-scheme-${ megamenuScheme }` );
			}
		}

		slideDown( submenu, isMegamenu, megamenuScheme, megamenuBg ) {
			submenu.closest( 'li' ).siblings().find( '.nav-item-children, .children' ).stop().slideUp( this.options.animationSpeed );
			submenu.stop().slideDown( this.options.animationSpeed );

			if ( isMegamenu ) {
				if ( this.$elementorHeaderWidgets && megamenuScheme !== 'transparent' && !$liquidMainHeader.hasClass( 'is-stuck' ) ) {
					this.$elementorHeaderWidgets.removeClass( `lqd-active-row-dark lqd-active-row-light` );
					this.$elementorHeaderWidgets.addClass( `lqd-active-row-${ megamenuScheme }` );
				}
				$liquidMainHeader[ 0 ].style.setProperty( '--lqd-megamenu-background-color', megamenuBg );
				$liquidMainHeader.removeClass( 'megamenu-scheme-dark megamenu-scheme-light megamenu-scheme-transparent' );
				$liquidMainHeader.addClass( `megamenu-item-active megamenu-scheme-${ megamenuScheme }` );
			}
		}

		slideUp( submenu, isMegamenu ) {
			submenu.stop().slideUp( this.options.animationSpeed );

			if ( isMegamenu ) {
				if ( this.$elementorHeaderWidgets ) {
					this.$elementorHeaderWidgets.removeClass( `lqd-active-row-dark lqd-active-row-light` );
				}
				$liquidMainHeader.removeClass( 'megamenu-scheme-dark megamenu-scheme-light megamenu-scheme-transparent' );
				$liquidMainHeader.removeClass( 'megamenu-item-active' );
			}
		}

		getMegamenuBackgroundLuminance( subParent ) {
			const megamenuRowsWrap = subParent.querySelector( '.lqd-megamenu-rows-wrap' );
			let backgroundColor;

			fastdom.measure( () => {
				const styles = getComputedStyle( megamenuRowsWrap );
				backgroundColor = tinycolor( styles.backgroundColor );
			} );

			fastdom.mutate( () => {

				subParent.setAttribute( 'data-bg-color', backgroundColor );

				if ( backgroundColor.getAlpha() === 0 ) {
					return subParent.setAttribute( 'data-megamenu-bg-scheme', 'transparent' )
				}
				if ( backgroundColor.isLight() ) {
					return subParent.setAttribute( 'data-megamenu-bg-scheme', 'light' )
				}
				if ( backgroundColor.isDark() ) {
					return subParent.setAttribute( 'data-megamenu-bg-scheme', 'dark' )
				}

			} );
		}

		closeActiveSubmenu( event ) {
			const { toggleType } = this.options;

			// if Esc key pressed
			if ( event.keyCode ) {

				this.$submenuParents.each( ( i, submenuParent ) => {
					const $submenuParent = $( submenuParent );

					$submenuParent.removeClass( 'active, is-active' );
					this[ toggleType === 'fade' ? 'fadeOut' : 'slideUp' ]( $submenuParent.children( '.nav-item-children, .children' ), $submenuParent.hasClass( 'megamenu' ) );
				} );

				document.dispatchEvent(
					new CustomEvent( 'lqd-submenu-change', { bubbles: false, detail: { state: 'hide', $submenu: null } } )
				);

			} else { // else if it was clicked in the document

				this.$submenuParents.each( async ( i, submenuParent ) => {
					const $submenuParent = $( submenuParent );
					const $link = $submenuParent.children( 'a' );

					if ( !$submenuParent.hasClass( 'is-active' ) ) return;

					if (
						!$link.is( event.target ) &&
						!$submenuParent.is( event.target ) &&
						!$submenuParent.has( event.target ).length
					) {
						$submenuParent.removeClass( 'active, is-active' );
						this[ toggleType === 'fade' ? 'fadeOut' : 'slideUp' ]( $submenuParent.children( '.nav-item-children, .children' ), $submenuParent.hasClass( 'megamenu' ) );

						document.dispatchEvent(
							new CustomEvent( 'lqd-submenu-change', { bubbles: false, detail: { state: 'hide', $submenu: null } } )
						);
					}
				} );

			}
		}

		async getRects( i, submenu, submenuParent ) {
			this.rects[ i ] = {
				submenuRect: {},
				subParentRect: {}
			}

			return fastdomPromised.measure( () => {

				return new Promise( resolve => {
					new IntersectionObserver( ( [ entry ], observer ) => {
						const { boundingClientRect } = entry;
						observer.disconnect();
						resolve( boundingClientRect );
					} ).observe( submenu )
				} );

			} ).then( submenuRect => {

				this.rects[ i ].submenuRect = submenuRect;

				return new Promise( resolve => {
					new IntersectionObserver( ( [ entry ], observer ) => {
						const { boundingClientRect } = entry;
						observer.disconnect();
						resolve( boundingClientRect );
					} ).observe( submenuParent );
				} );

			} ).then( subParentRect => {

				this.rects[ i ].subParentRect = subParentRect;

			} )
		}

		positioning( i, submenu, submenuParent ) {
			return fastdomPromised.mutate( () => {

				const submenuRect = this.rects[ i ].submenuRect;
				const subParentRect = this.rects[ i ].subParentRect;

				if ( submenuRect.left + submenuRect.width >= this.windowWidth ) {
					submenu.classList.add( 'to-left' );
				}

				submenuParent.style.setProperty( '--item-height', `${ subParentRect.height }px` );

				submenuParent.classList.add( 'position-applied' );

			} );
		}
	}

	$.fn[ pluginName ] = function ( options ) {

		return this.each( function () {

			const pluginOptions = { ...$( this ).data( 'submenu-options' ), ...options };

			if ( !$.data( this, "plugin_" + pluginName ) ) {
				$.data( this, "plugin_" + pluginName, new Plugin( this, pluginOptions ) );
			}

		} );

	};

}( jQuery ) );

jQuery( document ).ready( function ( $ ) {
	$( '.main-nav, .lqd-custom-menu' ).liquidSubmenu();
} );