( function ( $ ) {

	'use strict';

	const pluginName = 'liquidCustomAnimations';
	let defaults = {
		delay: 160,
		startDelay: 0,
		// 'forward', 'backward', 'random', 'center', 'edges'
		direction: 'forward',
		duration: 1600,
		ease: 'power4.out',
		animationTarget: 'this', // it can be also a selector e.g. '.selector', or it ccan 'all-childs'
		addPerspective: true,
		perspectiveVal: 1400,
		initValues: { x: 0, y: 0, z: 0, rotationX: 0, rotationY: 0, rotationZ: 0, scaleX: 1, scaleY: 1, skewX: 0, skewY: 0, opacity: 1, transformOriginX: 50, transformOriginY: 50, transformOriginZ: '0px' },
		animations: { transformOriginX: 50, transformOriginY: 50, transformOriginZ: '0px' },
		randomizeInitValues: false,
		randomizeTargets: false,
		clearProps: 'transform,opacity,transform-origin',
	};

	class Plugin {

		constructor( element, options ) {

			this._defaults = defaults;
			this._name = pluginName;

			this.options = { ...defaults, ...options };

			this.options.duration = this.options.duration / 1000;
			this.options.offDuration = this.options.offDuration / 1000;
			this.options.offDelay = this.options.offDelay / 1000;
			this.options.delay = this.options.delay / 1000;
			this.options.startDelay = this.options.startDelay / 1000;

			this.element = element;
			this.$element = $( element );

			this.animationTargets = [];
			this.animationsTimeline = null;
			this.animationsStarted = false;

			this.needPerspective = this.options.addPerspective && this._needPerspective();

			this.animationsInitiatedPromise = new Promise( resolve => {
				this.$element.on( 'lqdanimationsinitiated', resolve.bind( this, this ) )
			} );

			this.animationsDonePromise = new Promise( resolve => {
				this.$element.on( 'lqdanimationsdone', resolve.bind( this, this ) )
			} );

			new IntersectionObserver( ( [ entry ], observer ) => {
				if ( entry.isIntersecting ) {
					observer.disconnect();
					this._build();
				}
			}, { rootMargin: '8%' } ).observe( this.element );

		}

		_build() {

			const $rowBgParent = this.$element.closest( '[data-row-bg]' );
			const $slideshowBgParent = this.$element.closest( '[data-slideshow-bg]' );
			const promises = [];

			if ( !this.element.classList.contains( 'vc_row' ) ) {

				const $splitTextEls = this.$element.find( '[data-split-text]' );

				if ( this.element.hasAttribute( 'data-split-text' ) ) {
					$splitTextEls.push( this.element );
				}

				if ( $splitTextEls.length ) {
					$splitTextEls.each( ( i, el ) => {
						const $el = $( el );
						$el.liquidSplitText( { forceApply: true } );
						const prom = $el.data( 'plugin_liquidSplitText' );
						prom && promises.push( prom.splitDonePormise );
					} )
				}

			}

			if ( $rowBgParent.length ) {
				const prom = $rowBgParent.data( 'plugin_liquidRowBG' );
				prom && promises.push( prom.rowBgInitPromise );
			}
			if ( $slideshowBgParent.length ) {
				const prom = $slideshowBgParent.data( 'plugin_liquidSlideshowBG' );
				prom && promises.push( prom.slideshowBgInitPromise );
			}

			if ( promises.length > 0 ) {
				Promise.all( promises ).then( () => {
					this._init();
				} )
			} else {
				this._init();
			}

		}

		_init() {

			// this._onWindowResize = liquidDebounce( this._onWindowResize, 350 );

			this._getAnimationTargets();
			this._createTimeline();
			this._initValues();
			this._runAnimations()
			this._initPlugins();
			// this._handleResize();

		}

		_getAnimationTargets() {

			const { animationTarget } = this.options;
			let targets = null;

			switch ( animationTarget ) {
				case 'this':
					targets = this.element;
					break;
				case 'all-childs':
					targets = this._getChildElments();
					break;
				default:
					targets = this.element.querySelectorAll( animationTarget );
					break;
			}

			this.animationTargets = Array.from( targets );

		}

		_getChildElments() {

			let $childs = this.$element.children();

			return this._getInnerChildElements( $childs );

		}

		_getInnerChildElements( elements ) {

			const elementsArray = [];

			let $elements = $( elements ).map( ( i, element ) => {

				const $element = $( element );

				if ( $element.hasClass( 'vc_inner' ) || $element.hasClass( 'vc_vc_row_inner' ) ) {
					return $element.find( '.wpb_wrapper' ).children().get();
				} else if ( $element.hasClass( 'row' ) ) {
					return $element.find( '.lqd-column' ).children().get();
				} else if ( $element.hasClass( 'ld-slideelement-visible' ) || $element.hasClass( 'ld-slideelement-hidden' ) ) {
					return $element.children().children().get();
				} else if ( $element.hasClass( 'elementor-container' ) ) {
					return $element.children( '.elementor-column' ).get();
				} else if ( $element.hasClass( 'elementor-widget-wrap' ) ) {
					return $element.children( '.elementor-element' ).get();
				} else {
					return $element.not( 'style, .lqd-exclude-parent-ca' ).get();
				}

			} );

			$.each( $elements, ( i, element ) => {

				const $element = $( element );

				if ( element.hasAttribute( 'data-custom-animations' ) ) {
					return elementsArray.push( element );
				}

				if ( element.querySelector( '[data-custom-animations]' ) ) {
					return element.querySelectorAll( '[data-custom-animations]' ).forEach( el => {
						elementsArray.push( el );
					} );
				}

				if ( element.tagName === 'UL' ) {
					return $.each( $element.children(), ( i, li ) => {
						elementsArray.push( li );
					} );
				}

				if ( element.classList.contains( 'lqd-custom-menu' ) ) {
					return $.each( $element.find( '> ul > li' ), ( i, li ) => {
						elementsArray.push( li );
					} );
				}

				if ( element.classList.contains( 'accordion' ) ) {
					return $.each( $element.children(), ( i, accordionItem ) => {
						elementsArray.push( accordionItem );
					} );
				}

				if ( element.classList.contains( 'vc_inner' ) || element.classList.contains( 'vc_vc_row_inner' ) ) {
					return $.each( $element.find( '.wpb_wrapper' ), ( i, innerColumn ) => {
						elementsArray.push( innerColumn );
					} );
				}

				if ( element.classList.contains( 'row' ) ) {
					return $.each( $element.find( '.lqd-column' ), ( i, innerColumn ) => {
						elementsArray.push( innerColumn );
					} );
				}

				if ( element.classList.contains( 'lqd-pb-container' ) ) {
					return $.each( $element.find( '.lqd-pb' ), ( i, processBoxElement ) => {
						elementsArray.push( processBoxElement );
					} );
				}

				if ( $element.find( '[data-split-text]' ).length || element.hasAttribute( 'data-split-text' ) ) {

					if ( element.classList.contains( 'btn' ) || element.classList.contains( 'vc_ld_button' ) ) {
						return elementsArray.push( $element[ 0 ] );
					} else {
						return $.each( $element.find( '.split-inner' ), ( i, splitInner ) => {
							const $innerSplitInner = $( splitInner ).find( '.split-inner' );
							if ( $innerSplitInner.length ) {
								elementsArray.push( $innerSplitInner[ 0 ] );
							} else {
								elementsArray.push( splitInner );
							}
						} );
					}

				}

				if (
					!element.classList.contains( 'vc_empty_space' ) &&
					!element.classList.contains( 'ld-empty-space' ) &&
					!element.classList.contains( 'vc_ld_spacer' ) &&
					!element.classList.contains( 'ld-particles-container' ) &&
					!element.classList.contains( 'elementor-widget-spacer' ) &&
					!element.hasAttribute( 'data-split-text' ) &&
					element.tagName !== 'STYLE'
				) {
					return elementsArray.push( $element[ 0 ] );
				}

			} );

			return elementsArray;

		}

		_needPerspective() {

			const initValues = this.options.initValues;
			const valuesNeedPerspective = [ "z", "rotationX", "rotationY" ];
			let needPerspective = false;

			for ( let prop in initValues ) {
				for ( let i = 0; i <= valuesNeedPerspective.length - 1; i++ ) {

					const val = valuesNeedPerspective[ i ];

					if ( prop === val ) {
						needPerspective = true;
						break;
					}

				}
			}
			return needPerspective;
		}

		_generateRandomValues( valuesObject ) {

			const obj = { ...valuesObject };

			for ( const ky in valuesObject ) {
				if ( ky.search( 'transformOrigin' ) < 0 && ky.search( 'opacity' ) < 0 ) {
					obj[ ky ] = () => gsap.utils.random( 0, valuesObject[ ky ] );
				};
			}
			return obj;

		}

		_createTimeline() {

			const { ease, duration, clearProps } = this.options;

			this.animationsTimeline = gsap.timeline( {
				defaults: {
					duration,
					ease,
					clearProps
				},
				onComplete: this._onTimelineAnimationComplete.bind( this )
			} );

		}

		_initValues() {

			const { options } = this;
			const { randomizeInitValues, initValues } = options;
			const $animationTargets = $( this.animationTargets );
			const initProps = !randomizeInitValues ? initValues : this._generateRandomValues( initValues );

			$animationTargets
				.css( {
					transition: 'none',
					transitionDelay: 0,
				} )
				.addClass( 'will-change' );

			if ( this.needPerspective ) {
				$animationTargets.parent().parent().addClass( 'perspective' );
				// prevents wrong positioning when the elemnt is position:absolute
				$animationTargets.each( ( i, animTarget ) => {
					const $animTarget = $( animTarget );
					if ( !$animTarget.hasClass( 'lqd-imggrp-single' ) ) {
						$animTarget.parent().addClass( 'transform-style-3d' );
					}
				} )
			}

			gsap.set( this.animationTargets, {
				...initProps,
			} );

			this.element.classList.add( 'ca-initvalues-applied' );

			this.$element.trigger( 'lqdanimationsinitiated', this );

		}

		async _runAnimations() {

			const { delay, startDelay, animations, direction } = this.options;
			const stagger = {
				from: direction,
				each: delay
			};

			if ( direction === 'forward' ) {
				stagger[ 'from' ] = 'start';
			} else if ( direction === 'backward' ) {
				stagger[ 'from' ] = 'end';
			}

			this.animationsTimeline.to( this.animationTargets, {
				...animations,
				stagger,
				delay: startDelay,
				onStart: () => {
					this.animationsStarted = true;
				},
				onComplete: this._onUnitsAnimationsComplete,
				onCompleteParams: [ this.animationTargets ]
			} );

		}

		_onTimelineAnimationComplete() {

			if ( this.needPerspective ) {
				$( this.animationTargets ).parent().parent().removeClass( 'perspective' );
				$( this.animationTargets ).parent().removeClass( 'transform-style-3d' );
			}

			this.$element.addClass( 'lqd-animations-done' );
			this.$element.trigger( 'lqdanimationsdone', this );

		}

		_onUnitsAnimationsComplete( animationTargets ) {

			animationTargets.forEach( element => {

				element.style.transition = '';
				element.style.transitionDelay = '';

				element.classList.remove( 'will-change' );

				if ( element.classList.contains( 'split-inner' ) ) {
					element.parentElement.classList.add( 'lqd-unit-animation-done' );
				} else {
					element.classList.add( 'lqd-unit-animation-done' );
				}

			} )

		}

		_initPlugins() {

			/**
			initing plugins that doesn't depend on animations begins or not
			*/

			this.$element.find( '[data-slideelement-onhover]' ).filter( ( i, element ) => {
				return (
					element.clientHeight > 0
				)
			} ).liquidSlideElement();
			this.element.hasAttribute( 'data-slideelement-onhover' ) && this.$element.liquidSlideElement();

		}

		destroy() {

			this.element.classList.remove( 'ca-initvalues-applied', 'lqd-animations-done', 'transform-style-3d' );

			this.animationTargets.forEach( target => {

				if ( !target.vars ) {

					target.classList.remove( 'will-change' );

					if ( target.classList.contains( 'split-inner' ) ) {
						target.parentElement.classList.remove( 'lqd-unit-animation-done' );
					} else {
						target.classList.remove( 'lqd-unit-animation-done' );
					}

					gsap.set( target, { clearProps: 'all' } );

				} else {
					this.animationsTimeline.killTweensOf( target );
				}

			} )

			if ( this.animationsTimeline ) {

				this.animationsTimeline.kill();
				this.animationsTimeline.clear();

			}

			$.data( this.element, 'plugin_' + pluginName, null );

		}

	}

	$.fn[ pluginName ] = function ( options ) {

		return this.each( function () {

			const $this = $( this );
			const plugin = `plugin_${ pluginName }`;
			const pluginOptions = { ...$this.data( 'ca-options' ), ...options };
			let { initValues, animations } = pluginOptions;

			function handleTransformOrigins( opts ) {

				if ( !opts ) return;

				const { transformOriginX, transformOriginY, transformOriginZ } = opts;

				if ( transformOriginX && typeof transformOriginX === 'number' ) {
					opts.transformOriginX = transformOriginX + '%';
				}
				if ( transformOriginY && typeof transformOriginY === 'number' ) {
					opts.transformOriginY = transformOriginY + '%';
				}
				if ( transformOriginZ && typeof transformOriginZ === 'number' ) {
					opts.transformOriginZ = transformOriginZ + '%';
				}

				if ( transformOriginX && transformOriginY && transformOriginZ ) {
					opts.transformOrigin = `${ opts.transformOriginX } ${ opts.transformOriginY } ${ opts.transformOriginZ }`;
					delete opts.transformOriginX;
					delete opts.transformOriginY;
					delete opts.transformOriginZ;
				}

				return opts;

			}

			initValues = handleTransformOrigins( initValues );
			animations = handleTransformOrigins( animations );

			if ( !$.data( this, plugin ) ) {
				$.data( this, `plugin_${ pluginName }`, new Plugin( this, pluginOptions ) );
			}

		} );

	};

}( jQuery ) );

jQuery( document ).ready( function ( $ ) {

	// causing animations not initialize when site structure is changed
	// if ( ! $liquidContents.length ) return;

	// we'll call custom animations directly from these plugins
	const anims = $( '[data-custom-animations]' ).filter( ( i, element ) => {

		const $element = $( element );
		const stackOptions = $liquidContents.length && $liquidContents[ 0 ].getAttribute( 'data-stack-options' );
		const stackDisableOnMobile = stackOptions && JSON.parse( stackOptions ).disableOnMobile === true;

		return (
			( !stackOptions || ( stackOptions && stackDisableOnMobile && ( liquidIsMobile() || liquidWindowWidth() <= liquidMobileNavBreakpoint() ) ) ) &&
			!$element.hasClass( 'carousel-items' )
		);

	} ).get().reverse();

	if ( anims.length < 1 ) {
		return
	};

	if (
		liquidIsMobile() &&
		document.body.hasAttribute( 'data-disable-animations-onmobile' )
	) {
		return $( anims ).addClass( 'ca-initvalues-applied' );
	};

	if (
		$liquidBody.hasClass( 'lqd-preloader-activated' ) &&
		$( '.lqd-preloader-wrap' ).length
	) {
		document.addEventListener( 'lqd-preloader-anim-done', () => {
			$( anims ).liquidCustomAnimations();
		} );
	} else {
		$( anims ).liquidCustomAnimations();
	}

} );