(function ( $ ) {
	
	'use strict';
	
	const pluginName = 'liquidProgressbar';
	let defaults = {
		value: 0,
		suffix: null,
		prefix: null,
		skipCreateMarkup: false,
		orientation: "horizontal"
	};
	
	class Plugin {

		constructor(element, options) {

			this.element = element;
			this.$element = $(element);

			this.options = $.extend({}, defaults, options);
			this._defaults = defaults;
			this._name = pluginName;

			this.valueEl = $('.liquid-progressbar-value, .lqd-progressbar-value', element);
			this.prefixEl = $('.liquid-progressbar-prefix, .lqd-progressbar-prefix', element);
			this.suffixEl = $('.liquid-progressbar-suffix, .lqd-progressbar-prefix', element);
			this.percentageElement = $('.liquid-progressbar-percentage, .lqd-progressbar-percentage', element);
			this.barElement = $('.liquid-progressbar-bar, .lqd-progressbar-bar', element);
			this.titleElement = $('.liquid-progressbar-title, .lqd-progressbar-title', element);

			this.isRTL = $('html').attr('dir') == 'rtl';

			this.init();

		}

		init() {
	
			this.addValue();
			this.addPrefixSuffix();
			this.setupIntersectionObserver();
			
		}
	
		addValue() {

			if (
				this.options.skipCreateMarkup ||
				this.valueEl.length ||
				! this.percentageElement.length
			) return;
	
			this.valueEl = $('<span class="liquid-progressbar-value lqd-progressbar-value">0</span>');
	
			this.percentageElement.html('');
			this.valueEl.appendTo(this.percentageElement);
	
		}
	
		addPrefixSuffix() {

			if (
				this.options.skipCreateMarkup ||
				(this.prefixEl.length && this.suffixEl.length) ||
				! this.percentageElement.length
			) return;
	
			const prefixOpt = this.options.prefix;
			const suffixOpt = this.options.suffix;
			const prefixEl = $('<span class="liquid-progressbar-prefix lqd-progressbar-prefix"></span>');
			const suffixEl = $('<span class="liquid-progressbar-suffix lqd-progressbar-suffix"></span>');
	
			if ( prefixOpt ) prefixEl.text(prefixOpt);
	
			if ( suffixOpt ) suffixEl.text(suffixOpt);
	
			prefixEl.prependTo(this.percentageElement);
			suffixEl.appendTo(this.percentageElement);
	
		}
	
		checkValuesEncountering() {

			if ( ! this.percentageElement.length ) return;
			
			if ( this.options.orientation == "horizontal" && this.titleElement.length ) {
				
				const titleWidth = this.titleElement.width();
				const percentageOffsetLeft = this.percentageElement.offset().left || 0;
				const percentageWidth = this.percentageElement.width();
				const titleOffsetLeft = this.titleElement.offset().left || 0;

				if ( ! this.isRTL ) {
					
					if ( percentageOffsetLeft >= titleOffsetLeft + titleWidth ) {
						this.$element.addClass('values-not-encountering');
					} else {
						this.$element.removeClass('values-not-encountering');
					}
					
				} else {
					
					if ( percentageOffsetLeft + percentageWidth <= titleOffsetLeft ) {
						this.$element.addClass('values-not-encountering');
					} else {
						this.$element.removeClass('values-not-encountering');
					}

				}
	
				
			} else {
	
				this.$element.addClass('values-not-encountering');
				
			}
	
		}
	
		setupIntersectionObserver() {
	
			new IntersectionObserver(([entry], observer) => {
				if ( entry.isIntersecting ) {
					
					this.animatePercentage();
					this.animateProgressbar();

					observer.unobserve(entry.target);

				}
			}, { threshold: 1 }).observe(this.element);
	
		}
	
		animatePercentage() {
	
			const percentage = { value: 0 };
	
			gsap.to(percentage, {
				value: this.options.value,
				duration: 1.2,
				ease: 'power3.inOut',
				onUpdate: () => {
					this.percentageElement.length && this.valueEl.text(Math.round(percentage.value));
				}
			});
	
		}
	
		animateProgressbar() {
	
			const barElement = this.barElement.get(0);
			const value = this.options.value + '%';
			const orientation = this.options.orientation;
	
			if ( orientation === "horizontal" ) {
	
				this.animateHorizontal(barElement, value);
	
			} else {
				
				this.initCircleProgressbar(value);
	
			}
			
		}
		
		animateHorizontal(barElement, value) {
			
			gsap.to(barElement, {
				width: value,
				duration: 1.2,
				ease: 'power3.inOut',
				onUpdate: () => {
					this.checkValuesEncountering();
				}
			});

		}
	
		initCircleProgressbar(value) {
	
			const circleContainer = $(this.element).find('.ld-prgbr-circle-container');
			const containerWidth = circleContainer.width();
			const numericVal = parseInt(value, 10);
	
			circleContainer.circleProgress({
				value: numericVal / 100,
				size: containerWidth,
				lineCap: 'round',
				startAngle: -Math.PI/2
			});
	
		}

	}
	
	$.fn[ pluginName ] = function( options ) {
		
		return this.each( function() {
			
			const pluginOptions = {...$(this).data('progressbar-options'), ...options};
			
			if ( !$.data( this, "plugin_" + pluginName ) ) {
				$.data( this, "plugin_" + pluginName, new Plugin( this, pluginOptions ) );
			}
			
		} );
		
	};
	
}(jQuery));

jQuery(document).ready( function($) {

	$('[data-progressbar]').liquidProgressbar();

});