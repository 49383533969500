( function ( $ ) {

	'use strict';

	const pluginName = 'liquidZIndex';
	let defaults = {
		triggers: [ 'mouseenter', 'mouseleave' ],
		setTo: 'self',
		init: 10,
		to: 15,
		duration: 0.6
	};

	class Plugin {

		constructor( element, options ) {

			this._defaults = defaults;
			this._name = pluginName;

			this.options = { ...defaults, ...options };

			this.element = element;
			this.$element = $( element );

			this.init();

		}

		init() {

			this
			this.initEvents();

		}

		initEvents() {

			const { triggers } = this.options;

			this.$element.on( triggers[ 0 ], this.addZIndex.bind( this ) );
			this.$element.on( triggers[ 1 ], this.removeZIndex.bind( this ) );

		}

		addZIndex() {

			window.currentZIndex = window.currentZIndex || 10;

			window.currentZIndex += 1;

			gsap.killTweensOf( this.element );

			gsap.set( this.element, {
				zIndex: window.currentZIndex
			} )

		}

		removeZIndex() {

			const { duration } = this.options;

			gsap.to( this.element, {
				zIndex: this.options.init,
				delay: duration,
				ease: 'quint.out',
				duration: 0.15
			} )

		}

	}

	$.fn[ pluginName ] = function ( options ) {

		return this.each( function () {

			const pluginOptions = { ...$( this ).data( 'zindex-options' ), ...options };

			if ( !$.data( this, "plugin_" + pluginName ) ) {
				$.data( this, "plugin_" + pluginName, new Plugin( this, pluginOptions ) );
			}

		} );

	};

}( jQuery ) );

jQuery( document ).ready( function ( $ ) {
	$( '[data-lqd-zindex]' ).liquidZIndex();
} );