(function ( $ ) {
	
	'use strict';
	
	const pluginName = 'liquidStretchElement';
	let defaults = {
		to: 'right'
	};
	
	class Plugin {

		constructor(element, options) {
			this.element = element;
			this.$element = $(element);

			this.options = $.extend({}, defaults, options);

			this._defaults = defaults;
			this._name = pluginName;

			this.isStretched = false;
			this.boundingClientRect = null;
			this.rootBounds = null;

			this.initIO();

		}

		initIO() {

			new IntersectionObserver( enteries => {

				enteries.forEach( entry => {

					if ( entry.isIntersecting && ! this.isStretched ) {

						this.boundingClientRect = entry.boundingClientRect;
						this.rootBounds = entry.rootBounds;

						this.init();

						this.isStretched = true;

					}

				} );

			} ).observe(this.element, { rootMargin: '3%' });

		}
			
		init() {
			
			this.stretch();
			this.$element.addClass('element-is-stretched');
			
		}

		stretch() {

			if ( this.options.to === 'right' ) {
				this.stretchToRight();
			} else {
				this.stretchToLeft();
			}

		}

		stretchToRight() {

			const offset = this.rootBounds.width - ( this.boundingClientRect.width + this.boundingClientRect.left );

			this.$element.css( 'marginRight', offset * -1 );
			
		}
		
		// TODO: probably not correct. need to be revisited
		stretchToLeft() {

			const offset = this.rootBounds.width - this.boundingClientRect.left;

			this.$element.css( 'marginLeft', offset * -1 );

		}
		
	}
	
	$.fn[ pluginName ] = function( options ) {
		
		return this.each( function() {
			
			const pluginOptions = $(this).data('stretch-options') || options;
			
			if ( !$.data( this, "plugin_" + pluginName ) ) {
				$.data( this, "plugin_" + pluginName, new Plugin( this, pluginOptions ) );
			}
			
		} );
		
	};
	
}(jQuery));

jQuery(document).ready( function($) {
  $('[data-stretch-element=true]').liquidStretchElement();
});