// eslint-disable-next-line no-unused-vars, no-redeclare
class LiquidSectionsDetails {

	constructor() {

		this.sections = [];
		this.footerBg = tinycolor( liquidMainFooterBg ).getAlpha() === 0 ? liquidBodyBg : liquidMainFooterBg;

	}

	static getInstance() {

		if ( !this.instance ) {
			this.instance = new LiquidSectionsDetails();
		}

		return this.instance;

	}

	static getDetails() {

		const instance = this.getInstance();

		return new Promise( async resolve => {

			if ( instance.sections.length < 1 ) {

				instance.sections = [];

				const liquidContentsRect = await instance.getElementRect( { element: $liquidContents[ 0 ] } );

				await Promise.all( instance.init( liquidContentsRect, instance ) );

				/*
				in cases if there's no sections in main content but there's sections in footer,
				e.g. blog posts
				something like header dynamic colors won't work properly
				*/
				const mainContentSections = instance.sections.filter( section => section.isInMainContent );

				if ( mainContentSections.length < 1 ) {

					const DOM = {
						element: $liquidContents[ 0 ],
						$element: $liquidContents,
					};

					await instance.createDetailsObj( liquidContentsRect, liquidContentsRect, DOM, true ).then( detailsObj => {
						instance.sections.unshift( detailsObj );
					} );

				}

				instance.addParentSections( instance );
				instance.addInnerSections( instance );
				await instance.addLuminosity( instance );

			}

			resolve( instance.sections );

		} )

	}

	init( liquidContentsRect, instance ) {

		const promises = [];

		$liquidSections.each( ( i, row ) => {

			const promise = new Promise( resolve => {

				const DOM = {
					element: row,
					$element: jQuery( row ),
					parent: row.parentElement,
				};

				this.getElementRect( DOM ).then( rowRect => {
					this.createDetailsObj( liquidContentsRect, rowRect, DOM, false ).then( detailsObj => {
						instance.sections[ i ] = detailsObj;
						resolve( detailsObj );
					} );
				} )

			} );

			promises.push( promise );

		} );

		return promises;

	}

	getElementRect( DOM ) {

		return new Promise( resolve => {

			new IntersectionObserver( ( [ entry ], observer ) => {

				fastdom.measure( () => {
					observer.disconnect();
					resolve( entry.boundingClientRect );
				} )

			} ).observe( DOM.element );

		} );

	}

	createDetailsObj( liquidContentsRect, rowRect, DOM, isLiquidContentElement ) {

		return new Promise( resolve => {

			fastdom.measure( async () => {

				const { scrollY, scrollX } = window;
				const styles = getComputedStyle( DOM.element );
				const obj = {};

				obj.el = DOM.element;
				obj.$el = DOM.$element;
				obj.rect = {
					initialOffset: {
						x: rowRect.x + scrollX,
						y: rowRect.y + scrollY
					},
					width: rowRect.width,
					height: rowRect.height,
					x: rowRect.x,
					y: rowRect.y,
				};
				obj.backgroundColor = styles.backgroundColor;

				if ( isLiquidContentElement ) {
					obj.isMainContentElement = true;
					return resolve( obj );
				}

				const footerParent = DOM.element.closest( '.main-footer' );
				const elementorTopContainer = DOM.$element.parents( '.e-container, .e-con' );

				obj.borderColor = styles.borderColor;
				obj.isOuterSection =
					liquidIsElementor ?
						DOM.element.classList.contains( 'elementor-top-section' ) || ! !!elementorTopContainer.length :
						DOM.element.classList.contains( 'vc_section' ) || DOM.element.parentElement.closest( '.lqd-section' ) == null;
				obj.isInnerSection =
					liquidIsElementor ?
						DOM.element.classList.contains( 'elementor-inner-section' ) || !!elementorTopContainer.length :
						DOM.parent.classList.contains( 'vc_section' ) || DOM.element.parentElement.closest( '.lqd-section' ) != null;
				obj.isInFooter = footerParent != null;
				obj.isInMainContent = DOM.element.closest( '#lqd-site-content' ) != null;
				obj.isHidden = obj.rect.width < 1 && obj.rect.height < 1;
				obj.predefinedLuminosity = null;
				obj.parentSection = null;
				obj.innerSections = [];

				if ( obj.el.hasAttribute( 'data-section-luminosity' ) ) {
					obj.predefinedLuminosity = obj.el.getAttribute( 'data-section-luminosity' )
				}

				if ( obj.isInFooter ) {

					obj.parentFooter = footerParent;

					if ( footerParent.hasAttribute( 'data-sticky-footer' ) ) {

						const footerOffsetTop = liquidContentsRect.height;
						const footerHeight = ( document.body.offsetHeight - ( liquidContentsRect.y + scrollY ) ) - liquidContentsRect.height;
						const elPositionTop = Math.abs( window.innerHeight - footerHeight - obj.rect.y );

						obj.rect.initialOffset.y = footerOffsetTop + elPositionTop;
						obj.rect.y = footerOffsetTop + elPositionTop;

					}

				}

				resolve( obj );

			} )

		} );

	}

	addParentSections( instance ) {

		const innerSections = instance.sections.filter( sec => sec.isInnerSection );

		innerSections.forEach( innerSec => {

			let parentSec = null;

			if ( liquidIsElementor ) {
				parentSec = innerSec.el.closest( '.elementor-top-section' ) || innerSec.$el.parents( '.e-container' ) || innerSec.$el.parents( '.e-con' );
			} else {
				parentSec = innerSec.el.closest( '.vc_section' ) || innerSec.el.parentElement.closest( '.lqd-section' );
			}

			instance.sections.forEach( sec => {
				if ( sec.el === parentSec ) {
					innerSec.parentSection = sec;
				}
			} )

		} );

	}

	addInnerSections( instance ) {

		const innerSections = instance.sections.filter( sec => sec.isInnerSection );

		instance.sections.forEach( ( outerSec, i ) => {

			if ( outerSec.isInnerSection ) return;

			innerSections.forEach( innerSec => {

				if ( innerSec.parentSection && innerSec.parentSection.el === outerSec.el ) {
					instance.sections[ i ].innerSections.push( innerSec );
				}

			} )

		} );

	}

	getLuminosity( obj, instance ) {

		let { backgroundColor } = obj;

		if ( obj.isInnerSection && obj.parentSection && tinycolor( backgroundColor ).getAlpha() === 0 ) {
			backgroundColor = obj.parentSection.backgroundColor;
		}

		if ( tinycolor( backgroundColor ).getAlpha() === 0 ) {

			if ( obj.isInFooter ) {
				backgroundColor = instance.footerBg;
			} else {
				backgroundColor = window.liquidContentsBg;
			}

		}

		return tinycolor( backgroundColor ).isDark() ? 'dark' : 'light';

	}

	async addLuminosity( instance ) {

		instance.sections.forEach( async sec => {

			sec.isBgTransparent = tinycolor( sec.backgroundColor ).getAlpha() === 0;
			sec.luminosity = sec.predefinedLuminosity ? sec.predefinedLuminosity : instance.getLuminosity( sec, instance );

			await fastdomPromised.mutate( () => {
				sec.el.setAttribute( 'data-section-luminosity', sec.luminosity );
			} )

		} )

	}

};