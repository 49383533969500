( function ( $ ) {

	'use strict';

	const pluginName = 'liquidTab';
	let defaults = {
		deepLink: false,
		trigger: 'click', // 'click', 'hover'
		translateNav: false
	};

	class Plugin {

		constructor( element, options ) {

			this._defaults = defaults;
			this._name = pluginName;

			this.options = { ...defaults, ...options };
			this.isBS5 = typeof bootstrap !== 'undefined';
			this.isRTL = $( 'html' ).attr( 'dir' ) === 'rtl';

			this.element = element;
			this.$element = $( element );
			this.$tabNav = $( '.lqd-tabs-nav', this.element );
			this.$navItems = $( '> li > a', this.$tabNav );
			this.$activeItem = this.$navItems.filter( ( i, el ) => el.parentElement?.classList?.contains( 'active' ) || el.classList.contains( 'active' ) );
			this.$tabNavArrows = ( '.lqd-tabs-nav-arrows', this.element );
			this.$tabNavPrev = $( '.lqd-tabs-nav-prev', this.$tabNavArrows );
			this.$tabNavNext = $( '.lqd-tabs-nav-next', this.$tabNavArrows );
			this.canChange = true;

			if ( this.$activeItem.length && this.options.translateNav ) {
				this.activeItemWidth = this.$activeItem.outerWidth();
				this.activeItemHeight = this.$activeItem.outerHeight();
				console.log( this.$activeItem.position().left + this.activeItemWidth );
				this.activeItemPosLeft = !this.isRTL ? this.$activeItem.position().left : ( ( this.$tabNav.width() - ( this.$activeItem.position().left + this.activeItemWidth ) * -1 ) );
			}

			if ( this.element.parentElement.closest( '.lqd-mobile-sec' ) ) {
				this.changeIds();
			}

			this.init();

		}

		changeIds() {
			this.$navItems.each( ( i, navItem ) => {
				const href = navItem.getAttribute( 'href' );
				navItem.setAttribute( 'href', `${ href }-mobile` );
				navItem.setAttribute( 'aria-controls', `${ navItem.getAttribute( 'aria-controls' ) }-mobile` );
				this.element.querySelector( href ).setAttribute( 'id', `${ href.replace( '#', '' ) }-mobile` );
			} );
		}

		init() {

			const { deepLink, translateNav } = this.options;

			if ( !this.isBS5 ) {
				this.$navItems.tab();
			} else {
				this.$navItems.each( ( i, el ) => new bootstrap.Tab( el ) );
			}

			deepLink && this.setHash();
			translateNav && this.translateNav( false );

			this.eventHandlers();

		}

		setHash() {

			const $el = this.$tabNav.find( `a[href="${ location.hash }"]` );

			if ( location.hash === '' || !$el.length ) return;

			$el.tab( 'show' );

			if ( window.scrollY === 0 ) {
				$( 'html,body' ).stop().animate( {
					scrollTop: this.$tabNav.offset().top - 90
				}, 600 )
			}

		}

		eventHandlers() {

			if ( this.options.trigger === 'hover' && !liquidIsMobile() ) {

				this.$navItems.on( 'mouseenter.lqdTabs', event => {
					const button = event.currentTarget;
					if ( !this.isBS5 ) {
						$( button ).tab( 'show' );
					} else {
						bootstrap.Tab.getInstance( button ).show();
					}
				} );

			}

			if ( !this.isBS5 ) {
				this.$element.on( 'hide.bs.tab', this.onHide.bind( this ) );
				this.$element.on( 'show.bs.tab', this.onShow.bind( this ) );
				this.$element.on( 'shown.bs.tab', this.onShown.bind( this ) );
			} else {
				this.$navItems.each( ( i, el ) => {
					el.addEventListener( 'hide.bs.tab', this.onHide.bind( this ) );
					el.addEventListener( 'show.bs.tab', this.onShow.bind( this ) );
					el.addEventListener( 'shown.bs.tab', this.onShown.bind( this ) );
				} )
			}

			this.$tabNavPrev.on( 'click.lqdTabs', this.showPrev.bind( this ) );
			this.$tabNavNext.on( 'click.lqdTabs', this.showNext.bind( this ) );

		}

		onHide( event ) {

			const link = event.target;
			const dataTarget = link.getAttribute( 'data-bs-target' );

			if ( dataTarget ) {
				const target = document.querySelector( dataTarget );
				if ( target ) {
					target.classList.remove( 'active', 'show', 'in' )
				}
			}

			link.parentElement.classList.remove( 'active' );

			this.canChange = false;

		}

		onShow( event ) {

			const link = event.target;
			const { deepLink, translateNav } = this.options;
			const dataTarget = link.getAttribute( 'data-bs-target' );

			this.$activeItem = $( link );

			if ( deepLink ) {
				const href = $( event.target ).attr( 'href' );
				location.hash = href;
				// to prevent scroll jump after changing location.hash
				const scrollPos = $( document ).scrollTop();
				$( document ).scrollTop( scrollPos );
			}

			this.$activeItem.parent().addClass( 'active' ).siblings().removeClass( 'active' );

			if ( dataTarget ) {
				const target = document.querySelector( dataTarget );
				if ( target ) {
					$( target ).siblings().removeClass( 'active' );
				}
			}

			// For the frontend editor
			if ( event.relatedTarget ) {

				const $prevActiveItem = $( event.relatedTarget );
				const $prevActiveTarget = $( $prevActiveItem.attr( 'href' ) );

				$prevActiveTarget.removeClass( 'active in' );

			}

			if ( translateNav ) {

				this.activeItemWidth = this.$activeItem.outerWidth();
				this.activeItemHeight = this.$activeItem.outerHeight();
				this.activeItemPosLeft = !this.isRTL ? this.$activeItem.position().left : ( ( this.$tabNav.width() - ( this.$activeItem.position().left + this.activeItemWidth ) ) * -1 );

				this.translateNav( true );

			}

		}

		onShown( event ) {

			this.canChange = true;

			const $link = $( event.target );
			const $target = $( $link.attr( 'href' ) );
			const offsetTop = liquidIsMobile() ? $( '.lqd-tabs-content', this.$element ).offset().top : this.$element.offset().top;
			const stickyHeaderHeight = $( '[data-sticky-header].is-stuck' )?.outerHeight() || 0;

			const link = event.target;
			const dataTarget = link.getAttribute( 'data-bs-target' );

			if ( dataTarget ) {
				const target = document.querySelector( dataTarget );
				if ( target ) {
					target.classList.add( 'in' )
				}
			}

			if (
				( !liquidIsMobile() && offsetTop <= $liquidWindow.scrollTop() - 15 ) ||
				( liquidIsMobile() && ( ( offsetTop > $liquidWindow.scrollTop() + window.innerHeight ) || ( $liquidWindow.scrollTop() > offsetTop ) ) )
			) {
				$( 'html, body' ).stop().animate( {
					scrollTop: offsetTop - stickyHeaderHeight - 45
				}, 800 );
			}

			this.initPlugins( $target );

		}

		translateNav( move ) {

			this.element.style.setProperty( '--lqd-tabs-nav-active-width', `${ this.activeItemWidth }px` );
			this.element.style.setProperty( '--lqd-tabs-nav-active-height', `${ this.activeItemHeight }px` );

			if ( move ) {
				this.element.style.setProperty( '--lqd-tabs-nav-translate', `${ this.activeItemPosLeft }px` );
			}

		}

		showPrev() {

			if ( !this.canChange ) {
				return;
			}

			this.$activeItem.parent().prev().children( 'a' ).tab( 'show' );

		}

		showNext() {

			if ( !this.canChange ) {
				return;
			}

			this.$activeItem.parent().next().children( 'a' ).tab( 'show' );

		}

		initPlugins( $target ) {

			const $pie_charts = $( '.vc_pie_chart:not(.vc_ready)', $target );
			const $round_charts = $( '.vc_round-chart', $target );
			const $line_charts = $( '.vc_line-chart', $target );
			const $elementor_gallery = $( '.elementor-gallery__container', $target );

			if ( $pie_charts.length && $.fn.vcChat )
				$pie_charts.vcChart();
			if ( $round_charts.length && $.fn.vcRoundChart )
				$round_charts.vcRoundChart( { reload: !1 } );
			if ( $line_charts.length && $.fn.vcLineChart )
				$line_charts.vcLineChart( { reload: !1 } );

			$( '[data-hover3d=true]', $target ).liquidHover3d();

			$( '[data-split-text]', $target ).liquidSplitText();

			$( '[data-slideelement-onhover]', $target ).liquidSlideElement();

			$( '.cd-image-container', $target ).liquidImageComparison();

			$( '.lqd-carousel-stack', $target ).liquidCarouselStack();

			$elementor_gallery.trigger( 'resize' );

		}

		destroy() {

			this.$navItems.off( 'mouseenter.lqdTabs' );

			this.$element.off( 'hide.bs.tab show.bs.tab shown.bs.tab', this.onHide.bind( this ) );

			this.$tabNavPrev.off( 'click.lqdTabs' );
			this.$tabNavNext.off( 'click.lqdTabs' );

		}

	}


	$.fn[ pluginName ] = function ( options ) {

		return this.each( function () {

			const pluginOptions = { ...$( this ).data( 'tabs-options' ), ...options };

			if ( !$.data( this, "plugin_" + pluginName ) ) {
				$.data( this, "plugin_" + pluginName, new Plugin( this, pluginOptions ) );
			}

		} );

	};

}( jQuery ) );

jQuery( document ).ready( function ( $ ) {
	$( '.lqd-tabs' ).liquidTab();
} );