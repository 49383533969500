( function ( $ ) {

	'use strict';

	const pluginName = 'liquidMegamenu';

	let defaults = {
	};

	class Plugin {

		constructor( element, options ) {

			this.options = { ...defaults, ...options };
			this._defaults = defaults;
			this._name = pluginName;

			this.element = element;
			this.$element = $( this.element );

			this.mobileNavBreakPoint = fastdom.measure( liquidMobileNavBreakpoint )();
			this.tabletBreakpoint = this.mobileNavBreakPoint <= 992 ? 992 : this.mobileNavBreakPoint;

			this.elementBoundingRect = null;
			this.megamenuBoundingRect = null;
			this.megamenuFinalPos = {};
			this.parentOffsetLeft = 0;

			// containerWidth: [windowMinWidth, windowMaxWidth]
			this.breakpoints = {
				[ this.mobileNavBreakPoint - 60 ]: [ this.mobileNavBreakPoint + 1, Infinity ],
				940: [ 992, this.tabletBreakpoint ]
			};
			this.$customMenuParent = this.$element.parent().parent( '.lqd-custom-menu' );
			this.isInCustomMenu = this.$customMenuParent.length && !this.$element.parent().hasClass( 'inline-nav' );
			this.submenu = this.element.querySelector( '.nav-item-children' );
			this.megamenuRowsWrap = this.submenu.querySelector( '.lqd-megamenu-rows-wrap' );
			this.megamenuRows = liquidHeaderIsElementor ?
				this.megamenuRowsWrap.querySelectorAll( ':scope > .elementor > .elementor-section-wrap > .elementor-section, :scope > .elementor > .elementor-section, :scope > .elementor > .e-container, :scope > .elementor > .e-con' ) :
				this.megamenuRowsWrap.querySelectorAll( ':scope > .megamenu-row, :scope > .vc_row' );
			this.isContentStretched = this.megamenuRowsWrap.classList.contains( 'container-fluid' );
			this.isFullwidth = this.element.classList.contains( 'megamenu-fullwidth' );
			this.windowWidth = fastdom.measure( liquidWindowWidth )();
			this.columnsWidth = 0;
			this.defaultSidePadding = liquidHeaderIsElementor ? 0 : 15;
			this.positionApplied = false;

			this.dropdownInfoPromise = new Promise( resolve => {
				this.element.addEventListener( 'megamenu-position-applied', async () => {
					resolve( {
						element: this.element,
						dropdown: this.submenu,
						elementBoundingRect: this.elementBoundingRect,
						megamenuBoundingRect: this.megamenuBoundingRect || await this.getMegamenuBoundingRect(),
						megamenuFinalPos: this.megamenuFinalPos
					} );
				} );
			} );

			this.init();

		}

		init() {

			this.isInCustomMenu && this.$customMenuParent.css( 'position', 'static' )

			if (
				this.isInCustomMenu &&
				!this.$customMenuParent.hasClass( 'lqd-custom-menu-mobile-collapsible' ) &&
				this.$element.closest( 'ul' ).siblings( '.lqd-custom-menu-dropdown-btn' ).length &&
				!this.$element.closest( 'ul' ).hasClass( 'is-active' )
			) {
				this.$element.closest( 'ul' ).on( 'shown.bs.collapse', this.sizing.bind( this ) );
			} else {
				this.sizing();
			}

		}

		async sizing() {

			if ( this.positionApplied ) return;

			// on elementor we'll use section width
			if ( !this.isFullwidth && !liquidHeaderIsElementor ) {
				await this.getColumnsWidth();
				await this.setContainerWidth();
			}

			// if ( liquidHeaderIsElementor ) {
			// 	await this.getParentOffsetLeft();
			// }

			this.elementBoundingRect = await this.getElementBoundingRect();
			this.megamenuBoundingRect = await this.getMegamenuBoundingRect();

			this.positioning();

			this.resizeWindow();

			if ( this.isContentStretched ) {
				this.element.classList.add( 'megamenu-content-stretch' );
			}

		}

		// getParentOffsetLeft() {

		// 	return new Promise(resolve => {

		// 		new IntersectionObserver(([entry], observer) => {

		// 			const {boundingClientRect} = entry;

		// 			observer.disconnect();

		// 			this.parentOffsetLeft = boundingClientRect.x;

		// 			resolve();

		// 		}).observe(this.element.closest('.elementor-widget-ld_header_menu'))

		// 	})

		// }

		async getColumnsWidth() {

			if ( !this.megamenuRows || liquidHeaderIsElementor ) {
				return 0
			};

			const promises = [];

			this.megamenuRows.forEach( row => {

				const columns = row.querySelectorAll( ':scope > .megamenu-col, :scope > .ld-container > .ld-row > .wpb_column' );

				if ( !columns ) {
					return 0
				};

				const promise = new Promise( resolve => {

					let columnsWidth = 0;

					columns.forEach( async column => {
						const styles = getComputedStyle( column );
						const { paddingLeft, paddingRight } = styles;
						columnsWidth += column.offsetWidth + ( parseInt( paddingLeft, 10 ) + parseInt( paddingRight, 10 ) );
					} );

					resolve( columnsWidth );

				} );

				promises.push( promise );

			} );

			const widths = await Promise.all( promises );

			this.columnsWidth = Math.max( ...widths );

		}

		setContainerWidth() {

			return fastdomPromised.mutate( () => {
				this.megamenuRowsWrap.style.width = `${ this.columnsWidth - ( this.defaultSidePadding * 2 ) }px`;
			} );

		}

		getGlobalContainerDimensions() {

			const windowWidth = this.windowWidth;
			const dimensions = {
				width: 0,
				offsetLeft: 0
			};

			$.each( this.breakpoints, ( containerWidth, windowWidths ) => {

				if ( windowWidth >= windowWidths[ 0 ] && windowWidth <= windowWidths[ 1 ] ) {

					dimensions.width = parseInt( containerWidth, 10 );
					dimensions.offsetLeft = ( windowWidth - containerWidth ) / 2;

				}

			} );

			return dimensions;

		}

		getElementBoundingRect() {

			const rect = {
				width: 0,
				height: 0,
				top: 0,
				left: 0
			};

			return new Promise( resolve => {

				new IntersectionObserver( ( [ entry ], observer ) => {

					const { boundingClientRect } = entry;

					observer.disconnect();

					rect.width = boundingClientRect.width;
					rect.height = boundingClientRect.height;
					rect.top = boundingClientRect.top;
					rect.left = boundingClientRect.left;

					resolve( rect );

				} ).observe( this.element );

			} )

		}

		async getMegamenuBoundingRect() {

			const $carousels = this.$element.find( '[data-lqd-flickity]' );
			const promises = [];
			const rect = {
				width: 0,
				height: 0,
				top: 0,
				left: 0
			};

			if ( $carousels.length ) {
				$carousels.each( ( i, carousel ) => {
					$( carousel ).liquidCarousel( { forceApply: true } )
					const carouselData = $( carousel ).data( 'plugin_liquidCarousel' );
					if ( carouselData ) {
						promises.push( carouselData.carouselInitPromise );
					}
				} )
			}

			if ( promises.length > 0 ) {
				await Promise.all( promises );
			}

			return new Promise( resolve => {

				new IntersectionObserver( ( [ entry ], observer ) => {

					const { boundingClientRect } = entry;

					observer.disconnect();

					rect.width = entry.target.offsetWidth;
					rect.height = entry.target.offsetHeight;
					rect.top = boundingClientRect.top;
					rect.left = boundingClientRect.left;

					resolve( rect );

				} ).observe( this.megamenuRowsWrap );

			} );

		}

		async resetPositioning() {

			await new Promise( resolve => {

				this.windowWidth = liquidWindowWidth();

				this.columnsWidth = 0;

				this.positionApplied = false;
				this.element.classList.remove( 'position-applied' );

				this.megamenuRowsWrap.style.width = '';

				this.submenu.style.width = '';
				this.submenu.style.left = '';
				this.submenu.style.right = '';
				this.submenu.style.top = '';
				this.submenu.style.marginLeft = '';

				resolve();

			} )

		}

		positioning() {

			const elementorMobileBreakpoint =
				window.elementorFrontendConfig &&
				(
					window.elementorFrontendConfig.responsive?.breakpoints?.mobile_extra?.value ||
					window.elementorFrontendConfig.responsive?.breakpoints?.mobile?.value
				);

			if (
				elementorMobileBreakpoint &&
				liquidWindowWidth() < elementorMobileBreakpoint
			) {
				return this.onPositioningDone()
			};

			const elementWidth = this.elementBoundingRect.width;
			const elementOffsetLeft = this.elementBoundingRect.left;
			const megamenuContainerWidth = this.megamenuBoundingRect.width;
			const globalContainerDimensions = fastdom.measure( this.getGlobalContainerDimensions, this )();
			const globalContainerWidth = globalContainerDimensions.width;
			const globalContainerOffsetLeft = globalContainerDimensions.offsetLeft;
			const menuItemisInGlobalContainerRange = elementOffsetLeft <= ( globalContainerWidth + globalContainerOffsetLeft );
			let left = 0;
			let right = 0;
			let top = 0;
			let megamenuOffsetLeft = 0;

			fastdomPromised.mutate( () => {

				if ( !this.isFullwidth ) {

					// just make it center if it fits inside global container
					if ( megamenuContainerWidth === globalContainerWidth && menuItemisInGlobalContainerRange ) {
						left = globalContainerOffsetLeft - this.parentOffsetLeft;
						this.submenu.style.left = `${ left }px`;
					}

					// if the menu item is inside the global container range
					if ( menuItemisInGlobalContainerRange ) {
						left = ( globalContainerOffsetLeft ) + ( ( globalContainerWidth / 2 ) - ( megamenuContainerWidth / 2 ) ) - this.parentOffsetLeft;
						this.submenu.style.left = `${ left }px`;
						megamenuOffsetLeft = left;
					}

					// if the megammenu is pushed too much to the right and it's far from it's parent menu item
					if ( megamenuOffsetLeft > elementOffsetLeft ) {
						left = elementOffsetLeft - this.parentOffsetLeft;
						this.submenu.style.left = `${ left }px`;
					}

					// if the megamenu needs to push a bit more to the right
					if ( ( megamenuOffsetLeft + megamenuContainerWidth ) < ( elementOffsetLeft + elementWidth ) ) {
						left = ( ( elementOffsetLeft + elementWidth ) - ( megamenuOffsetLeft + megamenuContainerWidth ) ) + megamenuOffsetLeft - this.parentOffsetLeft;
						this.submenu.style.left = `${ left }px`;
					}

					this.megamenuFinalPos.left = left;

				}

				if ( this.isInCustomMenu ) {

					const elementOffsetTop = this.elementBoundingRect.top;
					const elementHeight = this.elementBoundingRect.height;
					const megamenuOffsetTop = this.megamenuBoundingRect.top;
					const megamenuHeight = this.megamenuBoundingRect.height;

					// megamenu is short or the height doesn't reach the trigger
					if ( elementOffsetTop + elementHeight > megamenuOffsetTop + megamenuHeight ) {
						top = elementOffsetTop - megamenuOffsetTop;
					}

					// we can push the megamenu more to top. but it's causing missalignment in some cases
					// if (
					// 	megamenuHeight + megamenuOffsetTop + parseInt(this.$submenu.css('top'), 10) >
					// 	customMenuElHeight + custommenuOffset.top
					// ) {
					// 	this.$submenu.css({
					// 		top: customMenuElHeight - megamenuHeight
					// 	})
					// }

					this.submenu.style.top = `${ top }px`;
					this.megamenuFinalPos.top = top;

					if ( this.isFullwidth ) {

						// if it's pushed to the outside of the viewport
						if ( ( this.megamenuBoundingRect.left + megamenuContainerWidth ) > this.windowWidth ) {
							right = ( this.windowWidth - ( elementOffsetLeft + elementWidth ) ) * -1;
							this.submenu.style.width = 'auto';
							this.submenu.style.right = `${ right }px`;
							this.megamenuFinalPos.right = right;
						}

					}

				}

				this.onPositioningDone();

			} )

		}

		onPositioningDone() {

			this.positionApplied = true;
			this.element.classList.add( 'position-applied' );
			this.element.dispatchEvent( new CustomEvent( 'megamenu-position-applied', { bubbles: false, detail: { element: this.element } } ) );

		}

		resizeWindow() {

			const onResize = liquidDebounce( this.onResizeWindow.bind( this ), 300 );

			$( window ).on( 'resize', onResize );

			$( document ).on( 'lqd-header-sticky-change', () => {
				if (
					this.$element.is( ':visible' ) &&
					// if it's in custom menu and in main header
					this.isInCustomMenu &&
					this.$element.closest( '.main-header' ).length
				) {
					onResize();
				}
			} );

		}

		async onResizeWindow() {

			await this.resetPositioning();

			if ( !this.isFullwidth && !liquidHeaderIsElementor ) {

				await this.getColumnsWidth();
				await this.setContainerWidth();

			}

			this.elementBoundingRect = await this.getElementBoundingRect();
			this.megamenuBoundingRect = await this.getMegamenuBoundingRect();

			this.positioning();

		}

	}


	$.fn[ pluginName ] = function ( options ) {

		return this.each( function () {

			const pluginOptions = { ...$( this ).data( 'megamenu-options' ), ...options };

			if ( !$.data( this, "plugin_" + pluginName ) ) {
				$.data( this, "plugin_" + pluginName, new Plugin( this, pluginOptions ) );
			}

		} );

	};

}( jQuery ) );

jQuery( document ).ready( function ( $ ) {

	$( '.megamenu' ).filter( ( i, el ) => {
		const $el = $( el );
		const isInMobileNav = $el.parent().hasClass( 'lqd-mobile-main-nav' );
		if ( isInMobileNav ) {
			el.classList.add( 'position-applied' );
		}
		return (
			!$el.closest( '.navbar-fullscreen' ).length &&
			!( $el.closest( '.main-header' ).length && $liquidBody.hasClass( 'header-style-side' ) ) &&
			!isInMobileNav &&
			!$el.parent().hasClass( 'lqd-menu-items-block' )
		);
	} ).liquidMegamenu();

} );