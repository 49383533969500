(function ( $ ) {
	
	'use strict';
	
	const pluginName = 'liquidRowBG';
	let defaults = {
	};
	
	class Plugin {
		
		constructor(element, options) {

			this._defaults = defaults;
			this._name = pluginName;
			
			this.options = {...defaults, ...options};
			
			this.element = element;
			this.$element = $(element);

			this.bgUrl = this.element.getAttribute('data-row-bg');
			this.hasBgMarkup = this.element.classList.contains('lqd-has-bg-markup');

			this.rowBgInitPromise = new Promise(resolve => {
				this.$element.on('lqdrowbginit', resolve(this))
			});

			this.build();
			
		}

		async build() {

			if ( ! this.hasBgMarkup ) {

				await this._createElements();
				await this._addBgElement();

			} else {

				fastdomPromised.mutate(() => {

					this.bgWrap = this.element.querySelector('.row-bg-wrap');
					this.rowBg = this.bgWrap.querySelector('.row-bg');

				})

			}
			
			if ( liquidLazyloadEnabled ) {
				return new IntersectionObserver(([entry], observer) => {
					if (entry.isIntersecting) {
						observer.disconnect();
						this.initLoading();
					}
				}, {rootMargin: '500px'}).observe(this.element);
			}

			this.initLoading();

		}

		async initLoading() {

			await this._addBg();

			if ( this.element.hasAttribute('data-parallax') && ! liquidIsMobile() ) {
				this.element.addEventListener('lqd-parallax-initiated', this._imagesLoaded.bind(this));
			} else {
				this._imagesLoaded();
			}

		}
		
		_createElements() {

			return fastdomPromised.mutate(() => {

				const elements = [
					{
						'is': 'rowBg',
						'classname': 'row-bg',
						'tag': 'figure'
					},
					{
						'classname': 'row-bg-inner',
						'tag': 'div',
						'append': '.row-bg'
					},
					{
						'classname': 'row-bg-wrap bg-not-loaded',
						'tag': 'div',
						'append': '.row-bg-inner'
					},
				];
				const docFrag = new DocumentFragment();
	
				elements.forEach(element => {
					const el = document.createElement(element.tag);
					el.setAttribute('class', element.classname);
					docFrag.appendChild(el);
					if ( element.append ) {
						el.appendChild(docFrag.querySelector(element.append))
					}
					if ( element.is ) {
						this[element.is] = el;
					}
				});
	
				this.bgWrap = docFrag.querySelector('.row-bg-wrap');

			})

		}
		
		_addBg() {
			
			return fastdomPromised.mutate(() => {

				this.rowBg.style.backgroundImage = `url(${this.bgUrl})`;

			})		
			
		}
		
		_addBgElement() {

			return fastdomPromised.mutate(() => {

				let $loader = this.$element.children('.row-bg-loader');
				let $insertPlace = $loader;
				let appendingMethod = 'insertAfter';
				
				if ( this.$element.children('.lqd-sticky-bg-spacer').length ) {
					$insertPlace = this.$element.children('.lqd-sticky-bg-spacer');
					appendingMethod = 'appendTo';
				}
				if ( this.$element.hasClass('vc_column_container') ) {
					$insertPlace = this.$element.find('> .vc_column-inner > .row-bg-loader');
				}

				$insertPlace.siblings('.row-bg-wrap').remove();
				$insertPlace.find('.row-bg-wrap').remove();
				
				$(this.bgWrap)[appendingMethod]($insertPlace);

			})
			
		}
		
		_imagesLoaded() {

			fastdomPromised.mutate(() => {

				imagesLoaded(this.rowBg, { background: true }, this._onImagesLoaded.bind(this));

			})
			
		}
		
		_onImagesLoaded() {

			fastdomPromised.mutate(() => {
					
				this.element.classList.remove('row-bg-loaded');
				this.bgWrap.classList.remove('bg-not-loaded');
				
				this.bgWrap.classList.add('bg-loaded');
				this.element.classList.add('row-bg-loaded');

				this._onRowBgInit();

			})

		}

		_onRowBgInit() {

			this.$element.trigger('lqdrowbginit', this.element);

		}
		
	}
	
	$.fn[pluginName] = function ( options ) {
		
		return this.each( function() {

			const pluginOptions = {...$(this).data('row-bg-options'), ...options};

			if ( ! $.data( this, "plugin_" + pluginName ) ) {
				$.data( this, "plugin_" + pluginName, new Plugin( this, pluginOptions ) );
			}
			
		} );

	};
	
}(jQuery));

jQuery(document).ready( function($) {

	$('[data-row-bg]:not([data-slideshow-bg])').liquidRowBG();

});