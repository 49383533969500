(function ( $ ) {
	
	'use strict';
	
	const pluginName = 'liquidCarouselFalcate';
	let defaults = {
	};
	
	class Plugin {
		
		constructor(element, options) {
			
			this.element = element;
			this.$element = $(element);
			
			this.options = $.extend({}, defaults, options);
			this._defaults = defaults;
			this._name = pluginName;
			
			this.ms = null;
			this.activeItems = [0, 1, 2];
			this.loopCount = 2;
			this.$carouselItems = $('.carousel-items', this.element);
			this.$carouselCells = [];

			this.isMobile = liquidIsMobile();

			if ( this.$carouselItems.length ) {
				this.onImagesLoaded();
			}
		}
		
		onImagesLoaded() {

			imagesLoaded(this.element, () => {

				if ( ! this.isMobile ) {

					this.addCarouselCellsDetails();
					this.initFalcateCarousel();

				} else {

					this.$element.addClass('carousel-falcate-mobile');

					this.initFlickityCarousel();

				}

			});

		}

		addCarouselCellsDetails() {

			
			$.each( $('.carousel-item', this.element), (i, el) => {

				const obj = {};
				const $el = $(el);

				obj.el = el;
				obj.width = $el.outerWidth(true);
				obj.height = $el.outerHeight(true);

				this.$carouselCells.push(obj);

			} );

			this.$carouselItems.hide();

		}
		
		initFalcateCarousel() {
			
			this.ms = new MomentumSlider({
				el: this.element,
				multiplier: 0.6,
				range: [0, this.$carouselCells.length - 1],
				vertical: true,
				loop: this.loopCount,
				currentIndex: this.activeItems[1],
				rangeContent: i => $(this.$carouselCells[i].el).html(),
				style: {
					transform: [{
						translateX: [0, -100],
					}]
				},
				customStyles: (index, diff, lower) => {
					this.customStyles(index, diff, lower);
					this.updateContainerHeight(index, diff, lower);
				},
				change: () => {
					this.fadeInActiveItems();
					// this.fadeOutInactiveItems(oldIndex, newIndex);
					this.updateContainerHeight();
				}
			});

			if ( $('body').hasClass('lazyload-enabled') && window.liquidLazyload ) {
				window.liquidLazyload.update();
			}

		}

		fadeInActiveItems() {

			if ( ! this.ms ) {

				const $msContainer = this.$element.find('.ms-container');
				const $msSlides = $('.ms-slide', $msContainer);

				this.activeItems.forEach( i => {
					const activeItemIndex = i + this.loopCount;
					$($msSlides[activeItemIndex]).css('opacity', 1);
				} );

			}

		}

		fadeOutInactiveItems(oldIndex) {

			if ( ! this.ms ) return false;

			const prevItemIndex = (oldIndex + this.loopCount) - 1;
			const activeItemIndex = oldIndex + this.loopCount;
			const nextItemIndex = (oldIndex + this.loopCount) + 1;

			$(this.ms.msSlides).each( (i, msSlide) => {

				const $msSlide = $(msSlide);

				$msSlide.addClass('ms-slide-inactive');

				[prevItemIndex, activeItemIndex, nextItemIndex].map( index => {
					
					i === index && $msSlide.removeClass('ms-slide-inactive')
					
				});

			} );

		}

		updateContainerHeight(index, diff) {

			if ( ! this.ms ) {

				const $msContainer = this.$element.find('.ms-container');
				const $msSlides = $('.ms-slide', $msContainer);
				const $firstSlide = $($msSlides[this.activeItems[0 + this.loopCount]]);
				let height = 0;

				this.activeItems.forEach( i => {
					const activeItemIndex = i + this.loopCount;
					height += $($msSlides[activeItemIndex]).outerHeight();
				} );

				$msContainer.css({
					height,
					transform: `translateY(${$firstSlide.outerHeight()}px)`
				});

				return false;

			}

			const {msSlides} = this.ms;
			let height = 0;
			
			$.each( [$(msSlides[index - 1]), $(msSlides[index]), $(msSlides[index + 1])], (i, msSlide) => {
				height += $(msSlide).outerHeight();
			} );

			height = Math.round((Math.abs(diff) - height) / -1);

			this.$element.height( height );

		}

		customStyles(index, diff, lower) {

			if ( ! this.ms ) return false;

			const {msSlides} = this.ms;
			const opacityVal = (Math.abs(diff) - 1) / -1;

			$(msSlides[index]).css({
				opacity: 1
			});
			
			if ( lower ) {
				
				const $prevItem = $(msSlides[index - 1]);
				const translateVal = Math.round((diff - $prevItem.outerHeight()) / -1);

				$prevItem.css({
					opacity: opacityVal
				});
				
				$(this.ms.msContainer).css({
					transform: `translateY(${translateVal}px)`
				})
				
			}
			
			if ( ! lower ) {
				
				const $nextItem = $(msSlides[index + 1]);
				const $prevItem = $(msSlides[index - 1]);
				const translateVal = Math.round((Math.abs(diff) - $prevItem.outerHeight()) / -1);

				$nextItem.css({
					opacity: opacityVal
				});

				$(this.ms.msContainer).css({
					transform: `translateY(${translateVal}px)`
				})

			}

		}

		initFlickityCarousel() {

			this.$carouselItems.liquidCarousel({
				pageDots: true
			});

		}
		
	}
	
	$.fn[ pluginName ] = function( options ) {
		
		return this.each( function() {
			
			const pluginOptions = {...$(this).data('carousel-options'), ...options};
			
			if ( !$.data( this, "plugin_" + pluginName ) ) {
				$.data( this, "plugin_" + pluginName, new Plugin( this, pluginOptions ) );
			}
			
		} );
		
	};
	
}(jQuery));

jQuery(document).ready( function($) {
	$('.carousel-falcate').liquidCarouselFalcate();
});