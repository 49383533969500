(function ( $ ) {
	
	'use strict';
	
	const pluginName = 'liquidInView';
	let defaults = {
		delayTime: 0,
		onImagesLoaded: false,
		// 'stay', 'toggleOutOfView', 'toggleInView'
		toggleBehavior: 'stay'
	};
	
	class Plugin {

		constructor(element, options) {

			this._defaults = defaults;
			this._name = pluginName;
			
			this.options = $.extend({}, defaults, options);

			this.element = element;
			this.$element = $(element);
			this.$sentinel = null;

			this.isVisible = false;
			this.imagesAlreadyLoaded = false;
			this.isFixedPos = fastdom.measure(() => this.$element.css('position') === 'fixed')();
			this.windowWidth = fastdom.measure(liquidWindowWidth)();
			this.windowHeight = fastdom.measure(liquidWindowHeight)();
			
			this.initIO();

		}

		initIO() {

			const { toggleBehavior } = this.options;

			new IntersectionObserver(([entry], observer) => {

				fastdomPromised.measure(() => {
					
					return {
						boundingClientRect: entry.boundingClientRect,
						scrollY: window.scrollY
					}

				}).then(({boundingClientRect, scrollY}) => {

					fastdom.mutate(() => {
	
						if ( toggleBehavior === 'toggleInView' ) {
		
							if ( scrollY + this.windowHeight >= boundingClientRect.top + scrollY ) {
								this.isVisible = true;
								this.callFns();
							} else if ( scrollY <= boundingClientRect.bottom + scrollY ) {
								this.isVisible = false;
								this.callFns();
							}
		
						}
		
						if ( entry.isIntersecting && toggleBehavior === 'stay' ) {
		
							observer.disconnect();
							
							this.isVisible = true;
							this.callFns();
		
						} else if ( ! entry.isIntersecting && toggleBehavior === 'toggleOutOfView' ) {
		
							this.onOutOfView();
		
						}
	
					});

				});

			}, {threshold: toggleBehavior === 'toggleInView' ? [0, 0.25, 0.5, 0.75, 1] : [0]}).observe(! this.isFixedPos ? this.element : this.$element.parent()[0]);

		}

		callFns() {

			if ( ! this.options.onImagesLoaded && ! this.imagesAlreadyLoaded ) {
				this.run();
			} else {
				imagesLoaded(this.element, () => {
					this.imagesAlreadyLoaded = true;
					this.run();	
				});
			}

		}

		run() {

			const { delayTime } = this.options;

			delayTime <= 0 ?
				this.onInView() :
				this.timeoutId = setTimeout(this.onInView.bind(this), delayTime);

		}

		onInView() {

			this.$element.toggleClass('is-in-view', this.isVisible);

			clearTimeout(this.timeoutId);

		}

		onOutOfView() {

			const { toggleBehavior } = this.options

			if ( toggleBehavior === 'toggleOutOfView' ) {
				this.isVisible = false;
			}

			if ( ! this.isVisible ) {
				this.$element.removeClass('is-in-view');
			}

		}

	}
	
	
	$.fn[pluginName] = function (options) {

		return this.each( function() {

			const pluginOptions = $(this).data('inview-options') || options;
			
			if ( ! $.data( this, "plugin_" + pluginName ) ) {
				$.data( this, "plugin_" + pluginName, new Plugin( this, pluginOptions ) );
			}
			
		} );

	};
	
}(jQuery));

jQuery(document).ready( function($) {

	if ( ! $liquidContents.length ) {
		return $('[data-inview]').liquidInView();
	};

	const init = () => {
		if ( $liquidBody.hasClass('lqd-preloader-activated') ) {
			document.addEventListener('lqd-preloader-anim-done', () => {
				$('[data-inview]').liquidInView();
			});
		} else {
			$('[data-inview]').liquidInView();
		}	
	}
	
	if ( ! $liquidContents[0].hasAttribute('data-liquid-stack')  ) {
		init();
	} else {
		const stackOptions = $liquidContents.attr('data-stack-options');
		if ( stackOptions ) {
			const optionsJson = JSON.parse(stackOptions);
			const {disableOnMobile} = optionsJson;
			if ( disableOnMobile && ( liquidIsMobile() || liquidWindowWidth() <= liquidMobileNavBreakpoint() ) ) {
				init();
			}
		}
	}

});