( function ( $ ) {

	'use strict';

	const pluginName = 'liquidBackToTop';
	let defaults = {
	};

	class Plugin {

		constructor( element, options ) {

			this._defaults = defaults;
			this._name = pluginName;

			this.options = { ...defaults, ...options };

			this.element = element;
			this.$element = $( element );


			this.init();

		}

		init() {

			this.checkforWPBottomMenu();
			this.firstSectionIO();

		}

		checkforWPBottomMenu() {

			const wpbm = document.getElementById( 'wp-bottom-menu' );

			if ( !wpbm ) return;

			const wpbmHeight = wpbm.clientHeight;
			this.element.style.bottom = `${ wpbmHeight + 30 }px`;

		}

		firstSectionIO() {

			let $firstSection = $liquidSectionsWrapper.children().not( 'style, p' ).first();

			// content coming from classic editor
			if ( $firstSection.hasClass( 'lqd-contents' ) ) {
				$firstSection = $firstSection.children().first();
			}

			const firstSectionIsSticky = $firstSection.css( 'position' ) === 'sticky';
			const firstSectionIsOnlyChild = $firstSection.is( ':only-child' );

			if ( $firstSection.is( ':hidden' ) || firstSectionIsSticky ) {
				$firstSection = $firstSection.siblings().not( 'style, p' ).first();
			}

			if ( !$firstSection.length ) return;

			let threshold = [ 0, 0.25, 0.5, 0.75, 1 ];

			if ( firstSectionIsSticky ) {
				threshold = [ 0 ];
			}
			if ( firstSectionIsOnlyChild ) {
				threshold = [ 0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1 ];
			}

			new IntersectionObserver( ( [ entry ] ) => {

				const { boundingClientRect, rootBounds } = entry;
				let activate = rootBounds && rootBounds.top >= ( boundingClientRect.bottom - ( boundingClientRect.height / 2 ) );

				if ( firstSectionIsSticky ) {
					activate = rootBounds && rootBounds.bottom >= boundingClientRect.top;
				}
				if ( firstSectionIsOnlyChild ) {
					activate = rootBounds && ( rootBounds.bottom - ( rootBounds.height / 2 ) ) >= boundingClientRect.top + 250;
				}

				if ( activate ) {
					this.$element.addClass( 'is-visible' );
				} else {
					this.$element.removeClass( 'is-visible' );
				}

			}, { threshold } ).observe( $firstSection.get( 0 ) );

		}

	}

	$.fn[ pluginName ] = function ( options ) {

		return this.each( function () {

			const pluginOptions = { ...$( this ).data( 'back-to-top-options' ), ...options };

			if ( !$.data( this, "plugin_" + pluginName ) ) {
				$.data( this, "plugin_" + pluginName, new Plugin( this, pluginOptions ) );
			}

		} );

	};

}( jQuery ) );

jQuery( document ).ready( function ( $ ) {

	if ( !$( 'html' ).hasClass( 'pp-enabled' ) ) {
		$( '[data-back-to-top]' ).liquidBackToTop();
	}

} );