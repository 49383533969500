(function ( $ ) {
	
	'use strict';
	
	const pluginName = 'liquidSlideshowBG';
	let defaults = {
		effect: 'fade', // 'fade', 'slide', 'scale'
		delay: 3000,
		imageArray: []
	};
	
	class Plugin {
		
		constructor(element, options) {

			this._defaults = defaults;
			this._name = pluginName;
			
			this.options = {...defaults, ...options};
			
			this.element = element;
			this.$element = $(element);

			this.slideshowBgInitPromise = new Promise(resolve => {
				this.$element.on('lqdslideshowinit', resolve.bind(this, this))
			});

			this.init();
			
		}
		
		init() {

			const markup = this._addMarkup();
			
			this.imageArray = this.options.imageArray;
			this.slideshowWrap = markup.slideshowWrap;
			this.slideshowInner = markup.slideshowInner;
			
			this._addImages();
			this._initSlideShow();
			this._onImagesLoaded();
			
		}
		
		_addMarkup() {
			
			const slideshowWrap = $('<div class="ld-slideshow-bg-wrap" />');
			const slideshowInner = $('<div class="ld-slideshow-bg-inner" />');
			const loader = $('<span class="row-bg-loader" />');
			
			slideshowWrap.append(slideshowInner);
			slideshowWrap.append(loader);
			this.$element.prepend(slideshowWrap);
			
			return {
				slideshowWrap,
				slideshowInner
			};
			
		}
		
		_addImages() {	

			$.each( this.imageArray, (i, {src, alt}) => {
				
				const $img = $(`<img class="invisible" src="${src}" alt="${alt || 'Slideshow image'}"/>`);
				const $figure = $(`<figure class="ld-slideshow-figure" style="background-image: url(${src})" />`);
				const $slideshowItem = $('<div class="ld-slideshow-item" />');
				const $slideshowItemInner = $('<div class="ld-slideshow-item-inner" />');

				$figure.append($img);
				$slideshowItemInner.append($figure);
				$slideshowItem.append($slideshowItemInner);

				this.slideshowInner.append($slideshowItem);
				
			} );
			
		}
		
		_initSlideShow() {
			
			this.slideshowInner.children(':first-child').addClass('active');
			this.slideshowInner.children().not(':first-child').css({
				opacity: 0
			});
			
		}

		_onImagesLoaded() {

			imagesLoaded(this.slideshowInner.children().first().get(0), () => {

				this.$element.addClass('slideshow-applied');

				this._initSlideshowAnimations();
				this._onSlideshowInit();
				
			} );

		}
		
		_getCurrentSlide() {
			
			return this.slideshowInner.children('.active');
			
		}
		
		_getAllSlides() {
			
			return this.slideshowInner.children();
			
		}
		
		_setActiveClassnames(element) {
			
			$(element).addClass('active').siblings().removeClass('active');
			
		}
		
		_getNextSlide() {
			
			return ! this._getCurrentSlide().is(':last-child') ? this._getCurrentSlide().next() : this.slideshowInner.children(':first-child');
			
		}
		
		/*
			getting animation style from this.options
			and having the same function names. fade(); slide(); scale();
		*/
		_initSlideshowAnimations() {
			
			this[this.options.effect]();
			
		}
		
		_setWillChange(changingProperties) {
			
			const slides = this._getAllSlides();
			
			slides.css({
				willChange: changingProperties.join(', ')
			});
			
		}
		
		// START FADE ANIMATIONS
		fade() {
			
			// this._setWillChange(['opacity']);
			imagesLoaded([this._getCurrentSlide(), this._getNextSlide()], () => this._fadeOutCurrentSlide() );
			
		}
		_fadeOutCurrentSlide() {

			gsap.to(this._getCurrentSlide().get(0), {
				startAt: {
					opacity: 1
				},
				opacity: 0,
				duration: 1,
				delay: parseInt(this.options.delay, 10) / 1000,
				ease: 'power1.in',
				onStart: () => {
					this._fadeInNextSlide();
				}
			});

		}
		_fadeInNextSlide() {
			
			const nextSlide = this._getNextSlide().get(0);

			gsap.to(nextSlide, {
				startAt: {
					opacity: 0
				},
				opacity: 1,
				duration: 1,
				ease: 'power1.inOut',
				onComplete: () => {
					this._setActiveClassnames(nextSlide);
					this._fadeOutCurrentSlide();
				}
			});
			
		}
		// END FADE ANIMATIONS
		
		// START SLIDING EFFECT
		slide() {
			
			// this._setWillChange(['opacity', 'transform']);
			imagesLoaded([this._getCurrentSlide(), this._getNextSlide()], () => this._slideOutCurrentSlide() );
			
		}
		_slideOutCurrentSlide() {

			const currentSlide = this._getCurrentSlide().get(0);
			const inner = $(currentSlide).children().get(0);
			const figure = $(inner).children().get(0);

			// $(figure).add(inner).addClass('will-change');

			gsap.timeline({
				delay: parseInt(this.options.delay, 10) / 1000,
			})
			.to(currentSlide, {
				startAt: {
					x: '0%'
				},
				x: '-100%',
				duration: 1,
				ease: 'power4.inOut',
			}, 0)
			.to(inner, {
				startAt: {
					x: '0%'
				},
				x: '100%',
				duration: 1,
				ease: 'power4.inOut',
			}, 0)
			.to(figure, {
				startAt: {
					scale: 1
				},
				scale: 1.2,
				duration: 1,
				ease: 'power3.inOut',
				onStart: () => {
					this._slideInNextSlide();
				}
			}, 0);
			
		}
		_slideInNextSlide() {

			const $nextSlide = this._getNextSlide();
			const nextSlide = $nextSlide.get(0);
			const inner = $nextSlide.children().get(0);
			const figure = $(inner).children().get(0);

			// $(figure).add(inner).addClass('will-change');

			gsap.timeline({
				onComplete: () => {
					this._slideOutCurrentSlide();
					// $(figure).add(inner).removeClass('will-change');
				}
			})
			.to(nextSlide, {
				startAt: {
					x: '100%',
					opacity: 1
				},
				x: '0%',
				duration: 0.85,
				ease: 'power4.inOut'
			}, 0)
			.to(inner, {
				startAt: {
					x: '-100%',
					opacity: 1
				},
				x: '0%',
				duration: 0.85,
				ease: 'power4.inOut'
			}, 0)
			.to(figure, {
				startAt: {
					scale: 1.2
				},
				scale: 1,
				duration: 1.65,
				ease: 'power3.out',
				onStart: () => {
					this._setActiveClassnames(nextSlide);
				}
			}, 0);
			
		}
		// END SLIDING ANIMATIONS
		
		// START SCALE EFFECT
		scale() {
			
			// this._setWillChange(['opacity', 'transform']);
			imagesLoaded([this._getCurrentSlide(), this._getNextSlide()], () => this._scaleUpCurrentSlide() );
			
		}
		_scaleUpCurrentSlide() {

			gsap.to(this._getCurrentSlide().get(0), {
				startAt: {
					scale: 1,
					opacity: 1,
					zIndex: 0
				},
				scale: 1.2,
				opacity: 0,
				duration: 0.9,
				ease: 'power4.inOut',
				delay: parseInt(this.options.delay, 10) / 1000,
				onStart: () => {
					this._scaleDownNextSlide();
				}
			});
			
		}
		_scaleDownNextSlide() {

			const nextSlide = this._getNextSlide().get(0);
			
			gsap.to(nextSlide, {
				startAt: {
					scale: 1.2,
					opacity: 0,
					zIndex: 1
				},
				scale: 1,
				opacity: 1,
				duration: 0.9,
				ease: 'power4.inOut',
				onComplete: () => {
					this._setActiveClassnames(nextSlide);
					this._scaleUpCurrentSlide();
				}
			});
			
		}
		// END SCALE ANIMATIONS

		_onSlideshowInit() {

			this.$element.trigger('lqdslideshowinit', this.element);

		}
		
	}
	
	$.fn[ pluginName ] = function( options ) {
	
		return this.each( function() {
	
			const pluginOptions = {...$(this).data('slideshow-options'), ...options};
	
			if ( !$.data( this, "plugin_" + pluginName ) ) {
				$.data( this, "plugin_" + pluginName, new Plugin( this, pluginOptions ) );
			}
	
		} );
	
	};
	
}(jQuery));

jQuery(document).ready( function($) {

	$('[data-slideshow-bg]').liquidSlideshowBG();

});