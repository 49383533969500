( function ( $ ) {

	'use strict';

	const pluginName = 'liquidVideoTrigger';
	let defaults = {
		triggerType: [ 'mouseenter', 'mouseleave' ], // [on, off]
		videoPlacement: "parent",
		loop: false
	};

	class Plugin {

		constructor( element, options ) {

			this.element = element;
			this.$element = $( element );

			this.options = $.extend( {}, defaults, options );

			this._defaults = defaults;
			this._name = pluginName;

			this.videoElement = this.$element[ this.options.videoPlacement ]().find( 'video' ).get( 0 );

			this.init();

		}

		init() {

			this.videoElement.oncanplay = this.events.call( this );

		}

		events() {

			this.$element.on( this.options.triggerType[ 0 ], this.triggerOn.bind( this ) );
			this.$element.on( this.options.triggerType[ 1 ], this.triggerOff.bind( this ) );

		}

		triggerOn() {

			if ( this.options.loop ) {
				this.videoElement.loop = true;
				this.videoElement.currentTime = 0;
			}

			this.videoElement.play();


		}

		triggerOff() {

			this.videoElement.pause();

		}

	}

	$.fn[ pluginName ] = function ( options ) {

		return this.each( function () {

			const pluginOptions = $( this ).data( 'trigger-options' ) || options;

			if ( !$.data( this, "plugin_" + pluginName ) ) {
				$.data( this, "plugin_" + pluginName, new Plugin( this, pluginOptions ) );
			}

		} );

	};

}( jQuery ) );

jQuery( document ).ready( function ( $ ) {
	$( '[data-video-trigger]' ).liquidVideoTrigger();
} );