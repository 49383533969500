(function ( $ ) {
	
	'use strict';
	
	const pluginName = 'liquidStickyFooter';
	let defaults = {
		shadow: 0,
		parallax: false,
	};
	
	class Plugin {

		constructor(element, options) {

			this._defaults = defaults;
			this._name = pluginName;

			this.options = {...defaults, ...options};

			this.element = element;
			this.$element = $(element);
			this.footerHeight = 0;
			this.windowWidth = fastdom.measure(() => window.innerWidth)();
			this.windowHeight = fastdom.measure(() => window.innerHeight)();

			this.init();

		}

		measure() {

			return fastdomPromised.measure(() => {

				if ( this.windowWidth < 768 ) {

					this.footerHeight = 0;

				} else {

					this.footerHeight = this.element.offsetHeight - 2;
					this.windowWidth = window.innerWidth;
					this.windowHeight = window.innerHeight;

				}

			});

		}
			
		init() {
			
			imagesLoaded(this.element, async () => {
				
				await this.measure();
				this.addMargin();
				this._addShadow();
				this._handleResize();

			} );
	
		}

		addMargin() {

			fastdomPromised.mutate(() => {

				if (this.footerHeight >= this.windowHeight) {
					return this.$element.addClass('lqd-footer-cant-stick');
				}

			})

		}

		_addShadow() {

			const { shadow } = this.options;

			if ( shadow > 0 ) {
				document.body.classList.add(`lqd-sticky-footer-shadow-${shadow}`);
			}

		}

		_handleResize() {

			const onResize = liquidDebounce( this._onResize.bind(this), 400 );
			
			$liquidWindow.on('resize', onResize );

		}

		async _onResize() {
			
			await this.measure();
			this.addMargin();

		}
		
	}
	
	$.fn[ pluginName ] = function( options ) {
		
		return this.each( function() {
			
			const pluginOptions = {...$(this).data('sticky-footer-options'), ...options};
			
			if ( !$.data( this, "plugin_" + pluginName ) ) {
				$.data( this, "plugin_" + pluginName, new Plugin( this, pluginOptions ) );
			}
			
		} );
		
	};
	
}(jQuery));

jQuery(document).ready( function($) {

	if ( liquidIsMobile() ) return;

	$('[data-sticky-footer=true]').liquidStickyFooter();
	
});