( function ( $ ) {

	'use strict';

	const pluginName = 'liquidParallax';
	let defaults = {
		start: 'top bottom',
		end: 'bottom top',
		ease: 'linear',
		scrub: 0.55,
		parallaxBG: false,
		scaleBG: true,
		overflowHidden: false,
		startTrigger: null,
		parallaxTargets: null,
		skipWillChange: false
	};
	let defaultParallaxFrom = {
	};
	let defaultParallaxTo = {
	};

	class Plugin {

		constructor( element, options, parallaxFrom, parallaxTo ) {

			this._defaults = defaults;
			this._name = pluginName;

			this.options = { ...defaults, ...options };

			this.element = element;
			this.$element = $( element );

			this.parallaxFromOptions = { ...defaultParallaxFrom, ...parallaxFrom };
			this.parallaxToOptions = { ...defaultParallaxTo, ...parallaxTo };

			this.ST = null;
			this.parallaxTimeline = null;

			this.parallaxElements = [];
			this.isRowBg = this.element.getAttribute( 'data-row-bg' );
			this.rect = {};
			this.bgImg = null;
			this.sentinel = null;
			this.parallaxFigure = null;
			this.parallaxMarkupExists = this.element.classList.contains( 'lqd-parallax-markup-exists' );

			const promises = [];

			if (
				this.$element.hasClass( 'lqd-css-sticky' ) &&
				this.$element.data( 'plugin_liquidStickyRow' )
			) {
				const data = this.$element.data( 'plugin_liquidStickyRow' );
				const promise = data.rowStickyInitPromise;
				promise && promises.push( promise );
			}

			if ( this.element.hasAttribute( 'data-split-text' ) ) {
				this.$element.liquidSplitText( { forceApply: true } );
				const prom = this.$element.data( 'plugin_liquidSplitText' );
				prom && promises.push( prom.splitDonePormise );
			}

			if ( promises.length > 0 ) {
				Promise.all( promises ).then( this.build.bind( this ) );
			} else {
				this.build();
			}

		}

		async build() {

			await this.handleSentinel();
			await this.buildParallaxMarkups();

			this.parallaxElements = this.getParallaxElements();

			new IntersectionObserver( ( [ entry ], observer ) => {
				if ( entry.isIntersecting ) {
					observer.disconnect();
					this.init();
				}
			}, { rootMargin: "50%" } ).observe( this.element );

		}

		getParallaxElements() {

			if ( this.options.parallaxTargets ) {
				return [ ...this.element.querySelectorAll( this.options.parallaxTargets ) ]
			} else if ( this.element.classList.contains( 'vc_column_container' ) ) {
				return [ this.element.querySelector( '.vc_column-inner' ) ];
			} else if ( this.options.parallaxBG ) {
				return [ this.parallaxFigure ];
			} else {
				return [ this.element ];
			}

		}

		measure() {

			return new Promise( resolve => {

				new IntersectionObserver( ( [ entry ], observer ) => {

					observer.disconnect();

					const { boundingClientRect } = entry;

					this.rect.width = boundingClientRect.width;
					this.rect.height = boundingClientRect.height;
					this.rect.top = boundingClientRect.top + window.scrollY;
					this.rect.left = boundingClientRect.left;

					resolve();

				} ).observe( this.element );

			} )

		}

		getBgInfo() {

			return fastdomPromised.measure( () => {

				if ( !this.bgImg ) {

					if ( this.isRowBg ) {
						return this.bgImg = `url(${ this.isRowBg })`;
					}

					const styles = getComputedStyle( this.element );

					this.bgImg = styles.backgroundImage;

				}

			} )

		}

		async handleSentinel() {

			this.onWindowResize = liquidDebounce( this.onWindowResize, 500 );

			await this.createSentinel();
			this.handleResize();

		}

		createSentinel() {

			return fastdomPromised.mutate( () => {

				this.sentinel = document.createElement( 'div' );
				this.sentinel.setAttribute( 'class', 'lqd-parallax-sentinel pointer-events-none pos-abs z-index--1 invisible absolute -z-1' );

				document.body.appendChild( this.sentinel );

			} )

		}

		positionSentinel() {

			return fastdomPromised.mutate( () => {

				this.sentinel.style.width = `${ this.rect.width }px`;
				this.sentinel.style.height = `${ this.rect.height }px`;
				this.sentinel.style.top = `${ this.rect.top }px`;
				this.sentinel.style.left = `${ this.rect.left }px`;

			} )

		}

		buildParallaxMarkups() {

			return new Promise( async resolve => {

				if ( !this.options.parallaxBG ) {

					this.initParallax();

					resolve();

				} else {

					await this.getBgInfo();

					this.initParallaxBG();

					this.element.classList.add( 'lqd-parallax-bg' );

					resolve();

				}

			} )

		}

		initParallax() {

			const { overflowHidden } = this.options;

			if (
				!this.element.classList.contains( 'vc_column_container' ) &&
				!this.element.classList.contains( 'ld-fancy-heading' ) &&
				( overflowHidden || this.options.forceWrap )
			) {

				const overflow = overflowHidden ? 'overflow-hidden' : '';

				const wrapper = document.createElement( 'div' );

				wrapper.setAttribute( 'class', `ld-parallax-wrap ${ overflow }` );
				this.element.parentNode.insertBefore( wrapper, this.element );
				wrapper.appendChild( this.element );

			}

		}

		initParallaxBG() {

			const isSlideshowBg = this.element.hasAttribute( 'data-slideshow-bg' );
			const videoBg = this.element.querySelector( ':scope > .lqd-vbg-wrap' );
			const slideshowBgPlugin = this.$element.data( 'plugin_liquidSlideshowBG' );
			const rowBgPlugin = this.$element.data( 'plugin_liquidRowBG' );

			if (
				( ( !isSlideshowBg && !this.isRowBg ) ||
					( this.isRowBg && !rowBgPlugin ) ||
					( isSlideshowBg && !slideshowBgPlugin ) ) &&
				!videoBg
			) {

				if ( !this.parallaxMarkupExists ) {
					this.createParallaxBgMarkup();
				}

				this.parallaxFigure = this.element.querySelector( '.lqd-parallax-figure' );

				this.updateParallaxBgOptions();

				this.setParallaxBgImg();

			}

			if ( isSlideshowBg ) {

				return slideshowBgPlugin.slideshowBgInitPromise.then( slideshowPlugin => {
					const slideshowInner = slideshowPlugin.slideshowInner;
					this.updateParallaxBgOptions();
					return slideshowInner;
				} );

			}

			if ( this.isRowBg ) {

				return rowBgPlugin.rowBgInitPromise.then( rowBgPlugin => {
					const { rowBg } = rowBgPlugin;
					this.updateParallaxBgOptions();
					return rowBg;
				} );

			}

			if ( videoBg ) {
				this.updateParallaxBgOptions();
				return videoBg.children;
			}

		}

		createParallaxBgMarkup() {

			const parallaxContainer = document.createElement( 'div' );
			parallaxContainer.setAttribute( 'class', 'lqd-parallax-container lqd-overlay overflow-hidden' );
			parallaxContainer.setAttribute( 'style', 'border-radius: inherit; background-size: inherit; background-attachment: inherit; background-repeat: inherit; background-position: inherit;' );

			const parallaxFigure = document.createElement( 'figure' );
			parallaxFigure.setAttribute( 'class', 'lqd-parallax-figure lqd-overlay' );
			parallaxFigure.setAttribute( 'style', 'border-radius: inherit; background-size: inherit; background-attachment: inherit; background-repeat: inherit; background-position: inherit;' );

			parallaxContainer.appendChild( parallaxFigure );
			this.$element.prepend( parallaxContainer );

		}

		setParallaxBgImg() {

			if ( this.bgImg && this.bgImg !== 'none' && this.options.parallaxBG ) {
				this.parallaxFigure.style.backgroundImage = this.bgImg;
				this.element.classList.add( 'bg-none' );
			}

		}

		updateParallaxBgOptions() {

			if ( typeof this.parallaxFromOptions.yPercent === typeof undefined ) {
				this.parallaxFromOptions.yPercent = -15;
			}
			if ( typeof this.parallaxToOptions.yPercent === typeof undefined ) {
				this.parallaxToOptions.yPercent = 0;
			}

		}

		init() {

			fastdomPromised.measure( async () => {

				await this.measure();
				await this.positionSentinel();

			} ).then( () => {

				fastdomPromised.mutate( () => {

					const isParallaxBg = this.options.parallaxBG;
					let { start, end, scrub, ease, startTrigger } = this.options;
					let trigger = this.sentinel;

					if ( startTrigger ) {
						if ( typeof startTrigger === 'string' ) {
							trigger = document.querySelector( startTrigger );
						} else {
							trigger = startTrigger;
						}
					}

					this.parallaxTimeline = gsap.timeline();

					this.parallaxTimeline.fromTo( this.parallaxElements,
						{ ...this.parallaxFromOptions },
						{
							ease,
							...this.parallaxToOptions
						}
					);

					this.ST = ScrollTrigger.create( {
						animation: this.parallaxTimeline,
						trigger,
						start: () => start,
						end: () => end,
						scrub: isParallaxBg ? 0.35 : scrub,
						onRefresh: () => {
							start = this.options.start;
							end = this.options.end;
							this.ST.update();
						},
						onUpdate: () => {
							gsap.set( this.parallaxElements, {
								transition: 'none'
							} )
						},
						onScrubComplete: () => {
							gsap.set( this.parallaxElements, {
								transition: ''
							} )
						}
					} );

					!this.options.skipWillChange && this.addWillChange();

					if ( isParallaxBg ) {
						gsap.to( this.parallaxElements, {
							opacity: 1
						} )
					}

					this.element.dispatchEvent( new CustomEvent( 'lqd-parallax-initiated' ) );

				} )

			} )

		}

		addWillChange() {

			const willChangeProps = [ 'transform' ];

			if (
				( this.parallaxFromOptions.opacity && this.parallaxToOptions.opacity ) &&
				( this.parallaxFromOptions.opacity !== this.parallaxToOptions.opacity )
			) {
				willChangeProps.push( 'opacity' );
			}

			const props = willChangeProps.join( ', ' );

			new IntersectionObserver( ( [ entry ] ) => {
				if ( entry.isIntersecting ) {
					this.element.style.willChange = props;
				} else {
					this.element.style.willChange = 'auto';
				}
			} ).observe( this.sentinel );

		}

		handleResize() {

			$( window ).on( 'resize.lqdParallax', this.onWindowResize.bind( this ) );

		}

		async onWindowResize() {

			await this.measure();
			this.positionSentinel();

		}

		destroy() {

			if ( this.sentinel ) {
				this.sentinel.remove();
			}

			if ( this.parallaxTimeline ) {
				gsap.killTweensOf( this.parallaxTimeline );
				this.parallaxTimeline.scrollTrigger.kill();
				this.parallaxTimeline.kill();
				gsap.set( this.parallaxElements, { clearProps: 'all' } );
				this.parallaxTimeline.clear();
			}

			$.data( this.element, "plugin_" + pluginName, null );

			$( window ).off( 'resize.lqdParallax' );

		}

	}

	$.fn[ pluginName ] = function ( options, fromOpts, toOpts ) {

		return this.each( function () {

			const pluginOptions = { disableOnMobile: true, ...$( this ).data( 'parallax-options' ), ...options };
			const parallaxFrom = { ...$( this ).data( 'parallax-from' ), ...fromOpts };
			const parallaxTo = { ...$( this ).data( 'parallax-to' ), ...toOpts };

			if ( !$.data( this, "plugin_" + pluginName ) ) {

				if ( pluginOptions.disableOnMobile && liquidIsMobile() ) return;

				$.data( this, "plugin_" + pluginName, new Plugin( this, pluginOptions, parallaxFrom, parallaxTo ) );

			}

		} );

	};

}( jQuery ) );

jQuery( document ).ready( function ( $ ) {

	$( '[data-parallax]' ).not( '[data-pin]:not(.vc_row), .rev-slidebg' ).liquidParallax();

} );