( function ( $ ) {

	'use strict';

	const pluginName = 'liquidCounter';
	let defaults = {
		targetNumber: 0,
		startDelay: 0,
		blurEffect: false
	};

	function Plugin( element, options ) {

		this._defaults = defaults;
		this._name = pluginName;

		this.options = $.extend( {}, defaults, options );

		this.element = element;
		this.$element = $( element );

		this.init();
	}

	Plugin.prototype = {

		init: function () {

			this.createMarkup();
			this.setupIO();

		},

		formatNumberWithCommas: function ( number ) {
			return number.toString().replace( /\B(?=(\d{3})+(?!\d))/g, ',' );
		},

		formatNumberWithSpaces: function ( number ) {
			return number.toString().replace( /\B(?=(\d{3})+(?!\d))/g, ' ' );
		},

		formatCounterAnimator: function ( number ) {
			return number.toString().replace( /(\d)/g, '<span class="lqd-counter-animator d-inline-flex pos-rel inline-flex relative overflow-hidden"><span class="lqd-animator-value d-inline-block inline-block invisible">$1</span></span>' );
		},

		createMarkup: function () {

			const counter = $( this.element ).children( 'span' ).not( '.lqd-counter-element-hover' );
			const options = this.options;
			const counterVal = options.targetNumber;
			const formatWithCommas = /,+/.test( counterVal );
			const formatWithSpaces = /\s+/.test( counterVal );

			if ( formatWithCommas )
				counter.html( this.formatCounterAnimator( this.formatNumberWithCommas( counterVal ) ) );
			else if ( formatWithSpaces )
				counter.html( this.formatCounterAnimator( this.formatNumberWithSpaces( counterVal ) ) );
			else
				counter.html( this.formatCounterAnimator( counterVal ) );

			counter.find( '.lqd-counter-animator' ).each( function ( i, animator ) {

				const $animator = $( animator );
				const animatorValue = $animator.find( '.lqd-animator-value' ).text();

				$animator.append(
					`<div class="lqd-animator-numbers lqd-overlay overflow-hidden" data-value="${ animatorValue }">
	<ul class="reset-ul w-100 h-100 pos-rel w-full h-full relative">
		<li class="m-0">0</li>
		<li class="m-0">1</li>
		<li class="m-0">2</li>
		<li class="m-0">3</li>
		<li class="m-0">4</li>
		<li class="m-0">5</li>
		<li class="m-0">6</li>
		<li class="m-0">7</li>
		<li class="m-0">8</li>
		<li class="m-0">9</li>
	</ul>
</div>`
				);

			} );

		},

		addBlurEffect: function ( blurID ) {

			if ( this.options.blurEffect ) {

				const ulElement = $( '.lqd-animator-numbers ul', this.element );

				ulElement.each( ( i, element ) => {

					const $ul = $( element );

					if ( $ul.parent().data( 'value' ) != 0 ) {

						$ul.css( {
							'filter': "url('#counter-blur-" + blurID + "')"
						} );

					}

				} );

			}

		},

		animateCounter: function () {

			const startDelay = this.options.startDelay / 1000;
			const blurID = Math.round( gsap.utils.random( 0, 100 ) );
			const blurSVG = $(
				`<svg class="counter-blur-svg" xmlns="http://www.w3.org/2000/svg" version="1.1" width="0" height="0">
	<defs>
		<filter id="counter-blur-${ blurID }">
			<feGaussianBlur id="counter-blur-filter-${ blurID }" in="SourceGraphic" stdDeviation="0,0" />
		</filter>
	</defs>
</svg>`
			);

			this.addBlurEffect( blurID );

			this.$element.find( '.lqd-animator-numbers' ).each( ( i, animator ) => {

				const $animator = $( animator );
				const counterValue = parseInt( $animator.data( 'value' ), 10 );
				let stdDeviation = { x: 0, y: 0 };
				let blurFilter;

				gsap.to( $animator.find( 'ul' ).get( 0 ), {
					y: ( counterValue * -100 ) + '%',
					ease: 'power2.out',
					delay: startDelay,
					duration: 1.2,
					onComplete: () => {
						this.$element.addClass( 'counter-animated' );
					}
				} );

				if ( this.options.blurEffect ) {

					if ( !$( '.counter-blur-svg', this.element ).length ) {
						blurSVG.appendTo( this.element );
					}

					blurFilter = blurSVG.find( `#counter-blur-filter-${ blurID }` ).get( 0 );

					gsap.to( stdDeviation, {
						startAt: {
							y: ( 50 + ( counterValue * 10 ) )
						},
						ease: 'power4.out',
						duration: 1.2,
						delay: startDelay,
						y: 0.01,
						onUpdate: () => {
							blurFilter.setAttribute( 'stdDeviation', '0,' + stdDeviation.y );
						},
						onComplete: () => {
							$( '.lqd-animator-numbers ul', this.element ).css( 'filter', '' )
						}
					} );

				}

			} );

		},

		setupIO: function () {

			new LiquidIO( this.element, this.animateCounter.bind( this ), { threshold: 0.8, disconnect: true } );

		}

	};

	$.fn[ pluginName ] = function ( options ) {

		return this.each( function () {

			const pluginOptions = { ...$( this ).data( 'counter-options' ), ...options };

			if ( !$.data( this, "plugin_" + pluginName ) ) {
				$.data( this, "plugin_" + pluginName, new Plugin( this, pluginOptions ) );
			}

		} );

	};

}( jQuery ) );

jQuery( document ).ready( function ( $ ) {
	$( '[data-enable-counter]' ).liquidCounter();
} );