(function ( $ ) {
	
	'use strict';
	
	const pluginName = 'liquidMoveElement';
	let defaults = {
		target: '#selector',
		targetRelation: 'closest',
		type: 'prependTo',
		includeParent: false,
		clone: false
	};
	
	class Plugin {

		constructor(element, options) {

			this._defaults = defaults;
			this._name = pluginName;

			this.options = {...defaults, ...options};

			this.element = element;
			this.$element = $(element);

			this.movePromise = new Promise(resolve => {
				this.element.addEventListener('element-was-moved', resolve(this, this));
			})

			this.build();

		}

		build() {

			const promises = [];
			
			if ( this.element.hasAttribute('data-pin') ) {
				const pinPlugin = this.$element.data('plugin_liquidPin');
				if ( pinPlugin ) {
					promises.push(pinPlugin.pinPromise);
				}
			}

			if ( promises.length > 0 ) {
				Promise.all(promises).then(this.init.bind(this));
			} else {
				this.init();
			}

		}
			
		init() {
			
			this.getHiddenClasses();
			this.moveElement();
			
		}

		getHiddenClasses() {

			const parentColumn = this.element.closest('.vc_column_container');
			
			if ( parentColumn ) {

				const parentColumnClass = parentColumn.getAttribute('class');
				const classList = parentColumnClass.split(' ').filter( cls => cls.search('vc_hidden') >= 0 );

				if ( classList.length > 0 ) {
					this.element.classList.add([...classList]);
				}

			}

		}
		
		moveElement() {

			fastdom.mutate(() => {

				const { target, type, targetRelation } = this.options;
		
				this.$element[type]( this.$element[targetRelation](target) );
		
				this.element.classList.add('element-was-moved');

				this.element.dispatchEvent( new CustomEvent( 'element-was-moved', {bubbles: false} ) );

			})

		}
		
	}
	
	$.fn[ pluginName ] = function( options ) {
		
		return this.each( function() {
			
			const pluginOptions = $(this).data('move-element') || options;
			
			if ( !$.data( this, "plugin_" + pluginName ) ) {
				$.data( this, "plugin_" + pluginName, new Plugin( this, pluginOptions ) );
			}
			
		} );
		
	};
	
}(jQuery));

jQuery(document).ready( function($) {
  $('[data-move-element]').liquidMoveElement();
});