( function ( $ ) {

	'use strict';

	const pluginName = 'liquidMasonry';
	let defaults = {
		bypassCheck: false,
		layoutMode: 'packery',
		itemSelector: '.masonry-item',
		alignMid: false,
		filtersID: null,
		filtersCounter: false
	};

	class Plugin {

		constructor( element, options ) {

			this._defaults = defaults;
			this._name = pluginName;

			this.options = { ...defaults, ...options };

			this.element = element;
			this.$element = $( element );

			this.isoData = null;

			this.init();

		}

		init() {

			const $collapsedParents = this.$element.parents( '.lqd-tabs-pane, .accordion-collapse' );

			if ( $collapsedParents.length && $collapsedParents.is( ':hidden' ) ) {
				return this.setupIO();
			}

			this.onImagesLoad();
		}

		setupIO() {

			if ( this.isoData ) return;

			new IntersectionObserver( ( [ entry ], observer ) => {

				if ( entry.isIntersecting ) {

					observer.disconnect();

					this.onImagesLoad();

				}

			} ).observe( this.element );

		}

		onImagesLoad() {

			imagesLoaded( this.element, this.handleOnImagesLoaded.bind( this ) );

		}

		handleOnImagesLoaded() {

			// setting IO causing problem with localscroll
			this.initIsotope();
			this.initFilters();
			this.eventHandlers();

		}

		initIsotope() {

			const { layoutMode, itemSelector, stamp, bypassCheck } = this.options;

			this.isoData = new Isotope( this.element, {
				bypassCheck,
				layoutMode,
				itemSelector,
				stamp
			} );

			$( document ).trigger( 'lqd-masonry-layout-init', { detail: { isotopeData: this.isoData } } );

		}

		setStamps() {

			this.setAlignMidStamps();

		}

		setAlignMidStamps() {

			const options = this.options;

			if ( options.alignMid ) {

				const items = $( options.itemSelector, this.element );
				const columnsCount = this.$element.attr( 'data-columns' );
				const itemsHeights = [];

				let gridSizer = $( '.grid-stamp', this.$element );

				$.each( items, ( i, item ) => {

					const $item = $( item );
					const height = $item.outerHeight();

					itemsHeights.push( height );

				} );

				this.highestHeight = Math.max( ...itemsHeights );
				this.lowestHeight = Math.min( ...itemsHeights );

				if ( columnsCount >= 3 ) {

					gridSizer.clone().insertBefore( items.eq( columnsCount - 1 ) ).addClass( 'is-right' );
					gridSizer = gridSizer.add( '.grid-stamp', this.$element );

				}

				gridSizer.height( this.lowestHeight / 2 );

				options.stamp = '.grid-stamp';

			}

		}

		initFilters() {

			const { filtersID, filtersCounter } = this.options;

			if ( !filtersID ) { return };

			const $filterList = $( filtersID );
			const $filterDropdown = $filterList.siblings( '.lqd-filter-dropdown' );

			$( 'li', $filterList ).each( ( i, element ) => {

				const $li = $( element );
				const filterVal = $li.attr( 'data-filter' );

				if ( filtersCounter ) {
					const filterItems = $( filterVal, this.element );
					const $counter = $( `
						<sup class="lqd-filter-counter">
							<span>${ filterVal === '*' ? this.isoData.items.length : filterItems.length }</span>
						</sup>`
					);
					$counter.appendTo( $li );
				}

				$li.on( 'click.lqdMasonryFilter', () => {
					$li.addClass( 'active' ).siblings().removeClass( 'active' );
					this.isoData.arrange( { filter: filterVal } );
				} );

			} );

			if ( $filterDropdown.length ) {
				$( 'select', $filterDropdown ).on( 'selectmenuchange', ( event, ui ) => {
					const filterVal = ui.item.value;
					this.isoData.arrange( { filter: filterVal } );
				} )
			}

		}

		eventHandlers() {

			this.isoData.on( 'layoutComplete', this.handleLayoutComplete.bind( this ) );

		}

		handleLayoutComplete() {

			$( document ).trigger( 'lqd-masonry-layout-complete', { detail: { isotopeData: this.isoData } } );

		}

	}

	$.fn[ pluginName ] = function ( options ) {

		return this.each( function () {

			const pluginOptions = { ...$( this ).data( 'masonry-options' ), ...options };

			if ( !$.data( this, "plugin_" + pluginName ) ) {
				$.data( this, "plugin_" + pluginName, new Plugin( this, pluginOptions ) );
			}

		} );

	};

}( jQuery ) );

jQuery( document ).ready( function ( $ ) {

	if ( ( !$liquidContents.length ) || ( $liquidContents.length && !$liquidContents[ 0 ].hasAttribute( 'data-liquid-stack' ) ) ) {
		$( '[data-liquid-masonry]' ).liquidMasonry();
	} else if ( $liquidContents.length && $liquidContents[ 0 ].hasAttribute( 'data-liquid-stack' ) ) {
		const stackOptions = $liquidContents.attr( 'data-stack-options' );
		if ( stackOptions ) {
			const optionsJson = JSON.parse( stackOptions );
			const { disableOnMobile } = optionsJson;
			if ( disableOnMobile && ( liquidIsMobile() || liquidWindowWidth() <= liquidMobileNavBreakpoint() ) ) {
				$( '[data-liquid-masonry]' ).liquidMasonry();
			}
		}
	}

} );