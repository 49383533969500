( function ( $ ) {

	'use strict';

	const pluginName = 'liquidScrollIndicator';
	let defaults = {
		start: 'top top',
		end: 'bottom top',
		scrollingTarget: 'body',
		indicatorBase: '.lqd-scrl-indc-line',
		indicatorEl: '.lqd-scrl-indc-el',
		dir: "y", // "x", "y"
		scale: false,
		origin: 'null',
		waitForElementMove: false,
	};

	class Plugin {

		constructor( element, options ) {

			this._defaults = defaults;
			this._name = pluginName;

			this.options = { ...defaults, ...options };

			this.DOM = {};
			this.DOM.element = element;
			this.DOM.scrollingTarget = this.getScrollingTarget();
			this.DOM.indicatorBase = this.DOM.element.querySelector( this.options.indicatorBase );
			this.DOM.indicatorEl = this.DOM.element.querySelector( this.options.indicatorEl );

			if ( this.options.waitForElementMove ) {
				const $parentElementMove = $( this.DOM.element ).closest( '[data-move-element]' );
				if ( $parentElementMove.length ) {
					return $parentElementMove.data( 'plugin_liquidMoveElement' ).movePromise.then( () => this.init() );
				}
			}

			this.init();

		}

		init() {

			!$liquidContents[ 0 ].hasAttribute( 'data-liquid-stack' ) ? this.initialTrigger() : this.initMutationObserver();

		}

		getScrollingTarget() {

			const { scrollingTarget } = this.options;

			switch ( scrollingTarget ) {
				case 'this':
					return this.DOM.element;
				case 'parentHref':
					return this.$element.closest( 'a' ).attr( 'href' )
				case 'siblingsHref':
					return this.$element.siblings( 'a' ).first().attr( 'href' )
				default:
					const isLinkedToElement = scrollingTarget.startsWith( '#' );
					let target = isLinkedToElement ? document.querySelector( scrollingTarget ) : scrollingTarget;
					if ( target === 'body' ) {
						target = document.body
					}
					return target;
			}

		}

		initialTrigger() {

			const { dir, scale, start, end, origin } = this.options;
			const { indicatorBase, scrollingTarget, indicatorEl } = this.DOM;
			const initScale = `scale${ dir.toUpperCase() }`;
			const otherScale = dir === 'x' ? 'scaleY' : 'scaleX';
			let tween;

			if ( origin ) {
				gsap.set( indicatorEl, { transformOrigin: origin } );
			}
			if ( !scale ) {
				tween = gsap.to( indicatorEl, {
					[ dir ]: dir === 'y' ? indicatorBase.offsetHeight : indicatorBase.offsetWidth,
					force3D: false
				} );
			} else {
				tween = gsap.fromTo( indicatorEl,
					{
						[ initScale ]: 0,
						[ otherScale ]: 1
					},
					{
						scale: 1,
						force3D: false
					}
				)
			}

			ScrollTrigger.create( {
				trigger: scrollingTarget,
				animation: tween,
				start,
				end,
				scrub: 0.5
			} );

		}

		initMutationObserver() {

			const stackData = $liquidContents.data( 'plugin_liquidStack' );

			if ( !stackData || liquidIsMobile() || liquidWindowWidth() <= liquidMobileNavBreakpoint() ) return;

			stackData.stackInitPromise.then( plugin => {

				const { dir } = this.options;
				const { indicatorBase, indicatorEl } = this.DOM;
				const totalSections = [ ...plugin.$sectionElements ].filter( section => !section.classList.contains( 'main-footer' ) ).length;
				const indicatorBaseSize = dir === 'y' ? indicatorBase.offsetHeight : indicatorBase.offsetWidth

				new MutationObserver( () => {

					const currentStackPage = document.body.getAttribute( 'data-lqd-stack-page' );
					const indicatorTrans = gsap.utils.mapRange( 1, totalSections, 0, indicatorBaseSize, currentStackPage );

					gsap.to( indicatorEl, {
						[ dir ]: indicatorTrans,
						ease: 'linear',
						duration: 1
					} );

				} ).observe( document.body, { attributeFilter: [ 'data-lqd-stack-page' ] } )

			} );

		}

	}

	$.fn[ pluginName ] = function ( options ) {

		return this.each( function () {

			const pluginOptions = { ...$( this ).data( 'scrl-indc-options' ), ...options };

			if ( !$.data( this, "plugin_" + pluginName ) ) {
				$.data( this, "plugin_" + pluginName, new Plugin( this, pluginOptions ) );
			}

		} );

	};

}( jQuery ) );

jQuery( document ).ready( function ( $ ) {
	$( '[data-lqd-scroll-indicator]' ).liquidScrollIndicator();
} );