jQuery(document).ready( function($) {

	if ( liquidLazyloadEnabled ) {

		const globalLazyLoadOffset = liquidParams?.lazyLoadOffset;
		const threshold = globalLazyLoadOffset >= 0 ? globalLazyLoadOffset : 500;

		window.liquidLazyload = new LazyLoad({
			elements_selector: '.ld-lazyload',
			threshold,
			callback_loaded: el => {
			
				const $element = $(el);
				const $masonryParent = $element.closest('[data-liquid-masonry=true]');
				// const $flickityParent = $element.closest('[data-lqd-flickity]');
				const $flexParent = $element.closest('.flex-viewport');
				const $webGLHoverParent = $element.closest('[data-webglhover]');
				const $revealParent = $element.closest('[data-reveal]');
				
				$element.parent().not('#wrap, #lqd-site-content').addClass('loaded');
				
				$element.closest('[data-responsive-bg=true]').liquidResponsiveBG();
				
				if ( $masonryParent.length && $masonryParent.data('isotope') ) {
					$masonryParent.isotope('layout');
				}
				
				// CAUSING ISSUES ON FREESCROLL WHEN IMAGES LOADED IT'S JUSMPING
				// if ( $flickityParent.length && $flickityParent.data('flickity') && ! $flickityParent.hasClass('lqd-marquee-carousel') ) {
				// 	$flickityParent.on('settle.flickity', () => {
				// 		$flickityParent.flickity('resize');
				// 	});
				// }
				
				if ( $flexParent.length && $flexParent.parent().data('flexslider') ) {
					$flexParent.height($element.height());
				}
				
				if ( $webGLHoverParent.length && ! liquidIsMobile() ) {
					$webGLHoverParent.liquidWebGLHover();
				}
				
				if ( $revealParent.length ) {
					$revealParent.liquidReveal();
				}

			}
		});

	}

});