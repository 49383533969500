(function ( $ ) {
	
	'use strict';
	
	const pluginName = 'liquidCountdown';
	let defaults = {
		daysLabel: "Days",
		hoursLabel: "Hours",
		minutesLabel: "Minutes",
		secondsLabel: "Seconds",
		timezone: null
	};
	
	class Plugin {

		constructor(element, options) {
			this.element = element;
			this.$element = $(element);
			this.options = $.extend({}, defaults, options);
			this._defaults = defaults;
			this._name = pluginName;
			this.init();
		}
			
		init() {
			
			const {options} = this;
			const {until, timezone} = options;

			this.$element.countdown({
				until: new Date( until.replace(/-/g, "/") ),
				padZeroes: true,
				timezone,
				// Have to specify the layout due to errors on mobile devices
				layout: '<span class="countdown-row">' +
					'<span class="countdown-section">' +
						'<span class="countdown-amount">{dn}</span>' +
						'<span class="countdown-period">' + options.daysLabel + '</span>' +
					'</span>' +
					'<span class="countdown-sep">:</span>' +
					'<span class="countdown-section">' +
						'<span class="countdown-amount">{hn}</span>' +
						'<span class="countdown-period">' + options.hoursLabel + '</span>' +
					'</span>' +
					'<span class="countdown-sep">:</span>' +
					'<span class="countdown-section">' +
						'<span class="countdown-amount">{mn}</span>' +
						'<span class="countdown-period">' + options.minutesLabel + '</span>' +
					'</span>' +
					'<span class="countdown-sep">:</span>' +
					'<span class="countdown-section">' +
						'<span class="countdown-amount">{sn}</span>' +
						'<span class="countdown-period">' + options.secondsLabel + '</span>' +
					'</span>' +
				'</span>'
			});

			return this;
			
		}

		destroy() {

			this.$element.countdown('destroy');

		}
		
	}
	
	
	$.fn[ pluginName ] = function( options ) {
		
		return this.each( function() {
			
			const pluginOptions = $(this).data('countdown-options') || options;
			
			if ( !$.data( this, "plugin_" + pluginName ) ) {
				
				$.data( this, "plugin_" + pluginName, new Plugin( this, pluginOptions ) );
				
			}
			
		} );
		
	};
	
}(jQuery, window, document));

jQuery(document).ready(function($) {
  $('[data-plugin-countdown=true]').liquidCountdown();
});