( function ( $ ) {

	'use strict';

	const pluginName = 'liquidStickyStack';
	let defaults = {
		itemsSelector: '.lqd-sticky-stack-item',
		itemsInnerSelector: '.lqd-sticky-stack-item-inner',
		offset: 30,
		spacer: 30,
		minScale: 0.8
	};

	class Plugin {

		constructor( element, options ) {

			this._defaults = defaults;
			this._name = pluginName;

			this.options = { ...defaults, ...options };

			this.DOM = {};
			this.DOM.element = element;
			this.DOM.$element = $( element );
			this.DOM.$items = this.DOM.$element.find( this.options.itemsSelector );
			this.DOM.$innerItems = this.DOM.$element.find( this.options.itemsInnerSelector );
			this.DOM.$nav = null;
			this.DOM.$navItems = null;

			if ( this.DOM.$items.length < 2 ) return;

			imagesLoaded( this.DOM.element, () => {
				// this.createNav();
				this.init();
				// this.initNav();
			} )

		}

		createNav() {

			this.DOM.$nav = $( '<div class="lqd-sticky-stack-nav h-100 pos-abs pos-tl absolute top-0 left-0" />' );
			const $navUl = $( '<ul class="reset-ul lqd-css-sticky d-flex flex-column justify-content-center h-vh-100 flex flex-col justify-center" />' );

			for ( let i = 0; i < this.DOM.$items.length; i++ ) {
				$navUl.append( '<li><span></span></li>' );
			}

			$navUl.appendTo( this.DOM.$nav );
			this.DOM.$navItems = $navUl.children();
			this.DOM.$nav.appendTo( this.DOM.$element );

		}

		init() {

			const { spacer, minScale } = this.options;
			const offset = this.getOffset();
			const stickyHeaderHeight = getComputedStyle( document.documentElement ).getPropertyValue( '--lqd-sticky-header-height' ) || 0;
			const lasItemHeight = this.DOM.$items.last().outerHeight();

			const distributor = gsap.utils.distribute( { base: minScale, amount: 0.2 } );

			this.DOM.$innerItems.each( ( i, innerItem ) => {

				const scaleVal = distributor( i, this.DOM.$innerItems.get( i ), this.DOM.$innerItems.get() );

				const animation = gsap.to( innerItem, {
					scale: scaleVal
				} );

				ScrollTrigger.create( {
					animation,
					trigger: innerItem,
					start: `top top`,
					scrub: 0.25,
					// onEnter: () => {
					// 	this.DOM.$navItems.removeClass('is-active').eq(i).addClass('is-active');
					// },
					// onEnterBack: () => {
					// 	this.DOM.$navItems.removeClass('is-active').eq(i).addClass('is-active');
					// }
				} );

			} );

			this.DOM.$items.each( ( i, item ) => {

				ScrollTrigger.create( {
					trigger: item,
					start: `top-=${ offset + ( i * spacer ) + parseInt( stickyHeaderHeight ) } top`,
					end: `bottom top+=${ lasItemHeight + ( this.DOM.$items.length * spacer ) }`,
					endTrigger: this.DOM.element,
					pin: true,
					pinSpacing: false,
				} );

			} );

		}

		getOffset() {

			let { offset } = this.options;
			const $stickySections = $liquidMainHeader.find( '.lqd-head-sec-wrap' ).not( '.lqd-hide-onstuck' );

			if ( $stickySections.length ) {
				$stickySections.each( ( i, sec ) => { offset += $( sec ).outerHeight() } );
			}

			return offset;

		}

		initNav() {

			function buildThresholdList() {
				let thresholds = [];
				let numSteps = 20;

				for ( let i = 1.0; i <= numSteps; i++ ) {
					let ratio = i / numSteps;
					thresholds.push( ratio );
				}

				thresholds.push( 0 );
				return thresholds;
			}

			for ( let i = 0; i < this.DOM.$items.length; i++ ) {

				let prevRatio = 0;

				new IntersectionObserver( ( [ entry ] ) => {
					if ( entry.isIntersecting && prevRatio < entry.intersectionRatio ) {
						this.DOM.$navItems.removeClass( 'is-active' ).eq( i ).addClass( 'is-active' );
					}
				}, { threshold: buildThresholdList() } ).observe( this.DOM.$items.get( i ) )

			}

		}

	}

	$.fn[ pluginName ] = function ( options ) {

		return this.each( function () {

			const pluginOptions = { ...$( this ).data( 'sticky-stack-options' ), ...options };

			if ( !$.data( this, "plugin_" + pluginName ) ) {
				$.data( this, "plugin_" + pluginName, new Plugin( this, pluginOptions ) );
			}

		} );

	};

}( jQuery ) );

jQuery( document ).ready( function ( $ ) {
	liquidIsMobile() || $( '.lqd-sticky-stack' ).liquidStickyStack();
} );