( function ( $ ) {

	'use strict';

	const pluginName = 'liquidMegamenuSlide';
	let defaults = {
	};

	class Plugin {

		constructor( element, options ) {

			this._defaults = defaults;
			this._name = pluginName;

			this.options = { ...defaults, ...options };

			this.DOM = {};
			this.DOM.element = element;
			this.DOM.$element = $( element );
			this.DOM.$megamenuItems = this.DOM.$element.find( '.megamenu' ).filter( ( i, el ) => !$( el ).parent().hasClass( 'lqd-mobile-main-nav' ) && !$( el ).parent().hasClass( 'lqd-menu-items-block' ) );
			this.DOM.$nonMegamenuItems = this.DOM.$megamenuItems.eq( 0 ).siblings().not( '.megamenu' );

			this.dropdownsInfo = [];
			this.$megamenusParent = false;
			this.lastActiveIndex = false;
			this.activeIndex = false;
			this.wasRevealed = false;
			this.timeout = false;

			this.getMegamenuDropdowns().then( infos => {

				this.dropdownsInfo = infos.filter( info => info.dropdown != null );
				this.$megamenusParent = $( infos[ 0 ].element.parentNode );

				this.buildMarkup();
				this.init();

			} );

		}

		async getMegamenuDropdowns() {

			const promises = [];

			this.DOM.$megamenuItems.each( ( i, megamenuItem ) => {

				const $megamenuItem = $( megamenuItem );
				const megamenuData = $megamenuItem.data( 'plugin_liquidMegamenu' );

				if ( megamenuData ) {
					promises.push( megamenuData.dropdownInfoPromise );
				}

			} );

			return await Promise.all( promises );

		}

		buildMarkup() {

			this.$megamenusParent.append( `
				<li class="lqd-megamenu-slide-stuff pos-abs pos-bl pos-r absolute bottom-0 left-0 right-0 pointer-events-none">
					<div class="lqd-megamenu-slide-stuff-wrap pos-abs pos-l pos-r absolute left-0 right-0">
						<span class="lqd-megamenu-slide-arrow pos-abs pos-tl absolute top-0 left-0"></span>
						<span class="lqd-megamenu-slide-bg pos-abs pos-tl absolute top-0 left-0">
							<span class="lqd-megamenu-slide-bg-inner pos-abs pos-tl absolute top-0 left-0"></span>
						</span>
					</div>
				</li>
			`);

		}

		init() {

			this.eventListeners();

		}

		eventListeners() {

			document.addEventListener( 'lqd-submenu-change', e => {
				const { detail } = e;
				const { state, $submenu } = detail;
				const $submenuParent = $submenu?.closest( 'li' );

				if ( $submenuParent?.length ) {
					const megamenuIndex = this.DOM.$megamenuItems.get().indexOf( $submenuParent[ 0 ] );

					if ( megamenuIndex >= 0 ) {
						if ( state === 'show' ) {
							this.onMegamenuItemEnter( megamenuIndex );
						} else {
							this.onMegamenuItemLeave( megamenuIndex );
						}
					} else {
						this.resetReveal();
					}
				} else {
					this.resetReveal();
				}
			} );

			// this.DOM.$megamenuItems.each( ( i, megamenuItem ) => {
			// 	megamenuItem.addEventListener( 'mouseenter', this.onMegamenuItemEnter.bind( this, i ) );
			// 	megamenuItem.addEventListener( 'mouseleave', this.onMegamenuItemLeave.bind( this, i ) );
			// } );

			// this.DOM.$nonMegamenuItems.each( ( i, menuItem ) => {
			// 	menuItem.addEventListener( 'mouseenter', this.resetReveal.bind( this ) );
			// } );

			// this.DOM.element.addEventListener( 'mouseleave', this.resetReveal.bind( this ) );

		}

		onMegamenuItemEnter( itemIndex ) {

			let elementBoundingRect;
			let megamenuBoundingRect;
			let megamenuFinalPos;
			let arrowPos;

			elementBoundingRect = this.dropdownsInfo[ itemIndex ].elementBoundingRect;
			megamenuBoundingRect = this.dropdownsInfo[ itemIndex ].megamenuBoundingRect;
			megamenuFinalPos = this.dropdownsInfo[ itemIndex ].megamenuFinalPos;
			arrowPos = elementBoundingRect.left + ( elementBoundingRect.width / 2 );

			if ( !this.wasRevealed ) {

				this.DOM.element.classList.add( 'lqd-megamenu-slide-reveal' );

				this.DOM.element.style.setProperty( '--lqd-megamenu-init-width', megamenuBoundingRect.width );
				this.DOM.element.style.setProperty( '--lqd-megamenu-init-height', megamenuBoundingRect.height );

			} else {

				this.DOM.element.classList.remove( 'lqd-megamenu-slide-reveal' );

			}

			this.DOM.$megamenuItems.eq( itemIndex ).removeClass( 'lqd-megamenu-item-slide-out' );

			this.activeIndex = itemIndex;

			this.DOM.element.style.setProperty( '--lqd-megamenu-slide-arrow-pos', `${ arrowPos }px` );
			this.DOM.element.style.setProperty( '--lqd-megamenu-current-width', megamenuBoundingRect.width );
			this.DOM.element.style.setProperty( '--lqd-megamenu-current-height', megamenuBoundingRect.height );
			this.DOM.element.style.setProperty( '--lqd-megamenu-x', `${ megamenuFinalPos.left || 0 }px` );
			this.DOM.element.style.setProperty( '--lqd-megamenu-y', `${ megamenuFinalPos.top || 0 }px` );

			if ( this.lastActiveIndex >= 0 && this.activeIndex >= 0 ) {

				if ( this.lastActiveIndex < this.activeIndex ) {
					this.DOM.element.classList.add( 'lqd-megamenu-slide-from-right' );
				} else if ( this.lastActiveIndex > this.activeIndex ) {
					this.DOM.element.classList.remove( 'lqd-megamenu-slide-from-right' );
				}

			}

			this.wasRevealed = true;

			$liquidMainHeader.addClass( 'lqd-megamenu-slide-active' );

		}

		onMegamenuItemLeave( itemIndex ) {

			this.activeIndex = false;
			this.lastActiveIndex = itemIndex;

			this.DOM.element.classList.remove( 'lqd-megamenu-slide-reveal' );
			this.DOM.element.classList.remove( 'lqd-megamenu-slide-from-right' );
			this.DOM.$megamenuItems.eq( itemIndex ).addClass( 'lqd-megamenu-item-slide-out' );
			$liquidMainHeader.removeClass( 'lqd-megamenu-slide-active' );

			this.timeout = setTimeout( () => {

				if ( this.activeIndex === false || this.activeIndex < 0 ) {
					this.resetReveal();
				}

				clearTimeout( this.timeout );

			}, 180 );

		}

		resetReveal() {

			this.activeIndex = false;
			this.wasRevealed = false;

			this.DOM.element.classList.remove( 'lqd-megamenu-slide-reveal' );
			this.DOM.element.classList.remove( 'lqd-megamenu-slide-from-right' );
			$liquidMainHeader.removeClass( 'lqd-megamenu-slide-active' );

		}

	}

	$.fn[ pluginName ] = function ( options ) {

		return this.each( function () {

			const pluginOptions = { ...$( this ).data( 'megamenu-slide-options' ), ...options };

			if ( !$.data( this, "plugin_" + pluginName ) ) {
				$.data( this, "plugin_" + pluginName, new Plugin( this, pluginOptions ) );
			}

		} );

	};

}( jQuery ) );

jQuery( document ).ready( function ( $ ) {
	$( '[data-megamenu-slide]' ).filter( ( i, el ) => $( el ).find( '.megamenu' ).length ).liquidMegamenuSlide();
} );