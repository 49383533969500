( function ( $ ) {

	'use strict';

	const pluginName = 'liquidVideoBg';
	let defaultInlineVideoOptions = {
		startVolume: false,
		controls: false,
		loop: true,
		muted: true,
		hideVideoControlsOnLoad: true,
		hideVideoControlsOnPause: true,
		clickToPlayPause: false,
		disableOnMobile: false
	};
	let defaultYoutubeOptions = {
		autoPlay: true,
		showControls: false,
		loop: true,
		mute: true,
		showYTLogo: false,
		stopMovieOnBlur: false,
		disableOnMobile: false
	}

	class Plugin {

		constructor( element, inlineVideoOptions, youtubeOptions ) {

			this.element = element;
			this.$element = $( element );

			this.inlineVideoOptions = { ...defaultInlineVideoOptions, ...inlineVideoOptions };
			this.youtubeOptions = { ...defaultYoutubeOptions, ...youtubeOptions };

			this._name = pluginName;

			this.lqdVBG = null;
			this.lqdYTPlayer = null;

			this.init();

		}

		init() {

			const isMobile = liquidIsMobile();

			if ( this.$element.is( 'video' ) && this.inlineVideoOptions.disableOnMobile && isMobile ) {
				this.$element.closest( '.lqd-vbg-wrap' ).addClass( 'hidden' );
			} else if ( this.$element.is( 'video' ) ) {
				this.$element.removeClass( 'hidden' );
				this.initInlineVideo();
			}

			if ( !this.$element.is( 'video' ) && this.youtubeOptions.disableOnMobile && isMobile ) {
				this.$element.closest( '.lqd-vbg-wrap' ).addClass( 'hidden' );
			} else if ( !this.$element.is( 'video' ) ) {
				this.initYoutubeVideo();
			}

		}

		initInlineVideo() {

			const $vBgWrap = this.$element.closest( '.lqd-vbg-wrap' );
			const elementToObserve = $vBgWrap.length ? $vBgWrap.get( 0 ) : this.element;

			// const videoOptions = $.extend(
			// 	{},
			// 	this.inlineVideoOptions,
			// 	{
			// 		stretching: 'responsive',
			// 		success: ( mediaElement ) => {
			// 			mediaElement.pause();
			// 			this.initInlineVidIO( elementToObserve );
			// 		}
			// 	}
			// );
			// this.lqdVBG = new MediaElementPlayer( this.element, videoOptions );
			this.lqdVBG = this.element;

		}

		initYoutubeVideo() {

			const videoOptions = $.extend( {}, this.youtubeOptions, { containment: this.$element } );

			this.lqdYTPlayer = this.$element.YTPlayer( videoOptions );

			this.lqdYTPlayer.on( 'YTPReady', () => {

				this.lqdYTPlayer.YTPPause();
				this.initYTIO();

			} );

		}

		initInlineVidIO( elementToObserve ) {

			new IntersectionObserver( ( [ entry ] ) => {

				if ( entry.isIntersecting ) {
					this.lqdVBG && this.lqdVBG.play();
				} else {
					this.lqdVBG && this.lqdVBG.pause();
				}

			} ).observe( elementToObserve );

		}

		initYTIO() {

			new IntersectionObserver( ( [ entry ] ) => {

				if ( entry.isIntersecting ) {
					this.lqdYTPlayer && this.lqdYTPlayer.YTPPlay();
				} else {
					this.lqdYTPlayer && this.lqdYTPlayer.YTPPause();
				}

			} ).observe( this.element );

		}

	}

	$.fn[ pluginName ] = function ( inlineVideoOptions, youtubeOptions ) {

		return this.each( function () {

			const inlineVidOptions = { ...$( this ).data( 'inlinevideo-options' ), ...inlineVideoOptions };
			const YTOptions = { ...$( this ).data( 'youtube-options' ), ...youtubeOptions };

			if ( !$.data( this, "plugin_" + pluginName ) ) {
				$.data( this, "plugin_" + pluginName, new Plugin( this, inlineVidOptions, YTOptions ) );
			}

		} );

	};

}( jQuery, window, document ) );

jQuery( document ).ready( function ( $ ) {

	$( '[data-video-bg]' ).liquidVideoBg();

} );