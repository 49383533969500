( function ( $ ) {

	'use strict';

	const pluginName = 'liquidAjaxLoadMore';
	let defaults = {
		trigger: "inview" // "inview", "click"
	};

	class Plugin {

		constructor( element, options ) {

			this.element = element;
			this.$element = $( element );

			this.options = $.extend( {}, defaults, options );
			this._defaults = defaults;
			this._name = pluginName;

			this.observer = null;

			this.init();

		}

		init() {

			const { trigger } = this.options;

			trigger == 'inview' && this.setupIntersectionObserver();
			trigger == 'click' && this.onClick();

		}

		onClick() {

			this.$element.on( 'click', this.loadItems.bind( this ) );

		}

		setupIntersectionObserver() {

			this.observer = new IntersectionObserver( enteries => {

				enteries.forEach( entery => {

					if ( entery.isIntersecting ) {

						this.loadItems();

					}

				} );

			}, { threshold: [ 1 ] } );

			this.observer.observe( this.element );

		}

		loadItems( event ) {

			event && event.preventDefault();

			const self = this;
			const options = self.options;
			const target = self.$element.attr( 'href' );

			// Loading State
			self.$element.addClass( 'items-loading' );

			// Load Items
			$.ajax( {
				type: 'GET',
				url: target,
				error: function ( MLHttpRequest, textStatus, errorThrown ) {
					alert( errorThrown );
				},
				success: function ( data ) {

					const $data = $( data );
					const $newItemsWrapper = $data.find( options.wrapper )
					const $newItems = $newItemsWrapper.find( options.items );
					const $wrapper = $( options.wrapper );
					const nextPageUrl = $data.find( '[data-ajaxify=true]' ).attr( 'href' );

					// Add New Items on imagesLoaded
					imagesLoaded( $newItems.get(), function () {

						if (
							nextPageUrl &&
							target != nextPageUrl
						) {

							self.$element.attr( 'href', nextPageUrl );

						} else {

							self.observer && self.observer.unobserve( self.element );

							self.$element.removeClass( 'items-loading' ).addClass( 'all-items-loaded' );

						}

						// Append new items
						$newItems.appendTo( $wrapper );

						if ( $wrapper.get( 0 ).hasAttribute( 'data-liquid-masonry' ) ) {
							const pluginData = $wrapper.data( 'plugin_liquidMasonry' );
							const isoData = pluginData && pluginData.isoData;
							isoData && isoData.appended( $newItems );
						}

						// Calling function for the new items
						self.onSuccess( $wrapper );

					} );

				}
			} );

		}

		onSuccess( $wrapper ) {

			if ( !$( 'body' ).hasClass( 'lazyload-enabled' ) ) {
				$( '[data-responsive-bg=true]', $wrapper ).liquidResponsiveBG();
			}

			if ( $( 'body' ).hasClass( 'lazyload-enabled' ) ) {

				window.liquidLazyload = new LazyLoad( {
					elements_selector: '.ld-lazyload',
					callback_loaded: ( e ) => {
						$( e ).closest( '[data-responsive-bg=true]' ).liquidResponsiveBG();
						$( e ).parent().not( '#wrap, #lqd-site-content' ).addClass( 'loaded' );
					}
				} );

			}

			$( '[data-split-text]', $wrapper ).filter( ( i, element ) => (

				!$( element ).parents( '[data-custom-animations]' ).length &&
				!element.hasAttribute( 'data-custom-animations' )

			) ).liquidSplitText();

			$( '[data-fittext]', $wrapper ).liquidFitText();

			$( '[data-custom-animations]', $wrapper ).map( ( i, element ) => {

				const $element = $( element );
				const $customAnimationParent = $element.parents( '.wpb_wrapper[data-custom-animations]' );

				if ( $customAnimationParent.length ) {

					$element.removeAttr( 'data-custom-animations' );
					$element.removeAttr( 'data-ca-options' );

				}

			} );

			$( '[data-custom-animations]', $wrapper ).filter( ( i, element ) => {

				const $element = $( element );
				const $rowBgparent = $element.closest( '.vc_row[data-row-bg]' );
				const $slideshowBgParent = $element.closest( '.vc_row[data-slideshow-bg]' );

				return (
					!$rowBgparent.length &&
					!$slideshowBgParent.length
				);

			} ).liquidCustomAnimations();

			$( '[data-lqd-flickity]', $wrapper ).liquidCarousel();

			$( '[data-parallax]', $wrapper ).liquidParallax();

			$( '[data-hover3d=true]', $wrapper ).liquidHover3d();

			this.$element.removeClass( 'items-loading' );

			ScrollTrigger?.refresh();

		}

	}


	$.fn[ pluginName ] = function ( options ) {

		return this.each( function () {

			const pluginOptions = { ...$( this ).data( 'ajaxify-options' ), options };

			if ( !$.data( this, "plugin_" + pluginName ) ) {
				$.data( this, "plugin_" + pluginName, new Plugin( this, pluginOptions ) );
			}

		} );

	};

}( jQuery ) );

jQuery( document ).ready( function ( $ ) {

	if ( $( 'body' ).hasClass( 'compose-mode' ) ) return false;

	$( '[data-ajaxify=true]' ).liquidAjaxLoadMore();

} );