(function ( $ ) {
	
	'use strict';
	
	const pluginName = 'liquidCarouselV3d';
	let defaults = {
		itemsSelector: '.carousel-item'
	};
	
	function Plugin( element, options ) {
		
		this.element = element;
		
		this.options = $.extend( {}, defaults, options) ;
		
		this._defaults = defaults;
		this._name = pluginName;
		
		this.build();
	}
	
	Plugin.prototype = {
		
		build: function() {
			
			this.init();
			
		},
		
		init: function() {
			
			const self = this;
			const element = $(self.element);
			const items = self.options.itemsSelector;

			self.prepareitems();

			const activeItem = $(items, element).first();
			const bottomItem = activeItem.next();
			const topItem = bottomItem.next();
			
			self.dragY = 0;
			self.startY = 0;
			self.currentY = 0;
			
			self.setActive(activeItem, element);
			self.initAnim(element, activeItem, topItem, bottomItem);
			self.initDrag();
			self.initClicks();
			
			element.addClass('carousel-initialized');
			
			return self;
			
		},

		prepareitems() {

			const self = this;
			const items = $(self.options.itemsSelector, self.element);

			if ( items.length <= 2 && items.length >= 1 ) {

				const firstItem = items[0];

				for ( let i = items.length; i <= 2; i++ ) {

					$(firstItem).clone(true).appendTo($(self.element).find('.carousel-items'));

				}

			}

		},
		
		setActive: function(activeItem, element) {
			
			const currentTopItem = $('.is-top', element);
			const currentActiveItem = $('.is-active', element);
			const currentBottomItem = $('.is-bottom', element);

			if ( currentTopItem.length ) { currentTopItem.addClass('was-top'); }
			if ( currentActiveItem.length ) { currentActiveItem.addClass('was-active'); }
			if ( currentBottomItem.length ) { currentBottomItem.addClass('was-bottom'); }
			
			activeItem.addClass('is-active').removeClass('is-top is-bottom').siblings().removeClass('is-active');

			this.setBottom(activeItem);
			this.setTop(activeItem);
			
		},
		
		// Bottom Item will be based on the active item
		setBottom: function(activeItem) {
			
			const element = $(this.element);
			const items = this.options.itemsSelector;
			const firstItem = $(items, element).first();

			let bottomItem = activeItem.next();
			
			if ( ! bottomItem.length && activeItem.is(':last-child') ) {
				
				bottomItem = firstItem;
				
			}

			bottomItem.addClass('is-bottom').removeClass('is-active is-top was-active').siblings().removeClass('is-bottom');
			
		},
		
		// Top Item will be based on the active item		
		setTop: function(activeItem) {
			
			const element = $(this.element);
			const items = this.options.itemsSelector;
			const lastItem = $(items, element).last();

			let topItem = activeItem.prev();

			if ( ! topItem.length && activeItem.is(':first-child') ) {

				topItem = lastItem;

			}
			
			topItem.addClass('is-top').removeClass('is-active is-bottom was-active').siblings().removeClass('is-top');
			
		},

		initAnim: function(element, activeItem, topItem, bottomItem) {

			this.animInited = false;

			if ( ! this.animInited ) {

				const timeline = gsap.timeline({
					duration: 0,
				});
				
				timeline
				.to(element.get(0).querySelectorAll('.carousel-item:not(.is-active):not(.is-bottom)'), {
					yPercent: -60,
					z: 0,
					scale: 0.9,
				}, 0)
				.to(activeItem.get(0), {
					z: 50,
					scale: 1,
				}, 0)
				.to(bottomItem.get(0), {
					yPercent: 50,
					z: 0,
					scale: 0.9,
				}, 0);

				this.animInited = true;

			}
			
		},

		initClicks() {

			$(this.element).on('click', '.is-top', this.moveItems.bind(this, 'prev'));
			$(this.element).on('click', '.is-bottom', this.moveItems.bind(this, 'next'));

		},
		
		initDrag: function() {
			
			const self = this;
			const element = $(self.element);
			
			element.on('mousedown', self.pointerStart.bind(self));
			element.on('mousemove', self.pointerMove.bind(self));
			element.on('mouseup', self.pointerEnd.bind(self));
			
		},
		
		pointerStart: function(event) {
			
			const self = this;
			const element = $(self.element);
			
			self.startY = event.pageY || event.touches[0].pageY;
			self.currentY = self.startY;
			
			element.addClass('pointer-down');
			
		},
		
		pointerMove: function(event) {
			
			const self = this;
			
			self.currentY = event.pageY || event.touches[0].pageY;
			
			self.dragY = parseInt(self.startY - self.currentY, 10);
			
		},
		
		pointerEnd: function() {
			
			const element = $(this.element);
			
			this.dragY = parseInt(this.startY - this.currentY, 10);

			if ( this.dragY >= 20 ) {
				
				this.moveItems('next');
				
			} else if ( this.dragY <= -20 ) {				
				
				this.moveItems('prev');
				
			}
			
			element.removeClass('pointer-down');
			
		},
		
		moveItems: function(dir) {

			if ( $(this.element).hasClass('items-moving') ) return;
			
			const element = $(this.element);
			const items = $(this.options.itemsSelector);
			const bottomItem = $('.is-bottom', element);
			const topItem = $('.is-top', element);

			const animationTimeline = gsap.timeline({
				duration: 0.65,
				onUpdate: () => {
					$(items, element).addClass('is-moving');
				},
				onComplete: () => {
					$(items, element).removeClass('is-moving was-top was-active was-bottom');
					$(this.element).removeClass('items-moving');
				}
			});
			
			if ( dir == 'next' )
				this.setActive(bottomItem, element);
			else if ( dir == 'prev' )
				this.setActive(topItem, element);
				
			const newActiveItem = $('.is-active', element);
			const newBottomItem = $('.is-bottom', element);
			const newTopItem = $('.is-top', element);
			
			if ( dir == 'next' ) {
				
				this.moveNext(animationTimeline, newActiveItem, newBottomItem, newTopItem);
				
			} else if ( dir == 'prev' ) {

				this.movePrev(animationTimeline, newActiveItem, newBottomItem, newTopItem);

			}
			
		},

		moveNext: function(animationTimeline, newActiveItem, newBottomItem, newTopItem) {

			$(this.element).addClass('items-moving');

			animationTimeline
			.fromTo(newTopItem.get(0),
				{
					rotateX: -18,
				},
				{
					yPercent: -60,
					z: 0,
					rotateX: 0,
					scale: 0.9,
				}, 0)
			.fromTo(newActiveItem.get(0),
				{
					rotateX: -18,
				},
				{
					yPercent: 0,
					z: 50,
					rotateX: 0,
					scale: 1,
				}, 0)
			.fromTo(newBottomItem.get(0),
				{
					rotateX: -18
				},
				{
					yPercent: 50,
					z: 0,
					rotateX: 0,
					scale: 0.9,
				}, 0);

		},

		movePrev: function(animationTimeline, newActiveItem, newBottomItem, newTopItem) {

			$(this.element).addClass('items-moving');

			animationTimeline
			.fromTo(newTopItem.get(0),
				{
					rotateX: 18
				},
				{
					yPercent: -60,
					z: 0,
					rotateX: 0,
					scale: 0.9,
				}, 0)
			.fromTo(newActiveItem.get(0),
				{
					rotateX: 18
				},
				{
					yPercent: 0,
					z: 50,
					rotateX: 0,
					scale: 1,
				}, 0)
			.fromTo(newBottomItem.get(0),
				{
					rotateX: 18
				},
				{
					yPercent: 50,
					z: 0,
					rotateX: 0,
					scale: 0.9,
				}, 0);

		}
		
	};
	
	$.fn[ pluginName ] = function( options ) {
		
		return this.each( function() {
			
			const pluginOptions = {...$(this).data('plugin-options'), ...options};
			
			if ( !$.data( this, "plugin_" + pluginName ) ) {
				$.data( this, "plugin_" + pluginName, new Plugin( this, pluginOptions ) );
			}
			
		} );
		
	};
	
}(jQuery));

jQuery(document).ready( function($) {
	$('.carousel-vertical-3d').liquidCarouselV3d();
});