// eslint-disable-next-line no-unused-vars
class LiquidIO {

  constructor(el, callback, opts = {}) {

    this.el = el;
    this.opts = opts;

    this.setupIO(callback);

  }

  setupIO(callback) {

    new IntersectionObserver(([entry], observer) => {

      if ( entry.isIntersecting && callback ) {
        if ( this.opts.disconnect ) {
          observer.disconnect();
        }
        callback();
      }

    }, {...this.opts}).observe(this.el);

  }

}