( function ( $ ) {

	'use strict';

	const pluginName = 'liquidCustomCursor';
	let defaults = {
		outerCursorSpeed: 0.2,
		outerCursorHide: false
	};

	class Plugin {

		constructor( element, options ) {

			this._defaults = defaults;
			this._name = pluginName;

			this.options = { ...defaults, ...options };

			this.clientX = -100;
			this.clientY = -100;
			this.magneticCords = { x: 0, y: 0 };

			this.element = element;
			this.$element = $( element );

			this.initiated = false;

			this.innerCursor = document.querySelector( ".lqd-cc--inner" );
			this.outerCursor = document.querySelector( ".lqd-cc--outer" );
			this.dragCursor = document.querySelector( ".lqd-cc-drag" );
			this.exploreCursor = document.querySelector( ".lqd-cc-explore" );
			this.arrowCursor = document.querySelector( ".lqd-cc-arrow" );
			this.iconCursor = document.querySelector( ".lqd-cc-custom-icon" );
			this.cursorEls = document.querySelectorAll( ".lqd-cc--el" );
			this.extraCursors = document.querySelectorAll( ".lqd-extra-cursor" );

			this.lastMovedOtherEl = null;
			this.lastMovedOtherInnerEl = null;
			this.scaleOuterCursor = null;
			this.scaleOuterCursorX = null;
			this.scaleOuterCursorY = null;

			this.activeEl = null;

			const cssVarEl = liquidIsElementor ? document.body : document.documentElement;
			const outerSize = window.liquidParams?.ccOuterSize || getComputedStyle( cssVarEl ).getPropertyValue( '--lqd-cc-size-outer' );
			this.outerCursorSize = parseInt( outerSize || 0, 10 );
			this.activeCircleBg = window.liquidParams?.ccActiveCircleBg || getComputedStyle( document.body ).getPropertyValue( '--lqd-cc-active-bg' );
			this.activeCircleBc = window.liquidParams?.ccActiveCircleBc || getComputedStyle( document.body ).getPropertyValue( '--lqd-cc-active-bc' );

			this.innerQuickSetX = gsap.quickSetter( this.innerCursor, 'x', 'px' );
			this.innerQuickSetY = gsap.quickSetter( this.innerCursor, 'y', 'px' );

			this.outerQuickToX = gsap.quickTo( this.outerCursor, 'x', { duration: this.options.outerCursorSpeed } );
			this.outerQuickToY = gsap.quickTo( this.outerCursor, 'y', { duration: this.options.outerCursorSpeed } );

			this.extrasQuickToX = gsap.quickTo( [ ...this.cursorEls, ...this.extraCursors ], 'x', { duration: 0.1 } );
			this.extrasQuickToY = gsap.quickTo( [ ...this.cursorEls, ...this.extraCursors ], 'y', { duration: 0.1 } );

			this.init();

		}

		init() {

			if ( this.options.outerCursorHide ) {
				$liquidBody.addClass( 'lqd-cc-outer-hidden' );
			}

			this.initCursor();
			this.initHovers();

			document.body.classList.add( 'lqd-cc-init' );

		}

		initCursor() {

			const pos = { x: window.innerWidth / 2, y: window.innerHeight / 2 };

			document.addEventListener( "mousemove", e => {
				this.clientX = e.clientX;
				this.clientY = e.clientY;
			} );

			gsap.ticker.add( () => {

				if ( !this.initiated ) {
					this.initiated = true;
					this.fadeOutInnerCursor = false;
					this.fadeOutInnerCursor = false;
				}

				const dt = 1.0 - Math.pow( 1.0 - 0.4, gsap.ticker.deltaRatio() );

				pos.x += ( this.clientX - pos.x ) * dt;
				pos.y += ( this.clientY - pos.y ) * dt;

				this.innerQuickSetX( pos.x );
				this.innerQuickSetY( pos.y );

				this.extrasQuickToX( this.clientX );
				this.extrasQuickToY( this.clientY );

				if ( !this.isStuck && !this.isMagnetic ) {
					this.outerQuickToX( this.clientX - this.outerCursorSize / 2 );
					this.outerQuickToY( this.clientY - this.outerCursorSize / 2 );
				}

				if ( this.isStuck && this.activeEl ) {
					const rect = this.activeEl.getBoundingClientRect();
					let { left, top, width, height } = rect;
					left += ( width / 2 ) - ( this.outerCursorSize / 2 );
					top += ( height / 2 ) - ( this.outerCursorSize / 2 );
					this.outerQuickToX( left );
					this.outerQuickToY( top );
				}

				if ( this.isMagnetic ) {
					this.outerQuickToX( this.magneticCords.x );
					this.outerQuickToY( this.magneticCords.y );
				}

				if ( this.scaleOuterCursor ) {
					gsap.to( this.outerCursor, {
						scaleX: () => this.scaleOuterCursorX || 1,
						scaleY: () => this.scaleOuterCursorY || 1,
					} )
				}

				if ( this.fadeOutInnerCursor ) {
					gsap.to( this.innerCursor, {
						opacity: 0,
					} )
				} else {
					gsap.to( this.innerCursor, {
						opacity: 1,
					} )
				}

				if ( this.fadeOutOuterCursor ) {
					gsap.to( this.outerCursor, {
						opacity: 0,
					} )
				} else {
					gsap.to( this.outerCursor, {
						opacity: 1,
					} )
				}

			} );

		}

		initHovers() {

			document.addEventListener( 'lqd-carousel-initialized', ( e ) => {

				const { carouselData } = e.detail;
				const flkty = carouselData.flickityData;

				if ( flkty.nextButton ) {
					this.initCarouselNavCursor( [ flkty.nextButton.element, flkty.prevButton.element ] );
				};

				if ( !flkty.options.draggable ) return;

				flkty.on( 'dragMove', e => {
					this.clientX = e.clientX;
					this.clientY = e.clientY;
				} );
				flkty.on( 'pointerDown', () => {
					this.cursorEls.forEach( el => el.classList.add( 'lqd-carousel-pointer-down' ) );
				} );
				flkty.on( 'pointerUp', () => {
					this.cursorEls.forEach( el => el.classList.remove( 'lqd-carousel-pointer-down' ) );
				} );

				this.initSolidCircles( flkty.viewport, this.dragCursor );

			} );

			this.initPageLinksCursor();
			this.initExtraCursor();
			this.initSolidCircles( '.lqd-cc-label-trigger', this.exploreCursor );
			this.initArrowCursor( '.lqd-dist-gal-menu a, .lqd-imgtxt-slider-link, .lqd-af-slide__link ~ a' );
			this.initCustomIconCursor( '.lqd-cc-icon-trigger', 'lqd-cc__active-icon' )
			this.initButtonShapeCursors();
			this.initNavTriggerCursor();
			this.initMenuItemsCursor();

		}

		initPageLinksCursor() {

			const linksMouseEnter = () => {

				gsap.to( this.innerCursor, {
					scale: 2.25,
					backgroundColor: this.activeCircleBg,
					ease: 'power2.out'
				} );
				gsap.to( this.outerCursor, {
					scale: 1.2,
					borderColor: this.activeCircleBc,
					ease: 'power2.out',
				} );
			};

			const linksMouseLeave = () => {

				gsap.to( this.innerCursor, {
					scale: 1,
					backgroundColor: '',
					ease: 'power2.out'
				} );
				gsap.to( this.outerCursor, {
					scale: 1,
					borderColor: '',
					ease: 'power2.out'
				} );
			};

			const pageLinks = [ ...document.querySelectorAll( "a" ) ].filter( el => {
				return (
					!el.classList.contains( 'lqd-cc-icon-trigger' ) &&
					!el.classList.contains( 'lqd-cc-label-trigger' ) &&
					!el.classList.contains( 'lqd-imgtxt-slider-link' ) &&
					!el.closest( '.carousel-items' ) &&
					!el.closest( '.lqd-slsh-alt' ) &&
					!el.closest( '.lqd-dist-gal-menu' ) &&
					!$( el ).siblings( '.lqd-af-slide__link' ).length
				)
			} );
			pageLinks.forEach( item => {
				item.addEventListener( "mouseenter", linksMouseEnter );
				item.addEventListener( "mouseleave", linksMouseLeave );
			} );

		}

		initCarouselNavCursor( navElementsArray ) {

			const carouselNavEnter = ( navEl, navElStyle ) => {

				this.activeEl = navEl;
				this.isStuck = true;
				this.scaleOuterCursor = true;
				this.fadeOutInnerCursor = true;

				gsap.to( this.outerCursor, {
					borderColor: navElStyle.borderColor,
					borderRadius: navElStyle.borderRadius,
				} );

				this.moveOtherElements( navEl, [ ...navEl.querySelectorAll( 'i' ), ...navEl.querySelectorAll( 'svg' ) ], true );

			};

			const carouselNavLeave = () => {

				this.activeEl = null;
				this.isStuck = false;
				this.fadeOutInnerCursor = false;

				gsap.to( this.outerCursor, {
					borderColor: '',
					borderRadius: '',
				} );

				this.moveOtherElements();

			};

			navElementsArray.forEach( item => {

				fastdomPromised.measure( () => {

					const navElStyle = getComputedStyle( item );

					return {
						navElStyle
					}

				} ).then( ( { navElStyle } ) => {

					item.addEventListener( "mouseenter", carouselNavEnter.bind( this, item, navElStyle ) );
					item.addEventListener( "mouseleave", carouselNavLeave.bind( this ) );

					item.addEventListener( 'click', e => {
						const timeout = setTimeout( () => {
							if ( item.disabled ) {
								carouselNavLeave();
							}
							clearTimeout( timeout );
						}, 10 );
					} );

				} );

			} );

		}

		initExtraCursor() {

			const extraCursorEnter = ( extraCursor ) => {

				this.fadeOutInnerCursor = true;
				this.fadeOutOuterCursor = true;

				extraCursor.classList.add( 'lqd-is-active' );

				gsap.to( extraCursor, {
					scale: 1,
					opacity: 1,
					duration: 0.65,
					ease: 'expo.out'
				} )

			};

			const extraCursorLeave = ( extraCursor ) => {

				this.fadeOutInnerCursor = false;
				this.fadeOutOuterCursor = false;

				extraCursor.classList.remove( 'lqd-is-active' );

				gsap.to( extraCursor, {
					scale: 0.15,
					opacity: 0,
					duration: 0.65,
					ease: 'expo.out',
				} )

			};

			this.extraCursors.forEach( item => {
				const itemParent = item.parentElement;
				itemParent.addEventListener( "mousemove", extraCursorEnter.bind( this, item ) );
				itemParent.addEventListener( "mouseleave", extraCursorLeave.bind( this, item ) );
			} );

		}

		initSolidCircles( selectors, elementSetActiveClassname ) {

			if ( !selectors ) return;

			const solidCircleEnter = () => {

				this.fadeOutInnerCursor = true;
				this.fadeOutOuterCursor = true;

				elementSetActiveClassname.classList.add( 'lqd-is-active' );

			};

			const solidCircleLeavve = () => {

				this.fadeOutInnerCursor = false;
				this.fadeOutOuterCursor = false;

				elementSetActiveClassname.classList.remove( 'lqd-is-active' );

			};

			const init = ( item ) => {
				item.addEventListener( "mouseenter", solidCircleEnter );
				item.addEventListener( "mouseleave", solidCircleLeavve );
			};

			if ( typeof selectors === 'string' ) {
				document.querySelectorAll( selectors ).forEach( item => init( item ) );
			} else {
				init( selectors );
			}

		}

		initArrowCursor( selectors ) {

			const onEnter = () => {

				this.fadeOutInnerCursor = true;
				this.fadeOutOuterCursor = true;

				this.arrowCursor.classList.add( 'lqd-is-active' );

			};

			const onLeave = () => {

				this.fadeOutInnerCursor = false;
				this.fadeOutOuterCursor = false;

				this.arrowCursor.classList.remove( 'lqd-is-active' );

			};

			const init = ( item ) => {
				item.addEventListener( "mouseenter", onEnter );
				item.addEventListener( "mouseleave", onLeave );
			};

			if ( typeof selectors === 'string' ) {
				document.querySelectorAll( selectors ).forEach( item => init( item ) );
			} else {
				init( selectors );
			}

		}

		initCustomIconCursor( selectors ) {

			const onEnter = color => {

				this.fadeOutInnerCursor = true;
				this.fadeOutOuterCursor = true;

				color && this.iconCursor.style.setProperty( '--cc-icon-color', color );

				this.iconCursor.classList.add( 'lqd-is-active' );

			};

			const onLeave = () => {

				this.fadeOutInnerCursor = false;
				this.fadeOutOuterCursor = false;

				this.iconCursor.style.removeProperty( '--cc-icon-color' );

				this.iconCursor.classList.remove( 'lqd-is-active' );

			};

			document.querySelectorAll( selectors ).forEach( item => {
				const color = item.getAttribute( 'data-cc-icon-color' );
				item.addEventListener( "mouseenter", onEnter.bind( this, color ) );
				item.addEventListener( "mouseleave", onLeave );
			} );

		}

		initButtonShapeCursors() {

			const buttonShapeEnter = ( icon, iconStyles ) => {

				this.isMagnetic = true;
				this.scaleOuterCursor = true;
				this.fadeOutInnerCursor = true;

				gsap.to( this.outerCursor, {
					borderColor: iconStyles.borderColor,
					borderRadius: iconStyles.borderRadius,
				} );

				this.moveOtherElements( icon, [ ...icon.querySelectorAll( 'i' ), ...icon.querySelectorAll( 'svg' ) ] );

			};

			const buttonShapeLeave = () => {

				this.isMagnetic = false;
				this.fadeOutInnerCursor = false;

				gsap.to( this.outerCursor, {
					borderColor: '',
					borderRadius: '',
				} );

				this.moveOtherElements();

			};

			const buttonWithIcon = document.querySelectorAll( '.btn-icon-bordered, .btn-icon-solid' );

			buttonWithIcon.forEach( btn => {

				const icon = btn.querySelector( '.btn-icon' );

				if ( !icon ) return;

				fastdomPromised.measure( () => {

					const iconStyles = getComputedStyle( icon );

					return {
						iconStyles
					}

				} ).then( ( { iconStyles } ) => {

					btn.addEventListener( "mouseenter", buttonShapeEnter.bind( this, icon, iconStyles ) );
					btn.addEventListener( "mouseleave", buttonShapeLeave.bind( this ) );

				} );


			} );

		}

		initNavTriggerCursor() {

			const navTriggerEnter = ( outerEl, innerEl, scaleOuterCursor ) => {

				this.isMagnetic = true;
				scaleOuterCursor && ( this.scaleOuterCursor = true );
				this.fadeOutInnerCursor = true;
				this.fadeOutOuterCursor = true;

				this.moveOtherElements( outerEl, innerEl );

			};

			const navTriggerLeave = () => {

				this.isMagnetic = false;
				this.fadeOutInnerCursor = false;
				this.fadeOutOuterCursor = false;

				this.moveOtherElements();

			};

			const navTriggers = document.querySelectorAll( '.nav-trigger' );
			const moduleTriggers = document.querySelectorAll( '.ld-module-trigger' );

			navTriggers.forEach( navTrigger => {

				const $navTrigger = $( navTrigger );
				const bars = navTrigger.querySelector( '.bars' );
				const barsInner = navTrigger.querySelector( '.bars-inner' )
				const toggleData = $navTrigger.data( 'plugin_liquidToggle' );

				if ( toggleData && toggleData.options.type !== 'hover' ) {
					navTrigger.addEventListener( "mouseenter", navTriggerEnter.bind( this, bars, barsInner, true ) );
					navTrigger.addEventListener( "mouseleave", navTriggerLeave );
				}

			} );

			moduleTriggers.forEach( moduleTrigger => {

				const $moduleTrigger = $( moduleTrigger );
				const txt = moduleTrigger.querySelector( '.ld-module-trigger-txt' );
				const icon = moduleTrigger.querySelector( '.ld-module-trigger-icon' )
				const toggleData = $moduleTrigger.data( 'plugin_liquidToggle' );

				if ( toggleData && toggleData.options.type !== 'hover' ) {
					moduleTrigger.addEventListener( "mouseenter", () => {
						const scaleOuterCursor = !moduleTrigger.classList.contains( 'lqd-module-icon-plain' ) && !moduleTrigger.parentElement.classList.contains( 'ld-dropdown-menu' );
						navTriggerEnter( moduleTrigger, txt, scaleOuterCursor );
						navTriggerEnter( moduleTrigger, icon, scaleOuterCursor );
					} );
					moduleTrigger.addEventListener( "mouseleave", navTriggerLeave );
				}

			} );

		}

		initMenuItemsCursor() {

			const menuItemEnter = menuItem => {

				this.moveOtherElements( menuItem );

			};

			const menuItemLeave = () => {

				this.moveOtherElements();

			};

			const magneticMenuItems = document.querySelectorAll( '.lqd-magnetic-items' );

			magneticMenuItems.forEach( el => {
				const ul = el.querySelector( 'ul' );
				const menuItems = ul.querySelectorAll( ':scope > li > a' );
				menuItems.forEach( menuItem => {
					menuItem.addEventListener( "mouseenter", menuItemEnter.bind( this, menuItem ) );
					menuItem.addEventListener( "mouseleave", menuItemLeave.bind( this ) );
				} )
			} );

		}

		moveOtherElements( movingEl, innerMovingEl, onlyMoveInnerEl ) {

			let movingElRect = null;

			if ( !movingEl ) {

				this.lastMovedOtherEl && gsap.to( this.lastMovedOtherEl, {
					duration: 0.4,
					x: 0,
					y: 0,
					clearProps: 'all'
				} );

				this.lastMovedOtherInnerEl && gsap.to( this.lastMovedOtherInnerEl, {
					duration: 0.35,
					x: 0,
					y: 0,
					clearProps: 'all'
				} );

				this.scaleOuterCursor = null;
				this.scaleOuterCursorX = null;
				this.scaleOuterCursorY = null;

				$liquidWindow.off( 'mousemove.lqdCCMoveOthers' );

				return;

			};

			this.lastMovedOtherEl = movingEl;
			this.lastMovedOtherInnerEl = innerMovingEl;

			$liquidWindow.on( 'mousemove.lqdCCMoveOthers', e => {

				!movingElRect && ( movingElRect = movingEl.getBoundingClientRect() );

				const dist = {
					x: ( movingElRect.left + movingElRect.width / 2 ) - this.clientX,
					y: ( movingElRect.top + movingElRect.height / 2 ) - this.clientY
				};

				const angle = Math.atan2( dist.x, dist.y );

				const hypotenuse = Math.sqrt(
					dist.x * dist.x + dist.y * dist.y
				);

				this.magneticCords = {
					x: ( movingElRect.left + movingElRect.width / 2 - this.outerCursorSize / 2 ) - ( ( Math.sin( angle ) * hypotenuse ) / 3 ),
					y: ( movingElRect.top + movingElRect.height / 2 - this.outerCursorSize / 2 ) - ( ( Math.cos( angle ) * hypotenuse ) / 3 )
				}

				!onlyMoveInnerEl && gsap.to( movingEl, {
					duration: 0.4,
					x: -( Math.sin( angle ) * hypotenuse ) / 8,
					y: -( Math.cos( angle ) * hypotenuse ) / 8
				} );

				innerMovingEl && gsap.to( innerMovingEl, {
					duration: 0.35,
					x: -( Math.sin( angle ) * hypotenuse ) / 8,
					y: -( Math.cos( angle ) * hypotenuse ) / 8
				} );

				if ( this.scaleOuterCursor ) {

					this.scaleOuterCursorX = movingElRect.width / this.outerCursorSize;
					this.scaleOuterCursorY = movingElRect.height / this.outerCursorSize;

				}

			} );

		}

	}

	$.fn[ pluginName ] = function ( options ) {

		return this.each( function () {

			const pluginOptions = { ...$( this ).data( 'cc-options' ), ...options };

			if ( !$.data( this, "plugin_" + pluginName ) ) {
				$.data( this, "plugin_" + pluginName, new Plugin( this, pluginOptions ) );
			}

		} );

	};

}( jQuery ) );

jQuery( document ).ready( function ( $ ) {

	if (
		liquidIsMobile() ||
		window.vc_iframe ||
		( 'elementorFrontend' in window && elementorFrontend.isEditMode() )
	) return;

	const addCustomCursorMarkup = () => {

		const $elements = $( '[data-lqd-custom-cursor]' );

		$elements.each( ( i, el ) => {
			const $ccSpan = $( `<span class="lqd-extra-cursor pos-fix pointer-events-none"></span>` );
			$( el ).prepend( $ccSpan );
		} );

	};

	addCustomCursorMarkup();

	$( '[data-lqd-cc]' ).liquidCustomCursor();

} );