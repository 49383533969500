// https://github.com/CodyHouse/image-comparison-slider
( function ( $ ) {

	'use strict';

	const pluginName = 'liquidImageComparison';
	let defaults = {
	};

	class Plugin {

		constructor( element, options ) {

			this.element = element;
			this.$element = $( element );

			this.options = $.extend( {}, defaults, options );
			this._defaults = defaults;
			this._name = pluginName;

			this.dragging = false;
			this.resizing = false;

			this.$dragElement = this.$element.find( '.cd-handle' );
			this.$resizeElement = this.$element.find( '.cd-resize-img' );
			this.dragWidth = this.$dragElement.outerWidth();
			this.containerWidth = this.$element.outerWidth();

			this.init();

		}

		init() {

			this.element.style.setProperty( '--container-width', `${ this.containerWidth }px` );

			this.initIO();

			this._drags();

		}

		initIO() {

			new IntersectionObserver( ( enteries ) => {

				enteries.forEach( entery => {

					if ( entery.isIntersecting ) {

						this.$element.addClass( 'is-visible' );

					}

				} );

			} ).observe( this.element, { threshold: 0.35 } );

		}

		//draggable funtionality - credits to http://css-tricks.com/snippets/jquery/draggable-without-jquery-ui/
		_drags() {

			this.$dragElement.on( "pointerdown.lqdImageComparison", event => {

				event.preventDefault();

				this.$dragElement.addClass( 'draggable' );
				this.$resizeElement.addClass( 'resizable' );

				const x = event.originalEvent.pageX;
				const xPosition = this.$dragElement.offset().left + this.dragWidth - x;

				$( document ).on( "pointermove.lqdImageComparison", event => {

					if ( !this.dragging ) {

						this.dragging = true;
						requestAnimationFrame( () => {
							this._animateDraggedHandle( event, xPosition );
						} );

					}

				} );

			} );

			$( document ).on( "pointerup.lqdImageComparison", () => {

				this.$dragElement.removeClass( 'draggable' );
				this.$resizeElement.removeClass( 'resizable' );

				$( document ).off( 'pointermove.lqdImageComparison' );

				this.dragging = false;

			} );

		}

		_animateDraggedHandle( event, xPosition ) {

			var containerOffset = this.$element.offset().left;
			var minLeft = containerOffset;
			var maxLeft = containerOffset + this.containerWidth;
			var x = event.originalEvent.pageX;
			var leftValue = x + xPosition - ( this.dragWidth / 2 );
			//constrain the draggable element to move inside his container
			if ( leftValue < minLeft ) {
				leftValue = minLeft;
			} else if ( leftValue > maxLeft ) {
				leftValue = maxLeft;
			}

			var widthValue = ( leftValue - containerOffset ) * 100 / this.containerWidth + '%';

			this.$dragElement.css( 'left', widthValue ).on( "pointerup.lqdImageComparison", () => {
				this.$dragElement.removeClass( 'draggable' );
				this.$resizeElement.removeClass( 'resizable' );
			} );

			this.$resizeElement.css( 'width', widthValue );

			this.dragging = false;

		}

	}

	$.fn[ pluginName ] = function ( options ) {

		return this.each( function () {

			const pluginOptions = $( this ).data( 'plugin-options' ) || options;

			if ( !$.data( this, "plugin_" + pluginName ) ) {
				$.data( this, "plugin_" + pluginName, new Plugin( this, pluginOptions ) );
			}

		} );

	};

}( jQuery ) );

jQuery( document ).ready( function ( $ ) {

	const imageCopElements = $( '.cd-image-container' );
	const visibleElements = imageCopElements.filter( ( i, element ) => !$( element ).closest( '.lqd-tabs-pane, .e-n-tabs-content > .e-con' ).not( '.active, .e-active' ).length );
	const parentElementorTabs = imageCopElements.closest( '.e-n-tabs-content > .e-con' );

	visibleElements.liquidImageComparison();

	if ( !parentElementorTabs.length ) return;

	const mutationObserver = new MutationObserver( ( [ entry ], observer ) => {
		const { target } = entry;
		if ( entry.target.classList.contains( 'e-active' ) ) {
			$( target ).find( '.cd-image-container' ).liquidImageComparison();
		}
	} );

	parentElementorTabs.each( ( i, el ) => {
		mutationObserver.observe( el, { attributes: true, attributeFilter: [ 'class' ] } );
	} );

} );