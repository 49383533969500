( function ( $ ) {

	'use strict';

	const pluginName = 'liquidStickyRow';
	let defaults = {
	};

	class Plugin {

		constructor( element, options ) {

			this.element = element;
			this.$element = $( element );

			this.options = $.extend( {}, defaults, options );
			this._defaults = defaults;
			this._name = pluginName;

			this.markupInitiated = false;
			this.$stickyWrap = null;
			this.$stickyWrapInner = null;

			this.boundingClientRect = null;

			this.rowStickyInitPromise = new Promise( resolve => {
				this.$element.on( 'lqd-sticky-row-initiated', resolve.bind( this, this ) )
			} );

			this.init();

		}

		init() {

			this.initMarkup();
			this.handleSizes();
			this.addEvents();
			this.handleWindowResize();

		}

		initMarkup() {

			if ( this.markupInitiated ) return false;

			const $stickyWrap = $( '<div class="lqd-css-sticky-wrap w-100 pos-rel w-full relative" />' );
			const $stickyWrapInner = $( '<div class="lqd-css-sticky-wrap-inner w-100 h-200 w-full pos-abs pos-tl absolute top-0 left-0" />' );

			this.$element.wrap( $stickyWrap ).wrap( $stickyWrapInner );

			this.$stickyWrapInner = this.$element.parent();
			this.$stickyWrap = this.$element.parent().parent();

			this.markupInitiated = true;

		}

		handleSizes() {

			const $nextElements = this.$stickyWrap.nextAll();
			const elementHeight = this.$element.outerHeight();

			this.$stickyWrap.css( {
				height: elementHeight
			} );

			if ( $nextElements.length ) {

				let nextElementsHeight = 0;

				$.each( $nextElements, ( i, nextElement ) => {

					nextElementsHeight += $( nextElement ).outerHeight();

				} );

				if ( elementHeight > nextElementsHeight ) {

					this.$stickyWrapInner.css( {
						height: `calc(200% - ${ nextElementsHeight }px)`
					} );

				}

			}

		}

		addEvents() {

			const e = new CustomEvent( 'lqd-sticky-row-initiated', { detail: { $element: this.$element } } );
			this.element.dispatchEvent( e );

		}

		handleWindowResize() {

			const resize = liquidDebounce( this.onWindowResize, 500 );

			$( window ).on( 'resize', resize.bind( this ) );

		}

		onWindowResize() {

			this.handleSizes();

		}

	}


	$.fn[ pluginName ] = function ( options ) {

		return this.each( function () {

			const pluginOptions = $( this ).data( 'sticky-options' ) || options;

			if ( !$.data( this, "plugin_" + pluginName ) ) {
				$.data( this, "plugin_" + pluginName, new Plugin( this, pluginOptions ) );
			}

		} );

	};

}( jQuery ) );

jQuery( document ).ready( function ( $ ) {

	if ( liquidWindowWidth() <= liquidMobileNavBreakpoint() || liquidIsMobile() ) return;

	$( '.vc_row.lqd-css-sticky, .lqd-force-css-sticky' ).liquidStickyRow();

} );