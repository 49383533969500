(function ( $ ) {
	
	'use strict';
	
	const pluginName = 'liquidHover3d';
	let defaults = {
	};
	
	class Plugin {

		constructor(element, options) {
			
			this._defaults = defaults;
			this._name = pluginName;

			this.options = {...defaults, ...options};

			this.element = element;
			this.$element = $(element);
			this.hoverable = this.element.querySelector('[data-stacking-factor]');
			this.stackingFactor = this.hoverable.getAttribute('data-stacking-factor');

			this.rect = {};
			this.maxRotation = 8;
			this.maxTranslation = 4;

			this.build();

		}
		
		build() {

			fastdom.measure(() => {

				new IntersectionObserver(([entry], observer) => {

					observer.disconnect();

					const {boundingClientRect} = entry;

					this.rect = {
						width: boundingClientRect.width,
						height: boundingClientRect.height
					};

					this.init();

					this.$element.addClass('hover-3d-applied');

				}).observe(this.element);

			})

		}

		measure() {

			return fastdomPromised.measure(() => {
				return new Promise(resolve => {
					new IntersectionObserver(([entry], observer) => {
						observer.disconnect();
						resolve(entry.boundingClientRect);
					}).observe(this.element);
				})
			}).then(rect => {
				this.rect = {
					width: rect.width,
					height: rect.height
				};
			})

		}

		init() {

			this.eventHandlers();

		}

		eventHandlers() {

			this.$element.on('mousemove.lqdHover3d', this.hover.bind(this));
			this.$element.on('mouseleave.lqdHover3d', this.leave.bind(this));
			
			$liquidWindow.on('resize.lqdHover3d', this.onWindowLoad.bind(this));

		}

		async onWindowLoad() {

			await this.measure();

		}

		animate(config, isIn) {

			fastdom.mutate(() => {

				if ( isIn ) {
					this.element.classList.add('mouse-in');
				} else {
					this.element.classList.remove('mouse-in');
				}

				this.hoverable.style.transition = 'none';
	
				gsap.to(this.hoverable, {
					x: (config.xTranslationPercentage * -1) * config.maxTranslationX,
					y: (config.yTranslationPercentage * -1) * config.maxTranslationY,
					rotateX: (config.xRotationPercentage * -1) * config.maxRotationX,
					rotateY: (config.yRotationPercentage * -1) * config.maxRotationY,
					duration: 0.8,
					ease: 'power2.out'
				});

			})

		}

		calculateRotationPercentage(offset, dimension) {
			return ((-2 / dimension) * offset) + 1;
		}

		calculateTranslationPercentage(offset, dimension) {
			return ((-2 / dimension) * offset) + 1;
		}

		hover(e) {

			const mouseOffsetInside = {
				x: e.pageX - this.$element.offset().left,
				y: e.pageY - this.$element.offset().top,
			};
			const transVals = {
				xRotationPercentage: this.calculateRotationPercentage(mouseOffsetInside.y, this.rect.height),
				yRotationPercentage: this.calculateRotationPercentage(mouseOffsetInside.x, this.rect.width) * -1,
				xTranslationPercentage: this.calculateTranslationPercentage(mouseOffsetInside.x, this.rect.width),
				yTranslationPercentage: this.calculateTranslationPercentage(mouseOffsetInside.y, this.rect.height),
			}
				
			this.animate({
				maxTranslationX: this.maxTranslation * this.stackingFactor,
				maxTranslationY: this.maxTranslation * this.stackingFactor,
				maxRotationX: this.maxRotation * this.stackingFactor,
				maxRotationY: this.maxRotation * this.stackingFactor,
				xRotationPercentage: transVals.xRotationPercentage,
				yRotationPercentage: transVals.yRotationPercentage,
				xTranslationPercentage: transVals.xTranslationPercentage,
				yTranslationPercentage: transVals.yTranslationPercentage
			}, true);

		}

		leave() {

			this.animate({
				maxTranslationX: 0,
				maxTranslationY: 0,
				maxRotationX: 0,
				maxRotationY: 0,
				xRotationPercentage: 0,
				yRotationPercentage: 0,
				xTranslationPercentage: 0,
				yTranslationPercentage: 0
			}, false);

		}

		destroy() {

			this.$element.off('mousemove.lqdHover3d mouseleave.lqdHover3d');
			$liquidWindow.off('resize.lqdHover3d');

		}

	}
	
	
	$.fn[ pluginName ] = function( options ) {
		
		return this.each( function() {
			
			const pluginOptions = {...$(this).data('hover3d-options'), ...options};
			
			if ( !$.data( this, "plugin_" + pluginName ) ) {
				$.data( this, "plugin_" + pluginName, new Plugin( this, pluginOptions ) );
			}
			
		} );
		
	};
	
}(jQuery));

jQuery(document).ready( function($) {

	if ( liquidIsMobile() ) return false;

	$('[data-hover3d]').filter( (i, element) => ! $(element).closest('.lqd-tabs-pane').not('.active').length ).liquidHover3d();

});
