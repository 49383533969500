( function ( $ ) {

	'use strict';

	const pluginName = 'liquidSectionScroll';
	let defaults = {
		scroller: '.lqd-section-scroll-sections',
		itemsSelector: ':scope > .vc_row, :scope > .elementor-container > .elementor-column > .elementor-widget-wrap > .elementor-section, :scope > .e-container, :scope > .e-con'
	};

	class Plugin {

		constructor( element, options ) {

			this._defaults = defaults;
			this._name = pluginName;

			this.options = { ...defaults, ...options };

			this.DOM = {};
			this.DOM.element = element;
			this.DOM.$element = $( element );
			this.DOM.items = [ ...this.DOM.element.querySelectorAll( this.options.itemsSelector ) ];
			this.DOM.scroller = this.DOM.element.querySelector( `:scope > ${ this.options.scroller }` );

			if ( !this.DOM.items || this.DOM.items.length <= 1 ) return;

			this.DOM.dots = [];

			this.isAnimating = false;
			this.activeItem = 0;
			this.totalItemsHeight = 0;
			this.elementRect = {};
			this.itemsRects = [];

			this.build().then( () => {
				this.appendDots();
				this.events();
				this.DOM.element.classList.add( 'lqd-section-scroll-activated' );
			} );

		}

		async build() {

			await this.createScroller();
			const rects = await Promise.all( [ this.getElementRect(), ...this.getItemsRects() ] );
			const windowScrollY = window.scrollY;
			const elementRect = rects[ 0 ];
			const itemsRects = rects.filter( ( item, i ) => i > 0 );

			this.elementRect = {
				width: elementRect.width,
				height: elementRect.height,
				y: elementRect.y + windowScrollY,
				bottom: elementRect.bottom
			};

			itemsRects.forEach( ( rect, i_1 ) => {
				this.itemsRects[ i_1 ] = {
					width: rect.width,
					height: rect.height,
					y: rect.y - this.elementRect.y,
					bottom: rect.bottom - this.elementRect.y
				};
				this.totalItemsHeight += rect.height;
			} );

		}

		async createScroller() {

			if ( this.DOM.scroller ) return;

			await fastdomPromised.mutate( () => {
				$( this.DOM.items ).wrapAll( `<div class="${ this.options.scroller.substring( 1 ) } lqd-overlay" />` );
				this.DOM.scroller = this.DOM.element.querySelector( this.options.scroller );
			} );

		}

		getElementRect() {

			return new Promise( resolve => {
				new IntersectionObserver( ( [ entry ], observer ) => {
					observer.disconnect();
					resolve( entry.boundingClientRect );
				} ).observe( this.DOM.element );
			} );

		}

		getItemsRects() {

			const promises = [];

			this.DOM.items.forEach( el => {

				const promise = new Promise( resolve => {
					new IntersectionObserver( ( [ entry ], observer ) => {
						observer.disconnect();
						resolve( entry.boundingClientRect );
					} ).observe( el );
				} );

				promises.push( promise )

			} );

			return promises;

		}

		events() {

			const transitionDuration = 1000;
			let wheelFree = true;
			let isScrollingDown = false;
			let isScrollingUp = false;

			this.DOM.$element.on( 'wheel mousewheel', event => {

				const { deltaY } = event.originalEvent;

				if ( deltaY < 0 ) {
					isScrollingUp = true;
					isScrollingDown = false;
				} else if ( deltaY > 0 ) {
					isScrollingUp = false;
					isScrollingDown = true;
				}

				if ( ( this.activeItem === 0 && isScrollingDown ) || ( this.activeItem === this.DOM.items.length - 1 && isScrollingUp ) ) {
					$( 'html, body' ).animate( {
						scrollTop: this.DOM.$element.offset().top - ( window.innerHeight - this.DOM.element.offsetHeight ) / 2
					}, 350 );
				}

				if ( !this.isAnimating ) {

					if ( isScrollingUp && this.activeItem > 0 ) {
						wheelFree = false;
						this.navigate( 'prev' );
					} else if ( isScrollingDown && this.activeItem !== this.DOM.items.length - 1 ) {
						wheelFree = false;
						this.navigate( 'next' );
					}

				}

				if ( !wheelFree || this.isAnimating ) {
					event.preventDefault();
					return false;
				};

			} );

			this.DOM.scroller.addEventListener( 'transitionend', event => {
				if ( event.target === this.DOM.scroller ) {
					wheelFree = true;
					this.isAnimating = false;
					this.navigateDone();
				}
			} );

			this.DOM.element.addEventListener( 'mouseover', () => {
				if ( ( this.activeItem === 0 && isScrollingDown ) || ( this.activeItem === this.DOM.items.length - 1 && isScrollingUp ) ) {
					wheelFree = false;
				}
			} )
			this.DOM.element.addEventListener( 'mouseout', () => {
				wheelFree = true;
			} )

			this.DOM.dots.forEach( ( $dot, i ) => {
				$dot.on( 'click', this.onDotsClick.bind( this, i ) );
			} );

			$liquidWindow.on( 'resize', liquidDebounce( this.onWindowResize.bind( this ), 250 ) )

		}

		appendDots() {

			const $dotsWrapper = $( '<div class="lqd-section-scroll-dots d-flex flex-column pos-abs z-index-5 flex flex-col absolute z-5" />' );

			this.itemsRects.forEach( ( rect, i ) => {
				const $dot = $( `<div class="lqd-section-scroll-dot d-flex flex align-items-center justify-content-center items-center justify-center border-radius-circle pos-rel relative text-center ${ i === 0 ? 'is-active' : '' }"><span></span></div>` );
				$dotsWrapper.append( $dot );
				this.DOM.dots.push( $dot );
			} );

			$dotsWrapper.appendTo( this.DOM.$element );

		}

		navigate( direction ) {

			this.isAnimating = true;

			this.DOM.scroller.style.willChange = 'transform';

			switch ( direction ) {
				case 'prev':
					this.navigatePrev()
					break;

				default:
					this.navigateNext();
					break;
			}

		}

		navigateDone() {

			this.DOM.scroller.style.willChange = 'auto';

		}

		navigateNext() {

			if ( this.activeItem < this.DOM.items.length - 1 ) {
				this.activeItem += 1;
			}

			this.moveScroller();
			this.manageDotsActiveState();

		}

		navigatePrev() {

			if ( this.activeItem > 0 ) {
				this.activeItem -= 1;
			}

			this.moveScroller();
			this.manageDotsActiveState();

		}

		moveScroller() {

			this.DOM.scroller.style.transform = `translate3d(0, ${ ( this.activeItem * 100 ) * -1 }%, 0)`;

		}

		manageDotsActiveState() {

			this.DOM.dots.forEach( $dot => $dot.removeClass( 'is-active' ) );
			this.DOM.dots[ this.activeItem ].addClass( 'is-active' );

		}

		onDotsClick( i ) {

			this.activeItem = i;
			this.moveScroller();
			this.manageDotsActiveState();

		}

		onWindowResize() {

			this.build();

		}

	}

	$.fn[ pluginName ] = function ( options ) {

		return this.each( function () {

			const pluginOptions = { ...$( this ).data( 'section-scroll-options' ), ...options };

			if ( !$.data( this, "plugin_" + pluginName ) ) {
				$.data( this, "plugin_" + pluginName, new Plugin( this, pluginOptions ) );
			}

		} );

	};

}( jQuery ) );

jQuery( document ).ready( function ( $ ) {
	if ( liquidIsMobile() ) return;
	$( '[data-lqd-section-scroll=true]' ).not( '.elementor-inner-section' ).liquidSectionScroll();
} );