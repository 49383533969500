(function ( $ ) {
	
	'use strict';
	
	const pluginName = 'liquidTypewriter';
	let defaults = {
		repeat: false,
		speed: 75
	};
	
	class Plugin {

		constructor(element, options) {

			this.element = element;
			this.$element = $(element);

			this.options = {...defaults, ...options};
			
			this._defaults = defaults;
			this._name = pluginName;

			this.init();
			this.initIO();

		}
			
		init() {

			const { repeat, speed } = this.options;
			
			this.$element.t({
				repeat,
				speed
			});
			this.$element.t('pause', true);
			
		}

		initIO() {

			new IntersectionObserver(([entry], observer) => {
				if ( entry.isIntersecting ) {
					this.$element.t('pause', false);
				} else {
					this.$element.t('pause', true);
				}
			}).observe(this.element);

		}
		
	}
	
	$.fn[ pluginName ] = function( options ) {
		
		return this.each( function() {
			
			const pluginOptions = {...$(this).data('plugin-options'), ...options};
			
			if ( !$.data( this, "plugin_" + pluginName ) ) {
				$.data( this, "plugin_" + pluginName, new Plugin( this, pluginOptions ) );
			}
			
		} );
		
	};
	
}(jQuery));

jQuery(document).ready( function($) {
  $('[data-typewriter]').liquidTypewriter();
});