(function ( $ ) {
	
	'use strict';
	
	const pluginName = 'liquidButton';
	let defaults = {
	};
	
	class Plugin {

		constructor(element, options) {
			this.element = element;
			this.options = $.extend({}, defaults, options);
			this._defaults = defaults;
			this._name = pluginName;
			this.init();
		}
			
		init() {
			
			this.gradientBorderRoundness();
			
		}

		gradientBorderRoundness() {

			const self = this;
			const element = $(self.element);

			if ( element.find('.btn-gradient-border').length && element.hasClass('circle') && element.is(':visible') ) {

				const svgBorder = element.find('.btn-gradient-border').children('rect');
				const buttonHeight = element.height();

				svgBorder.attr({
					rx: buttonHeight / 2,
					ry: buttonHeight / 2
				});

			}

		}
		
	}
	
	
	$.fn[ pluginName ] = function( options ) {
		
		return this.each( function() {
			
			const pluginOptions = $(this).data('plugin-options') || options;
			
			if ( !$.data( this, "plugin_" + pluginName ) ) {
				$.data( this, "plugin_" + pluginName, new Plugin( this, pluginOptions ) );
			}
			
		} );
		
	};
	
}(jQuery));

jQuery(document).ready( function($) {
  // $('.btn').liquidButton();
});