( function ( $ ) {

	'use strict';

	const pluginName = 'liquidFormInputs';
	let defaults = {
		dropdownAppendTo: null
	};

	class Plugin {

		constructor( element, options ) {

			this.element = element;
			this.$element = $( element );

			this.options = { ...defaults, ...options };

			this._defaults = defaults;
			this._name = pluginName;

			this.init();

		}

		init() {

			this.icons();
			this.initDatePicker();
			this.initSelect();
			this.initSpinner();
			this.inputsEventHandlers();

		}

		icons() {

			if ( this.$element.hasClass( 'wpcf7-form' ) ) {

				this.$element.find( '.wpcf7-form-control-wrap' ).siblings( 'i' ).each( ( i, icon ) => {
					$( icon ).appendTo( $( icon ).prev( '.wpcf7-form-control-wrap' ) );
				} );

			}

		}

		initDatePicker() {

			const dateInputs = this.$element.find( '.date-picker, input.wpcf7-form-control[type=date]' );

			dateInputs.each( ( i, element ) => {

				const $element = $( element );

				if ( $element.attr( 'type' ) === 'date' ) {

					const $clonedElement = $element.clone( true );

					$clonedElement.attr( 'type', 'text' );
					$clonedElement.insertAfter( $element );
					$element.css( 'display', 'none' );

					$clonedElement.datepicker( {
						dateFormat: 'yy-mm-dd',
						onSelect: ( date ) => {
							$element.val( date );
						}
					} );

				} else {

					$( element ).datepicker();

				}

			} );

		}

		initSelect() {

			const $selectElement = this.$element.find( 'select' ).not( '.select2-hidden-accessible, .select, .woo-rating, #bbp_stick_topic_select, #bbp_topic_status_select, #bbp_forum_id, .woocommerce-widget-layered-nav-dropdown, .elementor-field-textual, .wc-pao-addon-select, .quform-field' );
			let { dropdownAppendTo } = this.options;

			if (
				$selectElement.hasClass( 'orderby' ) ||
				$selectElement.hasClass( 'search-field' ) ||
				$selectElement.hasClass( 'wpcf7-form-control' ) ||
				$selectElement.hasClass( 'liquid-schedule-filter' )
			) {
				dropdownAppendTo = 'self';
			}

			if ( !$selectElement.closest( '.variations' ).length ) {

				$selectElement.each( ( i, element ) => {

					const $element = $( element );

					const slct = $element.selectmenu( {
						change: () => {
							$element.trigger( 'change' );
						}
					} );

					if ( dropdownAppendTo ) {

						let $appendToEl;

						if ( dropdownAppendTo === 'self' ) {
							$appendToEl = $( '<div class="lqd-select-dropdown" />' ).insertAfter( $element );
						} else {
							$appendToEl = $( dropdownAppendTo, this.element ).length ? $( dropdownAppendTo, this.element ) : $( dropdownAppendTo );
						}

						slct.selectmenu( 'option', 'appendTo', $appendToEl );

					}

					$element.on( 'change', () => {
						$element.selectmenu( 'refresh' );
					} );

				} );

			} else {

				const $selectElExtra = $( '<span class="lqd-select-ext" />' );
				$selectElement.wrap( '<span class="lqd-select-wrap pos-rel relative" />' );
				$selectElExtra.insertAfter( $selectElement );

			}

		}

		initSpinner() {

			const splinners = this.$element.find( 'input.spinner, input[type=number].qty' );

			splinners.each( ( i, element ) => {

				const $element = $( element );

				$element.spinner( {
					spin: ( event, ui ) => {
						$element.val( ui.value );
						$element.trigger( 'change' );
					}
				} );

			} );

		}

		getInputParent( focusedInput ) {

			if ( focusedInput.closest( 'p' ).length ) {

				return focusedInput.closest( 'p' );

			} else {

				return focusedInput.closest( 'div' );

			}

		}

		inputsEventHandlers() {

			$( 'input, textarea', this.$element ).on( 'focus', this.inputOnFocus.bind( this ) );
			$( 'input, textarea', this.$element ).on( 'blur', this.inputOnBlur.bind( this ) );

		}

		inputOnFocus( event ) {

			const inputParent = this.getInputParent( $( event.target ) );

			inputParent.addClass( 'input-focused' );

		}

		inputOnBlur( event ) {

			const input = $( event.target );
			const inputParent = this.getInputParent( input );

			if ( input.val() !== '' ) {
				inputParent.addClass( 'input-filled' );
			} else {
				inputParent.removeClass( 'input-filled' );
			}

			inputParent.removeClass( 'input-focused' );

		}

	}


	$.fn[ pluginName ] = function ( options ) {

		return this.each( function () {

			const pluginOptions = { ...$( this ).data( 'form-options' ), ...options };

			if ( !$.data( this, "plugin_" + pluginName ) ) {
				$.data( this, "plugin_" + pluginName, new Plugin( this, pluginOptions ) );
			}

		} );

	};

}( jQuery ) );

jQuery( document ).ready( function ( $ ) {
	$( 'form, .lqd-filter-dropdown, .widget' ).not( '[name="chbs-form"], .frm-fluent-form, .hub-booking-form, .geodir-listing-search' ).liquidFormInputs();
} );