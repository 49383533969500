/*
* FitText.js 1.2
*
* Copyright 2011, Dave Rupert http://daverupert.com
* Released under the WTFPL license
* http://sam.zoy.org/wtfpl/
*
* Date: Thu May 05 14:23:00 2011 -0600
*/
(function ( $ ) {
	
	'use strict';
	
	const pluginName = 'liquidFitText';
	let defaults = {
		compressor: 1,
		minFontSize : Number.NEGATIVE_INFINITY,
		maxFontSize : Number.POSITIVE_INFINITY
	};
	
	function Plugin( element, options ) {
		
		this.element = element;
		
		this.options = $.extend( {}, defaults, options) ;
		
		this._defaults = defaults;
		this._name = pluginName;
		
		this.init();
	}
	
	Plugin.prototype = {

		init() {
			
			this.setMinFontSize();
			this.setMaxFontSize();
			this.resizer();
			this.onWindowResize();
			
		},

		setMinFontSize() {

			const minFontSize = this.options.minFontSize;
			const elementFontSize = $(this.element).css('fontSize');

			if ( minFontSize == 'currentFontSize' ) {

				this.options.minFontSize = elementFontSize;

			}

		},

		setMaxFontSize() {

			const maxFontSize = this.options.maxFontSize;
			const elementFontSize = $(this.element).css('fontSize');

			if ( maxFontSize == 'currentFontSize' ) {

				this.options.maxFontSize = elementFontSize;

			}

		},

		resizer() {

			const options = this.options;
			const compressor = options.compressor;
			const maxFontSize = options.maxFontSize;
			const minFontSize = options.minFontSize;
			const $element = $(this.element);

			// if it's a fancy heading, get .ld-fancy-heading's parent width. because .ld-fancy-heading is set to display: inline-block
			const elementWidth = $element.parent('.ld-fancy-heading').length ? $element.parent().width() : $element.width();

			$element.css('font-size', Math.max(Math.min(elementWidth / (compressor*10), parseFloat(maxFontSize)), parseFloat(minFontSize)));

		},

		onWindowResize() {

			$(window).on('resize.fittext orientationchange.fittext', this.resizer.bind(this));

		}
		
	};
	
	$.fn[ pluginName ] = function( options ) {
		
		return this.each( function() {

			const pluginOptions = $(this).data('fittext-options') || options;

			if ( !$.data( this, "plugin_" + pluginName ) ) {
				$.data( this, "plugin_" + pluginName, new Plugin( this, pluginOptions ) );
			}
			
		} );
		
	};
	
}(jQuery));

jQuery(document).ready( function($) {

	$('[data-fittext]').liquidFitText();

});