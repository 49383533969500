(function ( $ ) {
	
	'use strict';
	
	const pluginName = 'liquidIconboxCircle';
	let defaults = {
		startAngle: 45,
		itemSelector: '.lqd-ib-circ-icn, .one-ib-circ-icn',
		contentsContainer: '.lqd-ib-circ-inner, .one-ib-circ-inner'
	};
	
	class Plugin {

		constructor(element, options) {

			this.element = element;

			this.options = $.extend({}, defaults, options);

			this._defaults = defaults;
			this._name = pluginName;

			this.$parent = $(this.options.contentsContainer);
			this.$items = $(this.options.itemSelector, this.element);
			this.anglesArray = [this.options.startAngle];

			this.init();

		}
			
		init() {

			this.addAngles(this.$items);
			this.setTransformOrigin();
			this.setIntersectionObserver();
			this.windowResize();
			
		}

		getItemsArray() {

			const $items = this.$items;
			const itemsLength = $items.length;
			const itemsArray = [];

			for ( let i = 0; i < itemsLength; i++ ) {

				itemsArray.push($items[i]);

			}

			return itemsArray;

		}

		getElementDimension($element) {

			return {
				width: $element.width(),
				height: $element.height()
			};

		}
		
		addAngles($elements) {

			const elementsLength = $elements.length;

			$elements.each( (i) => {	
				
				this.anglesArray.push( 360 / ( elementsLength ) + ( this.anglesArray[i] ) );

			});

		}

		setTransformOrigin() {

			const parentDimensions = this.getElementDimension(this.$parent);
			
			this.$items.each( (i, element) => {
				
				const $element = $(element);

				$element.css({
					transformOrigin: ''
				});
				$element.css({
					transformOrigin: `${( parentDimensions.width / 2 )}px ${( parentDimensions.height / 2 )}px`,
				});
				
				$element.children().css({
					transform: `rotate(${ this.anglesArray[i] * -1 }deg)`,
				});

			});

		}

		setIntersectionObserver() {

			new IntersectionObserver((enteries, observer) => {

				enteries.forEach( entery => {

					if ( entery.isIntersecting ) {
						
						this.animateIcons();

						observer.unobserve(entery.target);

					}

				});

			}, { threshold: 0.25 }).observe(this.	element);

		}

		animateIcons() {

			const icons = this.getItemsArray();
			const timeline = gsap.timeline();

			timeline.to(icons, {
				opacity: 1,
				duration: 0.2,
				ease: 'linear',
				stagger: i => i * 0.2
			}, 0.45)
			.to(icons, {
				rotation: i => this.anglesArray[i],
				duration: 1,
				ease: 'power4.inOut',
				stagger: i => i * 0.15,
			}, 0);

		}

		windowResize() {

			$(window).on('resize.lqdIconboxCircle', this.setTransformOrigin.bind(this));

		}

		destroy() {

			$(window).off('resize.lqdIconboxCircle');

		}
		
	}
	
	
	$.fn[ pluginName ] = function( options ) {
		
		return this.each( function() {
			
			const pluginOptions = {...$(this).data('plugin-options'), ...options};
			
			if ( !$.data( this, "plugin_" + pluginName ) ) {
				$.data( this, "plugin_" + pluginName, new Plugin( this, pluginOptions ) );
			}
			
		} );
		
	};
	
}(jQuery));

jQuery(document).ready( function($) {
  $('[data-spread-incircle]').liquidIconboxCircle();
});