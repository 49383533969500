( function ( $ ) {

	'use strict';

	const pluginName = 'liquidSlideElement';
	let defaults = {
		hiddenElement: null,
		visibleElement: null,
		hiddenElementOnHover: null,
		alignMid: false,
		waitForSplitText: false,
		disableOnMobile: false,
		triggerElement: 'self' // self, $ancesterElement
	};

	class Plugin {

		constructor( element, options ) {

			this._defaults = defaults;
			this._name = pluginName;

			this.options = { ...defaults, ...options };

			this.element = element;
			this.$element = $( element );
			this.$triggerElement = this.options.triggerElement === 'self' ? this.$element : this.$element.closest( this.options.triggerElement );

			this.timeline = gsap.timeline();

			if ( this.options.waitForSplitText ) {

				const $splitTextEls = this.$element.find( '[data-split-text]' );
				const promises = [];

				if ( $splitTextEls.length ) {
					$splitTextEls.liquidSplitText( { forceApply: true } );
					$splitTextEls.each( ( i, el ) => {
						const $el = $( el );
						const splitTextData = $el.data( 'plugin_liquidSplitText' );
						if ( splitTextData ) {
							promises.push( splitTextData.splitDonePormise );
						}
					} )
				}

				if ( promises.length > 0 ) {
					Promise.all( promises ).then( this.init.bind( this ) );
				}

			} else {
				this.init();
			}

		}

		init() {

			this.getElements();

			if ( !this.$hiddenElement.length || !this.$visibleElement.length ) {
				return;
			}

			imagesLoaded( this.element, () => {

				this.hiddenElementHeight = this.getHiddenElementHeight();
				this.$element.addClass( 'hide-target' );
				this.createTimeline();
				this.moveElements();
				this.eventListeners();

			} );

		}

		getElements() {

			this.$hiddenElement = $( this.options.hiddenElement, this.element );
			this.$visibleElement = $( this.options.visibleElement, this.element );
			this.$hiddenElementOnHover = $( this.options.hiddenElementOnHover, this.element );

			this.$hiddenElement.wrap( '<div class="ld-slideelement-hidden" />' ).wrap( '<div class="ld-slideelement-hidden-inner" />' );
			this.$visibleElement.wrap( '<div class="ld-slideelement-visible" />' ).wrap( '<div class="ld-slideelement-visible-inner" />' );

			this.$hiddenElementWrap = this.$hiddenElement.closest( '.ld-slideelement-hidden' );
			this.$hiddenElementInner = this.$hiddenElement.closest( '.ld-slideelement-hidden-inner' );
			this.$visibleElementWrap = this.$visibleElement.closest( '.ld-slideelement-visible' );
			this.$visibleElementInner = this.$visibleElement.closest( '.ld-slideelement-visible-inner' );

		}

		getHiddenElementHeight() {

			let height = 0;

			$.each( this.$hiddenElement, ( i, element ) => {
				height += $( element ).outerHeight( true );
			} );

			return height;

		}

		getHiddenElementChilds() {

			return this.$hiddenElementInner.children().map( ( i, childElement ) => childElement.parentElement );

		}

		getVisibleElementChilds() {

			return this.$visibleElementInner.children().map( ( i, childElement ) => childElement.parentElement );

		}

		moveElements() {

			const translateVal = this.options.alignMid ? this.hiddenElementHeight / 2 : this.hiddenElementHeight;

			this.$visibleElementWrap.css( {
				transform: `translateY(${ translateVal }px)`
			} );
			this.$hiddenElementWrap.css( {
				transform: `translateY(${ translateVal }px)`
			} );

		}

		createTimeline() {

			const { options } = this;
			const childElements = [ ...this.getVisibleElementChilds(), ...this.getHiddenElementChilds() ];
			let translateVal = options.alignMid ? this.hiddenElementHeight / 2 : this.hiddenElementHeight;

			if ( options.hiddenElementOnHover ) {
				const elementHeight = this.$hiddenElementOnHover.outerHeight( true );
				translateVal = options.alignMid ? ( this.hiddenElementHeight + elementHeight ) / 2 : this.hiddenElementHeight + elementHeight;
			}

			this.timeline.to( childElements, {
				y: translateVal * -1,
				opacity: ( i, element ) => {
					if ( $( element ).is( $( this.$hiddenElementOnHover ).parent() ) ) {
						return 0;
					}
					return 1;
				},
				ease: 'power3.out',
				duration: 0.65,
				stagger: 0.065,
			} ).pause();

		}

		eventListeners() {

			const onResize = liquidDebounce( this.onWindowResize.bind( this ), 500 );

			this.$triggerElement.on( 'mouseenter.lqdSlideElementOnHover', this.onMouseEnter.bind( this ) );
			this.$triggerElement.on( 'mouseleave.lqdSlideElementOnHover', this.onMouseLeave.bind( this ) );
			$( window ).on( 'resize.lqdSlideElementOnResize', onResize );

		}

		onMouseEnter() {

			this.timeline.play();

		}

		onMouseLeave() {

			this.timeline.reverse();

		}

		onWindowResize() {

			this.hiddenElementHeight = this.getHiddenElementHeight();
			this.moveElements();

		}

		destroy() {

			this.$triggerElement.off( 'mouseenter.lqdSlideElementOnHover mouseleave.lqdSlideElementOnHover' );
			$( window ).off( 'resize.lqdSlideElementOnResize' );

		}

	}


	$.fn[ pluginName ] = function ( options ) {

		return this.each( function () {

			const pluginOptions = { ...$( this ).data( 'slideelement-options' ), ...options };

			if ( pluginOptions.disableOnMobile && liquidIsMobile() ) {
				return;
			}

			if ( !$.data( this, "plugin_" + pluginName ) ) {
				$.data( this, "plugin_" + pluginName, new Plugin( this, pluginOptions ) );
			}

		} );

	};

}( jQuery ) );

jQuery( document ).ready( function ( $ ) {
	const $elements = $( '[data-slideelement-onhover]' ).filter( ( i, element ) => {
		return (
			!$( element ).parents( '[data-custom-animations]' ).length &&
			!element.hasAttribute( 'data-custom-animations' ) &&
			element.clientHeight > 0
		)
	} );
	$elements.liquidSlideElement();
} );