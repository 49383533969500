( function ( $ ) {

	'use strict';

	const pluginName = 'liquidMobileNav';

	let defaults = {
	};

	class Plugin {

		constructor( element, options ) {

			this._defaults = defaults;
			this._name = pluginName;

			this.options = { ...defaults, ...options };

			this.element = element;
			this.$element = $( element );
			this.$mobileSec = $( '.lqd-mobile-sec', $liquidMainHeader );
			this.$mobileSecInner = $( '.lqd-mobile-sec-inner', this.$mobileSec );
			this.$mobileNavCollapse = $( '.mobile-navbar-collapse', this.$mobileSec );
			this.$submenuExpanders = $( '.submenu-expander' );
			this.isModernMobileNav = document.body.hasAttribute( 'data-mobile-nav-style' ) && document.body.getAttribute( 'data-mobile-nav-style' ) === 'modern';

			this.$navItems = [];

			this.init();

		}

		init() {

			this.mobileExpander()
			this.eventHandlers();

			if ( document.body.hasAttribute( 'data-mobile-header-builder' ) ) return;

			this.removeStyleTags();
			this.addHeightVar();
			this.mobileModules();

			/*
				adding .elementor for styling
				can't add the classname in markup because it will conflict with elementor pro and elementor cloud
			*/
			if ( 'elementorFrontend' in window && !elementorFrontend.isEditMode() ) {
				this.$mobileSec.addClass( 'elementor' );
			}

		}

		mobileExpander() {
			this.$submenuExpanders.attr( 'role', 'button' );
			this.$submenuExpanders.attr( 'tabindex', 0 );

			this.$mobileNavCollapse.add( '.nav-item-children', this.$mobileNavCollapse ).add( '.nav-item-children', $( '.ld-sd-inner' ) ).css( {
				display: 'block',
				visibility: 'hidden'
			} );

			// move the expander out of <a> element
			this.$submenuExpanders.each( ( i, expander ) => {
				const $expander = $( expander );
				const $parent = $expander.parent();
				const $expanderCssPosition = $expander.css( 'position' );

				if ( $expanderCssPosition === 'absolute' ) {
					$expander.css( {
						// 18px hardcoded. 36px height of the expander / 2
						top: ( $parent.outerHeight() / 2 ) - 18
					} );
				}

				$expander.insertAfter( $parent );
			} );

			this.$mobileNavCollapse.add( '.nav-item-children', this.$mobileNavCollapse ).add( '.nav-item-children', $( '.ld-sd-inner' ) ).css( {
				display: '',
				visibility: ''
			} );
		}

		eventHandlers() {
			this.$submenuExpanders.
				off()
				.on( 'click keypress', event => {

					if (
						event.code &&
						(
							event.code !== 'Enter' &&
							event.code !== 'Space' &&
							event.code !== 'NumpadEnter'
						)
					) return;

					event.preventDefault();

					const $parentLi = $( event.currentTarget ).closest( 'li' );
					const $submenu = $parentLi.children( '.nav-item-children, .children' );
					const $navbarInner = $parentLi.closest( '.navbar-collapse-inner' );
					const submenuParentWasActive = $parentLi.hasClass( 'is-active' );

					$parentLi.toggleClass( 'is-active' );
					$parentLi.siblings().removeClass( 'is-active' ).find( '.nav-item-children, .children' ).stop().slideUp( 200 );

					$submenu.stop().slideToggle( 300, () => {

						if ( this.isModernMobileNav && !submenuParentWasActive && $navbarInner.length ) {

							$navbarInner.animate( {
								scrollTop: $navbarInner.scrollTop() + ( $parentLi.offset().top - $navbarInner.offset().top )
							} );

						}

					} );

				} );
		}

		removeStyleTags() {

			fastdom.mutate( () => {

				this.$mobileSec.find( '.navbar-collapse style[data-type=vc_shortcodes-custom-css]' ).remove();

			} );


		}

		addHeightVar() {

			fastdom.mutate( () => {

				if ( this.$mobileSecInner.length ) {

					document.documentElement.style.setProperty( '--lqd-mobile-sec-height', `${ this.$mobileSecInner[ 0 ].offsetHeight }px` );

				}

			} );

		}

		mobileModules() {

			const $mobileModules = $( '.lqd-show-on-mobile', this.element );

			if ( !$mobileModules.length ) return;

			const $mobileModulesContainer = $( '.lqd-mobile-modules-container', this.$mobileSec );


			fastdom.mutate( () => {

				$mobileModulesContainer.removeClass( 'empty' );

				$mobileModules.each( ( i, mobileModule ) => {

					const $module = $( mobileModule );

					if ( !$module.children().length ) return false;

					const $clonedModule = $module.clone( true );
					const $triggerElement = $( '[data-target]', $clonedModule );

					if ( $triggerElement.length ) {

						const target = $triggerElement.attr( 'data-target' );
						const $targetEelement = $( target, $clonedModule );

						$targetEelement.attr( {
							'id': `${ target.replace( '#', '' ) }-cloned`
						} );

						$triggerElement.attr( {
							'data-target': `${ target }-cloned`,
							'aria-controls': `${ target.replace( '#', '' ) }-cloned`
						} );
						$triggerElement.attr( {
							'data-bs-target': `${ target }-cloned`,
							'aria-controls': `${ target.replace( '#', '' ) }-cloned`
						} );

						$targetEelement.on( 'show.bs.collapse', () => {
							$targetEelement.add( $triggerElement ).addClass( 'is-active' )
						} )
						$targetEelement.on( 'hide.bs.collapse', () => {
							$targetEelement.add( $triggerElement ).removeClass( 'is-active' )
						} )

					}

					$clonedModule.appendTo( $mobileModulesContainer );

					if ( !$clonedModule.hasClass( 'header-module' ) ) {
						$clonedModule.wrap( '<div class="header-module" />' )
					}

				} );

			} );

		}

	}

	$.fn[ pluginName ] = function ( options ) {

		return this.each( function () {

			const pluginOptions = { ...$( this ).data( 'mobilenav-options' ), ...options };

			if ( !$.data( this, "plugin_" + pluginName ) ) {
				$.data( this, "plugin_" + pluginName, new Plugin( this, pluginOptions ) );
			}

		} );

	};

}( jQuery ) );

jQuery( document ).ready( function () {

	$liquidMainHeader.liquidMobileNav();

} );